import React, {Component} from 'react';

import classes from './FirstFloor.module.css';

class FirstFloor extends Component {
  
 
    render () {
        const style ={borderBottom:"0.5px solid #cccccc",height:"60px"};
        const styleActivated ={borderBottom:"0.5px solid #cccccc",height:"60px",  backgroundColor: "#285A64",opacity:" 0.2"};
        let property=classes.Active;

        if(this.props.state.active)
            property=classes.Disabled;

        if(this.props.state.activated)
            property=classes.Activated;


        return (
            <div className={[classes.FirstFloor, property].join(' ')}>
                    <div style={this.props.state.activated ? styleActivated : style }>

                    </div>
                </div>
        );
    }
}
  
export default FirstFloor;
