import i18n from 'i18next';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';
import Backdrop from '../../../components/UI/Backdrop/Backdrop';
import Privacy from '../../../components/Terms/Privacy';
import classes from './Final.module.css';

class Final extends Component {
  state = {
    message: <div className={classes.firstForm}>
      <p className={classes.ThankYouText}>{i18n.t("THANK_YOU")}</p>
      <p className={classes.someText}>{i18n.t("WE_NEED_TO_REVIEW_YOUR_DOCUMEN")} <br /> {i18n.t("AS_SOON_AS_YOUR_ACCOUNT_HAS_BE")}</p>
    </div>,
    privacy: false
  };


  changeMessage = () => {
    let newmessage = <div className={classes.firstForm}>
      <p className={classes.firstTitle}>{i18n.t("WE_RE_SORRY_TO_SEE_YOU_GO")}</p>
      <p className={classes.someText}>{i18n.t("YOU_CAN_RETURN_AT_ANY_TIME_TO")} <b>{i18n.t("ONE_WEEK")}</b></p>
      <div style={{ width: "85%", justifyContent: "center", display: "flex", alignItems: "center", margin: "20px" }}>
        <input type="radio" name="demo" id="radio-one" className={classes.formRadio}

          value="privacy"
          description="privacy"
        // changed={(event) => this.dataWUHandler()}
        />
        <div style={{ fontWeight: "bold" }}>{i18n.t("I_HAVE_READ_THE")} <strong onClick={() => this.privacyVisualizer()}> {i18n.t("PRIVACY_STATEMENT1")} </strong> </div>
      </div>

    </div>;

    this.setState({ message: newmessage });
  };

  closeHandler = () => {

    this.props.history.push({
      pathname: '/login'
    });

  };

  privacyVisualizer = () => {
    this.setState({ privacy: true });
  };

  privacyClose = () => {
    this.setState({ privacy: false });
  };

  render() {
    return (
      <div style={{ heightL: "auto" }}>
        <Backdrop clicked={() => this.changeMessage()} show={this.props.active} />
        <Privacy clicked={() => this.privacyClose()} show={this.state.privacy} />
        {this.state.message}
        <Link to="/login"> <Button> {i18n.t("CLOSE")} </Button></Link>
      </div>);

  }
}


export default Final;
