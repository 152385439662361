import i18n from 'i18next'; 
import React from 'react';
import classes from './graph.module.css';
import minimize from '../../../../assets/icons/Minimize_icon.svg';
import currenciesObject from "../../../../data/currencies";

function MiniGraphHeader(props) {


    let currencies = currenciesObject[0];

    let currencyDsc = currencies[props.currencyType].description.toUpperCase();

    return (
        <div>
            {props.currencyType === "OTHER" ?
                <div className={classes.otherCurrency}>{i18n.t("PLEASE_SELECT_A_CURRENCY")}</div> :
                props.panel === "Market Rates" ?
                    <div className={classes.currencyType}>{props.currencyType}</div> :
                    <div className={classes.currencyType}>{currencyDsc}</div>}

            <div className={classes.graphActions}>
                <div className={classes.graphActionsItem}>
                    <div className={classes.graphActionsItemType}>{i18n.t("BUY2")}</div>

                    {props.currencyType !== "OTHER" ?
                        <div><span className={classes.graphActionsItemValue}>124,</span><span className={classes.float}>61</span></div> : <div className={classes.graphActionsItemValue}></div>}

                </div>
                <div className={classes.graphSeperator}></div>
                <div className={classes.graphActionsItem}>
                    <div className={classes.graphActionsItemType}>{i18n.t("SELL2")}</div>

                    {props.currencyType !== "OTHER" ?
                        <div className={classes.graphActionsItemValue}>124,<span className={classes.float}>61</span></div> : <div className={classes.graphActionsItemValue}></div>}

                </div>

                {props.showMaxIcon ?
                    <div onClick={() => { props.toggleMaximize(); }}>
                        <div className={classes.minimizeIcon}> <img src={minimize} alt="minimize" /> </div>
                    </div> : null}
            </div>
        </div>);
}

export default MiniGraphHeader;