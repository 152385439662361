import React, { Component } from 'react';
import ArrowIcon from '../../../components/UI/ArrowIcon/ArrowIcon';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import classes from './BankAccount.module.css';

class BankComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filename: null,
    };
  }

  setFileAttributes = (file) => {
    this.setState({
      filename: file.filename,
    })
    this.props.assignSignedID(this.props.currency, file.signedBlobId)
  }

  render() {
    return (
      <div>
        <div className={classes.singleInput}>
          <div className={classes.moneyContainer}>
            <p className={classes.money}>{this.props.symbol}</p>
          </div>

          <div className={classes.dropdown}>
            <Dropdown
              items={this.props.bank_names}
              onSelectDropdown={(selected_value, id) => this.props.onSelectBank(selected_value, id, this.props.currency)}
            />
          </div>
          <input
            placeholder="IBAN number"
            value={this.props.iban}
            className={classes.inputs}
            onChange={(e) => this.props.handleIban(e, this.props.currency)}
          />
          <ArrowIcon setFileAttributes={this.setFileAttributes} />
        </div>

        {this.state.filename ?
          <div style={{ width: "500px", display: "flex", justifyContent: "flex-end", alignItems: "center", color: "#D39C3E" }}>
            <i className="fas fa-check" style={{ fontSize: "15px", marginRight: "5px" }} />
            <div style={{ fontSize: "10px" }}>{this.state.filename}</div>
          </div>
          :
          null}
      </div>
    );
  }
}

export default BankComponent;
