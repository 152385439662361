import i18n from 'i18next';
import React, { Component } from 'react';
import './myalert.scss';

export default class MyAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMode: true,
      editMode: false,
      newMode: false,
      saveMode: false,
      alert_obj: {
        'eur': { name: 'eur', list: [] },
        'usd': { name: 'usd', list: [] },
        'chf': { name: 'chf', list: [] },
        'gbp': { name: 'gbp', list: [] }
      },

      isloading: true,
      new_alerts_value: {
        'eur': { name: 'eur', value: 0, greater: true },
        'usd': { name: 'usd', value: 0, greater: true },
        'chf': { name: 'chf', value: 0, greater: true },
        'gbp': { name: 'gbp', value: 0, greater: true }
      },

      notified_radio_value: "email"
    };


    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    var first_euro = { id: 1, value: 1200000, greater: true };
    var second_euro = { id: 2, value: 1150000, greater: false };
    var third_euro = { id: 3, value: 1300000, greater: false };
    this.state.alert_obj['eur'].list.push(first_euro);
    this.state.alert_obj['eur'].list.push(second_euro);
    this.state.alert_obj['eur'].list.push(third_euro);

    var first_dollar = { id: 1, value: 1200000, greater: true };
    var second_dollar = { id: 2, value: 1150000, greater: false };
    var third_dollar = { id: 3, value: 1300000, greater: false };
    this.state.alert_obj['usd'].list.push(first_dollar);
    this.state.alert_obj['usd'].list.push(second_dollar);
    this.state.alert_obj['usd'].list.push(third_dollar);

    this.state.alert_obj['chf'].list.push(first_dollar);
    this.state.alert_obj['chf'].list.push(second_dollar);
    this.state.alert_obj['chf'].list.push(third_dollar);

    this.state.alert_obj['gbp'].list.push(first_dollar);
    this.state.alert_obj['gbp'].list.push(second_dollar);

    this.setState({ isloading: false });

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
     * Set the wrapper ref
     */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
    * close modal if clicked on outside of element
    */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.id !== 'my_alert_bell') {
      this.props.close_modal();
    }
  }


  render() {
    var euro_list = this.state.alert_obj['eur'].list.map((index, key) => {
      return (
        <div key={key} className="valute_column_text">
          €1  {index.greater ? "< " : "> "}{index.value} L
        </div>);

    });
    var usd_list = this.state.alert_obj['usd'].list.map((index, key) => {
      return (
        <div key={key} className="valute_column_text">
          $1  {index.greater ? "< " : "> "}{index.value} L
        </div>);

    });
    var chf_list = this.state.alert_obj['chf'].list.map((index, key) => {
      return (
        <div key={key} className="valute_column_text">
          €1  {index.greater ? "< " : "> "}{index.value} L
        </div>);

    });
    var gbp_list = this.state.alert_obj['gbp'].list.map((index, key) => {
      return (
        <div key={key} className="valute_column_text">
          €1  {index.greater ? "< " : "> "}{index.value} L
        </div>);

    });
    var AlertMode =
      <div>
        <div className="valute_row">
          <div className="valute_column">
            <div className="valute_column_title">EUR</div>
            {euro_list}
          </div>
          <div className="valute_v_seperator"></div>
          <div className="valute_column">
            <div className="valute_column_title">USD</div>
            {usd_list}
          </div>
        </div>
        <div className="valute_row">
          <div className="valute_column">
            <div className="valute_column_title">CHF</div>
            {chf_list}
          </div>
          <div className="valute_v_seperator"></div>
          <div className="valute_column">
            <div className="valute_column_title">GBP</div>
            {gbp_list}
          </div>
        </div>
        <div className="modal_hr"></div>
        <div className="text-center">
          <div><b>{i18n.t("HOW_DO_YOU_WANT_TO_BE_NOTIFIED")}</b></div>
          <div>
            <div className="d-flex justify-content-center" style={{ marginTop: '10px' }}>
              <div className="d-flex align-items-center" style={{ marginRight: '30px' }}>
                <div className="radio_design">
                  <input type="radio" onClick={event => { event.stopPropagation(); }} name="notified_type" onChange={() => { }} />
                  <span className='round_design'></span>
                </div>
                <span style={{ lineHeight: '16px', marginLeft: '5px' }}>{i18n.t("SMS")}</span>
              </div>
              <div className="d-flex align-items-center">
                <div className="radio_design">
                  <input type="radio" defaultChecked={true} onClick={event => { event.stopPropagation(); }} name="notified_type" onChange={() => { }} />
                  <span className='round_design'></span>
                </div>
                <span style={{ lineHeight: '16px', marginLeft: '5px' }}>{i18n.t("EMAIL1")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="modal_hr"></div>
      </div>;

    var euro_list_edit = this.state.alert_obj['eur'].list.map((index, key) => {
      return (
        <div key={key} className="valute_column_text">
          <i className="fas fa-times-circle" style={{ color: '#D39C3E', fontSize: '18px', marginRight: '5px', cursor: 'pointer' }} onClick={() => { alert('This Item should be deleted'); }}></i>
          €1  {index.greater ? "< " : "> "}{index.value} L
        </div>);

    });
    var usd_list_edit = this.state.alert_obj['eur'].list.map((index, key) => {
      return (
        <div key={key} className="valute_column_text">
          <i className="fas fa-times-circle" style={{ color: '#D39C3E', fontSize: '18px', marginRight: '5px', cursor: 'pointer' }} onClick={() => { alert('This Item should be deleted'); }}></i>
          $1 {index.greater ? "< " : "> "}{index.value} L
        </div>);

    });
    var chf_list_edit = this.state.alert_obj['chf'].list.map((index, key) => {
      return (
        <div key={key} className="valute_column_text">
          <i className="fas fa-times-circle" style={{ color: '#D39C3E', fontSize: '18px', marginRight: '5px', cursor: 'pointer' }} onClick={() => { alert('This Item should be deleted'); }}></i>
          €1  {index.greater ? "< " : "> "}{index.value} L
        </div>);

    });
    var gbp_list_edit = this.state.alert_obj['gbp'].list.map((index, key) => {
      return (
        <div key={key} className="valute_column_text">
          <i className="fas fa-times-circle" style={{ color: '#D39C3E', fontSize: '18px', marginRight: '5px', cursor: 'pointer' }} onClick={() => { alert('This Item should be deleted'); }}></i>
          €1  {index.greater ? "< " : "> "}{index.value} L
        </div>);

    });
    var EditMode =
      <div>
        <div className="valute_row">
          <div className="valute_column">
            <div className="valute_column_title">EUR</div>
            {euro_list_edit}
          </div>
          <div className="valute_v_seperator"></div>
          <div className="valute_column">
            <div className="valute_column_title">USD</div>
            {usd_list_edit}
          </div>
        </div>
        <div className="valute_row">
          <div className="valute_column">
            <div className="valute_column_title">CHF</div>
            {chf_list_edit}
          </div>
          <div className="valute_v_seperator"></div>
          <div className="valute_column">
            <div className="valute_column_title">GBP</div>
            {gbp_list_edit}
          </div>
        </div>
        <div className="modal_hr"></div>
        <div className="text-center">
          <div><b>{i18n.t("HOW_DO_YOU_WANT_TO_BE_NOTIFIED")}</b></div>
          <div>
            <div className="d-flex justify-content-center" style={{ marginTop: '10px' }}>
              <div className="d-flex align-items-center" style={{ marginRight: '30px' }}>
                <div className="radio_design">
                  <input type="radio" onClick={event => { event.stopPropagation(); }} name="notified_type" onChange={() => { }} />
                  <span className='round_design'></span>
                </div>
                <span style={{ lineHeight: '16px', marginLeft: '5px' }}>{i18n.t("SMS")}</span>
              </div>
              <div className="d-flex align-items-center">
                <div className="radio_design">
                  <input type="radio" defaultChecked={true} onClick={event => { event.stopPropagation(); }} name="notified_type" onChange={() => { }} />
                  <span className='round_design'></span>
                </div>
                <span style={{ lineHeight: '16px', marginLeft: '5px' }}>{i18n.t("EMAIL1")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="modal_hr"></div>
      </div>;


    var NewMode =
      <div>
        <div className="newITems">
          <div style={{ width: '25px' }}><i className="fas fa-bell cursor-pointer"></i></div>
          <div style={{ width: '140px', marginLeft: '5px', textAlign: 'left' }}>EUR</div>
          <div style={{ width: '25px', marginLeft: '5px' }}>€1</div>
          <div style={{ marginLeft: '5px', marginRight: '5px' }}>
            <span className="icon_changer" onClick={() => { this.setState(Object.assign(this.state.new_alerts_value.eur, { greater: !this.state.new_alerts_value['eur'].greater })); }}>
              {this.state.new_alerts_value["eur"].greater ? <i className="fas fa-greater-than-equal"></i> : <i className="fas fa-less-than-equal"></i>}
            </span>
          </div>
          <div style={{ width: '140px' }}>
            <input type="text" placeholder="0,00" onChange={this.props.eurval} className='myAlerts_input' />
          </div>
          <div style={{ flex: 1 }}>L</div>
        </div>

        <div className="newITems">
          <div style={{ width: '25px' }}><i className="fas fa-bell cursor-pointer"></i></div>
          <div style={{ width: '140px', marginLeft: '5px', textAlign: 'left' }}>USD</div>
          <div style={{ width: '25px', marginLeft: '5px' }}>$1</div>
          <div style={{ marginLeft: '5px', marginRight: '5px' }}>
            <span className="icon_changer" onClick={() => { this.setState(Object.assign(this.state.new_alerts_value.usd, { greater: !this.state.new_alerts_value['usd'].greater })); }}>
              {this.state.new_alerts_value["usd"].greater ? <i className="fas fa-greater-than-equal"></i> : <i className="fas fa-less-than-equal"></i>}
            </span>
          </div>
          <div style={{ width: '140px' }}>
            <input type="text" placeholder="0,00" onChange={this.props.eurval} className='myAlerts_input' />
          </div>
          <div style={{ flex: 1 }}>L</div>
        </div>

        <div className="newITems">
          <div style={{ width: '25px' }}><i className="fas fa-bell cursor-pointer"></i></div>
          <div style={{ width: '140px', marginLeft: '5px', textAlign: 'left' }}>GBP</div>
          <div style={{ width: '25px', marginLeft: '5px' }}>£1</div>
          <div style={{ marginLeft: '5px', marginRight: '5px' }}>
            <span className="icon_changer" onClick={() => { this.setState(Object.assign(this.state.new_alerts_value.gbp, { greater: !this.state.new_alerts_value['gbp'].greater })); }}>
              {this.state.new_alerts_value["gbp"].greater ? <i className="fas fa-greater-than-equal"></i> : <i className="fas fa-less-than-equal"></i>}
            </span>
          </div>
          <div style={{ width: '140px' }}>
            <input type="text" placeholder="0,00" onChange={this.props.eurval} className='myAlerts_input' />
          </div>
          <div style={{ flex: 1 }}>L</div>
        </div>

        <div className="newITems">
          <div style={{ width: '25px' }}><i className="fas fa-bell cursor-pointer"></i></div>
          <div style={{ width: '140px', marginLeft: '5px', textAlign: 'left' }}>CHF</div>
          <div style={{ width: '25px', marginLeft: '5px' }}>F1</div>
          <div style={{ marginLeft: '5px', marginRight: '5px' }}>
            <span className="icon_changer" onClick={() => { this.setState(Object.assign(this.state.new_alerts_value.chf, { greater: !this.state.new_alerts_value['chf'].greater })); }}>
              {this.state.new_alerts_value["chf"].greater ? <i className="fas fa-greater-than-equal"></i> : <i className="fas fa-less-than-equal"></i>}
            </span>
          </div>
          <div style={{ width: '140px' }}>
            <input type="text" placeholder="0,00" onChange={this.props.eurval} className='myAlerts_input' />
          </div>
          <div style={{ flex: 1 }}>L</div>
        </div>

        <div className="text-center"><b>{i18n.t("YOU_WILL_BE_NOTIFIED_BY")} <span>{this.state.notified_radio_value}</span></b></div>

        <div className="modal_hr"></div>
      </div>;

    var Header =
      <div className="modal_header">
        <div className="modal_header_title">{i18n.t("MY_ALERTS")}</div>
        <div className="modal_header_edit" onClick={this.pencil_action.bind(this)} style={this.state.editMode ? { opacity: 0.4 } : null}>
          <i className="fas fa-pen"></i>
        </div>
      </div>;

    if (this.state.newMode) {
      Header =
        <div className="modal_header">
          <div className="modal_header_title">{i18n.t("NEW_ALERT")}</div>
        </div>;

    }
    const MyAlertSavedSuccesfuly =
      <div className="SavedContainer" onClick={() => { this.setState({ editMode: false, saveMode: false }); }}>
        <div className="SavedContainerText">{i18n.t("CHANGES_SAVED")} <br /> {i18n.t("SUCCESSFULLY")}</div>
        <div className="SavedContainerIcon"><i className="fas fa-check"></i></div>
      </div>;

    return (
      <div ref={this.setWrapperRef} className="MyAlertModal">
        {Header}
        <div className="modal_body">
          <div>
            {this.state.alertMode ? AlertMode : null}
            {this.state.editMode ? EditMode : null}
            {this.state.newMode ? NewMode : null}

            <div className="text-center" style={{ marginTop: 20, width: "100%" }}>
              {this.state.newMode ? null : <div className="button_type1" onClick={this.newFunction.bind(this)}>{i18n.t("NEW")}</div>}
              <div className="button_type1" onClick={this.saveFunction.bind(this)}>{i18n.t("SAVE")}</div>
              <div className="button_type2" onClick={this.cancelFunction.bind(this)}>{i18n.t("CANCEL")}</div>
            </div>

          </div>
          {this.state.saveMode ? MyAlertSavedSuccesfuly : null}
        </div>
      </div>);

  }
  pencil_action() {
    if (this.state.alertMode) {
      this.setState({ alertMode: false, editMode: true });
    } else {
      this.setState({ alertMode: true, editMode: false });
    }
  }
  newFunction() {
    this.setState({ alertMode: false, editMode: false, newMode: true });
  }
  cancelFunction() {
    if (this.state.newMode) {
      this.setState({ alertMode: true, editMode: false, newMode: false });
    } else {
      this.props.close_modal();
    }
  }
  saveFunction() {
    if (this.state.newMode) {
      this.setState({ alertMode: true, editMode: false, newMode: false });
    } else {
      this.setState({ saveMode: true });
    }
  }
}
