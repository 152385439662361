import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../../CurrencyTrader/DossierModal/BuySell.module.scss';
import Button from '../../../../components/UI/Button/Button';
import CheckMark from '../../../../assets/icons/small_checkmark_blue.svg';
import NumberFormat from 'react-number-format';
import best_offer from '../../../../queries/CurrencyTrader/BestOffer';
import { graphql } from 'react-apollo';
import compose from 'lodash/fp/compose';
import flexible_order_purchase from '../../../../mutations/CurrencyTrader/CreateFlexiblePurchaseOrder';
import flexible_order_sale from '../../../../mutations/CurrencyTrader/CreateFlexibleSaleOrder';
import { API } from 'aws-amplify';
import Amplify from 'aws-amplify';

Amplify.configure({
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
    graphql_headers: async () => ({
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    })
  }
});




class OnlineOfferComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currency_symbol: this.props.currency_symbol,
      amount: this.props.amount,
      currency: this.props.currency,
      order_type: this.props.offer_order_type,
      cash_manager_rate: null,
      details_screen: false,
      order_completed: false,
      best_offer_screen: true,
      transaction_amount: null,
      best_offer_list: [],
      average_rate: null,
      total_value_selected: null,
      total_price: null,
      profit: null,
      selected_offers: [],
      button_active: false
    };

  }

  static getDerivedStateFromProps(props, state) {
    if (props !== state) {
      return {
        currency_symbol: props.currency_symbol
      };

    }
  }

  componentDidUpdate = async () => {

    if (this.props.amount !== this.state.transaction_amount || this.state.cash_manager_rate !== this.props.cash_manager_rate) {

      let rounded_amount;
      if (this.props.amount <= 50) {
        rounded_amount = 100;
      } else {
        rounded_amount = Math.ceil(this.props.amount / 50) * 50;
      }


      let best_offer_list = await API.graphql({
        query: best_offer,
        variables: {
          order_type: this.state.order_type,
          desired_amount: rounded_amount.toString(),
          currency: this.state.currency,
          paid_currency: "ALL"
        }
      });



      const rate = this.props.cash_manager_rate;
      best_offer_list = best_offer_list.data.bestOffer.bestOffer;

      if (this.state.order_type === "Sale") {
        best_offer_list = best_offer_list.filter(function (offer) {
          return offer.rate > rate;
        });
      } else {
        best_offer_list = best_offer_list.filter(function (offer) {
          return offer.rate < rate;
        });
      }


      const order_type = this.state.order_type;


      best_offer_list.forEach(function (element) {
        element.selected = false;
        if (order_type === "Sale") {
          element.pnl = element.amount_offered * element.rate - element.amount_offered * rate;
        } else {
          element.pnl = element.amount_offered * rate - element.amount_offered * element.rate;
        }

      });

      let total_value = 0;
      best_offer_list.map(offer => {
        total_value = total_value + offer.amount_offered;
        return null
      });


      this.setState({
        transaction_amount: this.props.amount,
        amount: total_value,
        currency_symbol: this.props.currency_symbol,
        cash_manager_rate: rate,
        best_offer_list
      });


    }
  };



  show_details = () => {

    let best_offer_list = [...this.state.best_offer_list];
    let selected_offers = best_offer_list.filter(function (offer) {
      return offer.selected === true;
    });

    const number_of_offers = selected_offers.length;
    let rate_sum = 0;
    let total_price = 0;
    let total_selected = 0;
    let profit = 0;
    selected_offers.map(offer => {
      rate_sum += offer.rate;
      total_selected += offer.amount_offered;
      total_price += offer.amount_offered * offer.rate;
      return null
    });
    const average_rate = rate_sum / number_of_offers;
    const cash_manager_price = total_selected * this.props.cash_manager_rate;

    if (this.state.order_type === "Sale") {
      profit = total_price - cash_manager_price;
    } else {
      profit = cash_manager_price - total_price;
    }

    this.setState({
      total_value_selected: total_selected,
      profit,
      total_price,
      average_rate,
      selected_offers,
      details_screen: true,
      best_offer_screen: false
    });

  };

  handleRadioButtons = offer_id => {
    let best_offer_list = [...this.state.best_offer_list];

    best_offer_list.map(offer => {
      if (offer.order_id === offer_id) {
        if (offer.selected) {
          offer.selected = false;
        } else {
          offer.selected = true;
        }
      }
      return null
    });

    if (best_offer_list.filter(e => e.selected === true).length > 0) {
      this.setState({
        button_active: true
      });

    } else {
      this.setState({
        button_active: false
      });

    }

    this.setState({
      best_offer_list
    });

  };

  confirmOrder = () => {
    if (this.state.order_type === "Sale") {
      this.onCreateSaleOrder();
    } else {
      this.onCreatePurchaseOrder();
    }
  };



  onCreateSaleOrder = () => {
    this.props.sell_mutation({
      variables: {
        attributes: {
          amountSoldCents: this.state.total_value_selected.toString(),
          amountSoldCurrency: this.state.currency,
          amountPaidCents: this.state.total_price.toString(),
          amountPaidCurrency: "ALL",
          status: "completed",
          datetime: new Date()
        },

        offer_list: this.state.selected_offers
      }
    }).then(res => {
      this.setState({
        order_completed: true,
        details_screen: false
      });

    }).catch(error => {

      if (error.message === "GraphQL error: Not enough limit remaining") {
        this.setState({
          error_message: "The requested amount exceeds your daily limit"
        });
      }
      else if (error.message === "GraphQL error: Currency Trader closed") {
        this.setState({
          error_message: i18n.t("CURRENCY_TRADER_CLOSED")
        })
      }
    });
  };

  onCreatePurchaseOrder = () => {
    this.props.purchase_mutation({
      variables: {
        attributes: {
          amountPurchasedCents: this.state.total_value_selected.toString(),
          amountPurchasedCurrency: this.state.currency,
          amountPaidCents: this.state.total_price.toString(),
          amountPaidCurrency: "ALL",
          status: "completed",
          datetime: new Date()
        },

        offer_list: this.state.selected_offers
      }
    }).then(res => {
      this.setState({
        order_completed: true,
        details_screen: false
      });

    }).catch(error => {

      if (error.message === "GraphQL error: Not enough limit remaining") {
        this.setState({
          error_message: "The requested amount exceeds your daily limit"
        });
      }
      else if (error.message === "GraphQL error: Currency Trader closed") {
        this.setState({
          error_message: i18n.t("CURRENCY_TRADER_CLOSED")
        })
      }
    });
  };



  render() {

    const description1 = this.state.order_type === "Sale" ? "sell" : "buy";
    const description2 = this.state.order_type === "Sale" ? "sold" : "bought";

    const offers = this.state.best_offer_list.map(offer => {
      return (
        <div className={classes.offer} key={offer.order_id}>
          <div style={{ marginRight: "10px" }}>
            <input type="radio" className={classes.formRadio}
              description="Individual"
              checked={offer.selected}
              onClick={() => this.handleRadioButtons(offer.order_id)}
              readOnly={true} />

            <span className={classes.offerDetails}>
              {offer.amount_offered} @ {offer.rate}
              <span> ( <NumberFormat displayType={"text"} thousandSeparator={true} suffix={" L"} value={(offer.amount_offered * offer.rate).toFixed(2)} /> {i18n.t("TOTAL")}</span>
            </span>
            <p className={classes.offerDetailsSmallText}>PNL <NumberFormat displayType={"text"} thousandSeparator={true} suffix={" L"} value={offer.pnl} /></p>
          </div>
        </div>);

    });

    return (
      <div className={classes.offersComponent}>
        <div className={classes.Head}>
          <p className={classes.TransactionText}>{i18n.t("ONLINE_OFFERS")}</p>
        </div>

        <div className={classes.container} style={{ alignItems: "center" }}>
          {this.state.total_value_selected ?
            <div>
              <div className={classes.offerDetailsSmallBold}>{this.state.order_completed ? i18n.t("YOU") + " " + description2 : i18n.t("YOU_WOULD_LIKE_TO") + " " + i18n.t(description1)}</div>
              <div className={classes.offersAmount}>
                <NumberFormat displayType={"text"} thousandSeparator={true} prefix={this.state.currency_symbol} value={this.state.total_value_selected} />
              </div>
            </div> :

            <div className={classes.offersAmount}>
              <NumberFormat displayType={"text"} thousandSeparator={true} prefix={this.state.currency_symbol} value={this.state.amount ? this.state.amount : "0,00"} />
            </div>}


          <hr />

          {this.state.best_offer_screen ?
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              {offers}
              <Button extraStyle={classes[!this.state.button_active ? "button_disabled" : null]} clicked={this.show_details} disabled={!this.state.button_active}> {description1.toUpperCase()} </Button>
            </div> :

            null}

          {this.state.details_screen ?
            <div className={classes.detailsWrapper}>
              <div style={{ display: "flex", flexDirection: "column", width: "80%" }}>
                <div className={classes.onlineOfferDetailContainer}>
                  <div className={classes.offerDetailsSmallBold} style={{ float: "left" }}> {i18n.t("AT_A_RATE_OF")} </div>
                  <div className={classes.offerDetailsMediumBold} style={{ float: "right" }}> {this.state.currency_symbol} 1 = {this.state.average_rate.toFixed(2)} L </div>
                </div>

                <div className={classes.onlineOfferDetailContainer}>
                  <div className={classes.offerDetailsSmallBold} style={{ float: "left" }}> {i18n.t("FOR_A_TOTAL_OF")} </div>
                  <div className={classes.offerDetailsMediumBold} style={{ float: "right" }}>
                    <NumberFormat displayType={"text"} thousandSeparator={true} suffix="L" value={this.state.total_price.toFixed(2)} />
                  </div>
                </div>
              </div>

              <hr />

              <div className={classes.offerDetailsSmallBold}>
                {i18n.t("YOU_WILL_EARN")} <span className={classes.offerDetailsMediumBold} style={{ margin: "5px" }}>
                  <NumberFormat displayType={"text"} thousandSeparator={true} suffix="L" value={this.state.profit.toFixed(2)} />
                </span> {i18n.t("ON_THIS_TRANSACTION")}
              </div>

              <div style={{ marginTop: "40px" }}>
                <Button clicked={() => this.confirmOrder()}> {i18n.t("CONFIRM")} </Button>
                <Button extraStyle={classes.cancelButton} clicked={() => this.setState({ best_offer_screen: true, details_screen: false, total_value_selected: null })}> {i18n.t("CANCEL")} </Button>
              </div>
            </div> :

            null}


          {this.state.order_completed ?
            <div className={classes.container} style={{ padding: "0px", width: "100%" }}>
              <div className={classes.firstPart}>
                <div className={classes.currenciesHeader} style={{ background: this.state.order_type === "Purchase" ? "#285A64" : "#BFD2C7" }}></div>
                <div className={[classes.currencies, classes[this.state.order_type]].join(' ')} style={{ paddingTop: "0px" }}>
                  <div>
                    <div className={classes.someText} style={{ marginTop: "20px", marginBottom: "20px" }}>

                      <span><img src={CheckMark} style={{ width: "30px", height: "25px", marginRight: "5px" }} alt="checkmark" /></span>
                      {i18n.t("THANK_YOU_FOR_YOUR_ORDER")}
                    </div>
                    <div className={classes.someText}>{i18n.t("YOU_CAN_FIND_DETAILS_OF_YOUR_O")} <br /> {i18n.t("THE_CURRENCY_TRADER_UNDER_THE")}</div>
                  </div>
                </div>
              </div>
            </div> :

            null}



        </div>
      </div>);

  }
}



export default compose(
  graphql(flexible_order_purchase, { name: 'purchase_mutation' }),
  graphql(flexible_order_sale, { name: 'sell_mutation' }))(
    OnlineOfferComponent);
