import i18n from 'i18next'; 
import React from 'react';
import classes from './graph.module.css';
import maximize from '../../../../assets/icons/Expand.svg';

function MaxiGraphHeader(props) {
  return (
    <div className={classes.maxiGraphHeader}>

      <div className={classes.columnElement}>
        <div className={classes.upperText}>
          <span className={classes.graphActionsItemType} style={{ fontSize: "25px" }}>{i18n.t("BUY2")} </span>
          <span className={classes.graphActionsItemValue} style={{ fontSize: "25px" }}>€1 = 124,</span>
          <span className={classes.float} style={{ fontSize: "35px" }}>61</span>
        </div>
        <div className={classes.smallText}>
          <span style={{ fontWeight: "bold" }}>{i18n.t("HRS1")} </span>
          <span>+1,02 (0,8%) 124,61 | 123,59</span>
        </div>

      </div>

      <div className={classes.graphSeperator2}></div>


      <div className={classes.columnElement}>
        <div>
          <span className={classes.graphActionsItemType} style={{ fontSize: "25px" }}>{i18n.t("SELL2")}</span>
          <span className={classes.graphActionsItemValue} style={{ fontSize: "25px" }}>€1 = 124,</span>
          <span className={classes.float} style={{ fontSize: "35px" }}>61</span>
        </div>
        <div className={classes.smallText}>
          <span style={{ fontWeight: "bold" }}>{i18n.t("HRS1")} </span>
          <span>+1,02 (0,8%) 124,61 | 123,59</span>
        </div>
      </div>

      {props.showMaxIcon ?
        <div onClick={() => { props.toggleMaximize(); }}>
          <div className={classes.maximizeIcon}> <img src={maximize} alt="maximize" /> </div>
        </div> : null}

    </div>);
}

export default MaxiGraphHeader;