import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './MyLogin.module.css';
import global from '../Register.module.css';
import Button from '../../../components/UI/Button/Button';
import CheckMark from '../../../assets/icons/small_checkmark_blue.svg';
import { graphql } from 'react-apollo';
import compose from 'lodash/fp/compose';
import create_user from '../../../mutations/Registration/CreateUser';
import resend_confirmation from '../../../mutations/Registration/ResendEmailConfirmation';
import confirm_email from '../../../mutations/Registration/ConfirmEmail';



class MyLogin extends Component {
  state = {
    enter_code_screen: false,
    email: "",
    password: "",
    password_confirmation: "",
    typology: this.props.account_type,
    token: "",
    error_message: null,
    user_id: null,
    graphql_error: ""
  };


  handleNext = () => {
    if (this.state.enter_code_screen) {
      this.ConfirmEmail();
    } else {
      this.CreateUserMutation();
    }
  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };

  CreateUserMutation = () => {
    const { email, password, password_confirmation, typology } = this.state;
    this.props.create_user({
      variables: { email, password, password_confirmation, typology }
    }).then(res => {
      this.setState({
        enter_code_screen: true,
        user_id: res.data.createUser.id,
        error_message: null
      });


      this.props.send_from_registration(res.data.createUser.id, res.data.createUser.email);

    }).catch(error => {
      console.log(error)
      const error_message = error.message.replace("GraphQL error: Invalid input: ", "");
      this.setState({
        error_message,
        graphql_error: error.message
      });

    });
  };

  resendEmailConfirmation = () => {
    this.props.resend_confirmation({
      variables: { id: this.state.user_id }
    }).then(
      this.setState({
        error_message: null
      }));


  };

  ConfirmEmail = () => {
    this.props.confirm_email({
      variables: { token: this.state.token }
    }).then(res => {
      if (res.data.confirmEmail.success) {
        this.props.clicked();
      } else {
        this.setState({
          error_message: i18n.t("YOU_TYPED_AN_INVALID_CODE"),
          graphql_error: "invalid code"
        });

      }
    }).catch(error => {
      console.log(error)
    });
  };


  check_input_error = (name) => {
    if (this.state.graphql_error.includes(name)) {
      return [classes.inputs, global.highlighted_input].join(' ')
    }
    else {
      return classes.inputs
    }
  }


  render() {
    return (
      <div className={classes.container}>
        <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
        <div className={classes.firstForm}>
          <p className={classes.firstTitle}>{this.props.type === "private_account" || "market_viewer" ? i18n.t("ENTER_YOUR_EMAIL_ADDRESS") : i18n.t("ENTER_THE_EMAIL_ADDRESS_OF_YOU")}</p>

          <div className={classes.singleInput} style={{ position: "relative" }}>
            <input
              placeholder="Email"
              name="email"
              className={this.check_input_error("Email")}
              value={this.state.email}
              onChange={this.handleInput}
              autoComplete="new-password" />

            {this.state.enter_code_screen ?
              <img src={CheckMark} style={{ position: "absolute", width: "25px", height: "20px", right: "-40px", top: "15px" }} alt="checkmark" /> :

              null}

          </div>

          <p style={{ fontSize: "12px", marginTop: "10px" }}>{i18n.t("THIS_IS_THE_EMAIL_YOU_WILL_USE1")}</p>
          <hr />
          <p className={classes.firstTitle}>{i18n.t("CREATE_A_PASSWORD_FOR_YOUR_ACC")}</p>
          <p style={{ fontSize: "12px" }}>{i18n.t("YOUR_PASSWORD_SHOULD_BE_CHAR")} <br /> {i18n.t("WITH_AT_LEAST_NUMBER_AND_S")}</p>
          <div style={{ width: "50%", display: "flex", justifyContent: "center" }}>
            <div style={{ width: "100%" }}>
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={this.state.password}
                className={this.check_input_error("Password")}
                onChange={this.handleInput} />

              <input
                type="password"
                name="password_confirmation"
                placeholder={i18n.t("RETYPE_PASSWORD")}
                value={this.state.password_confirmation}
                className={this.check_input_error("Password confirmation")}
                onChange={this.handleInput} />

            </div>
          </div>

          {this.state.enter_code_screen ?
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <hr />
              <p className={classes.firstTitle}>{i18n.t("ENTER_THE_CODE_SENT_TO_YOUR")} {this.props.type == null ? i18n.t("ADMIN_S") : null} {i18n.t("EMAIL4")}</p>
              <div style={{ width: "50%", display: "flex", justifyContent: "center" }}>
                <input
                  type="text"
                  placeholder={i18n.t("CODE")}
                  name="token"
                  value={this.state.token}
                  className={this.check_input_error("invalid code")}
                  onChange={this.handleInput} />

              </div>
              <p style={{ fontSize: "12px", marginTop: "10px" }}>{i18n.t("DIDN_T_RECEIVE_CODE")} <strong style={{ cursor: "pointer" }} onClick={() => this.resendEmailConfirmation()}>{i18n.t("CLICK_HERE1")}</strong></p>
            </div> :

            null}

        </div>
        <div className={classes.ButtonContainer}>
          <Button clicked={this.handleNext}>{i18n.t("NEXT")}</Button>
        </div>
      </div>);

  }
}


export default compose(
  graphql(create_user, { name: 'create_user' }),
  graphql(resend_confirmation, { name: 'resend_confirmation' }),
  graphql(confirm_email, { name: 'confirm_email' }))(
    MyLogin);
