import i18n from 'i18next';
import React, { Component } from 'react';
import CalendarComponent from './Calendar';
import classes from './SettingsCalendar.module.scss';
import NumberFormat from 'react-number-format';

import moment from 'moment';

var dateFormat = require('dateformat');

class SettingsCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(this.props.date.split("/").reverse().join("/")),
      date_input: dateFormat(new Date(this.props.date.split("/").reverse().join("/")), "dd/mm/yyyy")
    };


    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  cancelFunction = () => {
    this.props.closeModal();
  };

  saveFunction = () => {
    this.props.getDate(this.state.date);
    this.props.closeModal();
  };

  onChange = date => {

    this.setState({
      date: date,
      date_input: dateFormat(date, "dd/mm/yyyy")
    });

  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
     * Set the wrapper ref
     */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
    * close modal if clicked on outside of element
    */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.classList.value.search('calendarModalIconCM') === -1) {
      this.props.closeModal();
    }
  }

  date_input = e => {

    var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    let valid_format = e.target.value.match(dateformat);

    if (valid_format) {

      var dateMomentObject = moment(e.target.value, "DD/MM/YYYY");
      var dateObject = dateMomentObject.toDate();

      if (dateMomentObject.isValid()) {

        this.setState({
          date: dateObject
        });

      }


    }

    this.setState({
      date_input: e.target.value
    });


  };

  render() {

    return (
      <div ref={this.setWrapperRef} className={classes.modal_item}>
        <div className={classes.modal_header}>
          <div className={classes.settings_calendar_modal_header}>{i18n.t("SELECT_A_DATE")}</div>
          <div className={classes.modal_header_icon}>
            <i className="fas fa-redo-alt fa-flip-horizontal" onClick={() => this.setState({ date: new Date(), date_input: dateFormat(new Date(), "dd/mm/yyyy") })}></i>
          </div>
        </div>

        <div>

          <div className={classes.modal_body}>
            <NumberFormat format="##/##/####" value={this.state.date_input} onChange={this.date_input} style={{ width: "100%", textAlign: "right", height: "25px", marginBottom: "20px", height: "40px", color: "#285A64", paddingRight: "15px", fontWeight: "bold" }} />
            <CalendarComponent
              onChange={this.onChange}
              value={this.state.date} />
          </div>

          <div className={classes.action_buttons}>
            <div className="button_type1" onClick={this.saveFunction}>{i18n.t("SAVE")}</div>
            <div className="button_type2" onClick={this.cancelFunction}>{i18n.t("CANCEL")}</div>
          </div>
        </div>
      </div>);

  }
}


export default SettingsCalendar;
