import gql from 'graphql-tag';

export default gql`

mutation createBankAccount($bank_account_list: [JSON!]!, $user_id: ID!) {
  createBankAccount(bankAccountList: $bank_account_list, userId: $user_id) {
    success
  }
}


`;
