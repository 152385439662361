import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../BuySell.module.scss';
import Button from '../../../../../components/UI/Button/Button';
import ToolbarLogout from '../../../../../components/Toolbar/ToolbarLogout';
import { Link } from "react-router-dom";
import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';
import NumberFormat from 'react-number-format';
import fixed_order_purchase from '../../../../../mutations/CurrencyTrader/CreateFixedPurchaseOrder';
import fixed_order_sell from '../../../../../mutations/CurrencyTrader/CreateFixedSaleOrder';
import get_currency_trader_orders from '../../../../../queries/CurrencyTrader/GetOrders'
import bank_account from '../../../../../queries/CurrencyTrader/BankAccounts'
import compose from 'lodash/fp/compose';
import { graphql } from 'react-apollo';
import receive_client from "../../../../../Client";

const client = receive_client()


class RecapOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rate: this.props.location.state.rate,
      total: this.props.location.state.total,
      desired_amount: this.props.location.state.desired_amount.toString(),
      order_type: this.props.location.state.order_type,
      button_type: "NEXT",
      expiration_time: null,
      currency: this.props.location.state.currency,
      second_currency: this.props.location.state.second_currency,
      error_message: null,
      type: this.props.location.state.order_type,
      first_dropdown_list: [],
      second_dropdown_list: [],
      first_currency_bank_id: null,
      second_currency_bank_id: null,
      cancel_only: true,
      button_active: true
    }
  }

  handleDuration = (value, expiration_time) => {
    this.setState({
      error_message: null,
      expiration_time
    })
  }

  handleFirstCurrencyBank = (selected_iban, bank_id) => {
    this.setState({
      first_currency_bank_id: bank_id
    })
  }

  handleSecondCurrencyBank = (selected_iban, bank_id) => {
    this.setState({
      second_currency_bank_id: bank_id
    })
  }

  componentDidMount = async () => {
    const currency_bank_accounts = await client.query({ query: bank_account, variables: { currency: [this.state.currency, this.state.second_currency] } })

    let first_dropdown_list = []
    let first_currency_bank_id = null
    let second_dropdown_list = []
    let second_currency_bank_id = null

    currency_bank_accounts.data.bankAccounts.filter(e => e.currency === this.state.currency).map(currency => {
      if (currency.primary) {
        first_currency_bank_id = currency.id
      }
      first_dropdown_list.push({ value: currency.iban, id: currency.id, primary: currency.primary })
      return null;
    });

    currency_bank_accounts.data.bankAccounts.filter(e => e.currency === this.state.second_currency).map(currency => {
      if (currency.primary) {
        second_currency_bank_id = currency.id
      }
      second_dropdown_list.push({ value: currency.iban, id: currency.id, primary: currency.primary })
      return null;
    });


    this.setState({ first_dropdown_list, second_dropdown_list, first_currency_bank_id, second_currency_bank_id })
    if (first_currency_bank_id == null || second_currency_bank_id == null) {
      this.setState({
        error_message: i18n.t("YOU_DON_T_HAVE_AVAILABLE_BANK"),
      })
    }
    else {
      this.setState({
        cancel_only: false
      })
    }



    if (this.state.total === "") {
      this.setState({
        total: (this.state.desired_amount * this.state.rate).toFixed(2).toString()
      })
    }
    else if (this.state.rate === "") {
      this.setState({
        rate: (parseInt(this.state.total) / this.state.desired_amount).toFixed(4).toString()
      })
    }
  }

  onButtonClick = (button_type) => {
    if (button_type === "NEXT") {
      if (this.state.expiration_time == null) {
        this.setState({
          error_message: i18n.t("PLEASE_ADD_EXPIRATION_TIME")
        })
      }
      else {
        this.setState({
          button_type: "CONFIRM"
        })
      }
    }
    else if (button_type === "CONFIRM") {
      this.setState({
        button_active: false
      })
      {
        this.state.order_type === "Purchase" ?
          this.onCreatePurchaseOrder(
            this.state.desired_amount,
            this.state.currency,
            this.state.total,
            this.state.second_currency,
            "pending",
            new Date(),
            this.state.expiration_time,
            this.state.first_currency_bank_id,
            this.state.second_currency_bank_id
          )
          :
          this.onCreateSaleOrder(
            this.state.desired_amount,
            this.state.currency,
            this.state.total,
            this.state.second_currency,
            "pending",
            new Date(),
            this.state.expiration_time,
            this.state.first_currency_bank_id,
            this.state.second_currency_bank_id
          )
      }
    }
  }


  onCreatePurchaseOrder = (amountPurchasedCents, amountPurchasedCurrency, amountPaidCents, amountPaidCurrency, status, datetime, expiration, purchased_currency_bank, paid_currency_bank) => {
    this.props.purchase_mutation({
      variables: { attributes: { amountPurchasedCents, amountPurchasedCurrency, amountPaidCents, amountPaidCurrency, status, datetime }, expiration, purchased_currency_bank, paid_currency_bank },
      refetchQueries: [{ query: get_currency_trader_orders }]
    }).then(res => {
      this.props.history.push({
        pathname: "/completedorder",
        state: { order_type: "Purchase", page: "recap" }
      })

    }).catch(error => {
      console.log(error)
      this.setState({
        button_active: true
      })
      if (error.message === "GraphQL error: Not enough limit remaining") {
        this.setState({
          error_message: i18n.t("THE_REQUESTED_AMOUNT_EXCEEDS_Y")
        })
      }
      else if (error.message === "GraphQL error: Currency Trader closed") {
        this.setState({
          error_message: i18n.t("CURRENCY_TRADER_CLOSED")
        })
      }
    })
  }

  onCreateSaleOrder = (amountSoldCents, amountSoldCurrency, amountPaidCents, amountPaidCurrency, status, datetime, expiration, sold_currency_bank, paid_currency_bank) => {
    this.props.sell_mutation({
      variables: { attributes: { amountSoldCents, amountSoldCurrency, amountPaidCents, amountPaidCurrency, status, datetime }, expiration, sold_currency_bank, paid_currency_bank },
      refetchQueries: [{ query: get_currency_trader_orders }]
    }).then(res => {
      this.props.history.push({
        pathname: "/completedorder",
        state: { order_type: "Sale", page: "recap" }
      })
    }).catch(error => {
      console.log(error)
      this.setState({
        button_active: true
      })
      if (error.message === "GraphQL error: Not enough limit remaining") {
        this.setState({
          error_message: i18n.t("THE_REQUESTED_AMOUNT_EXCEEDS_Y")
        })
      }
      else if (error.message === "GraphQL error: Currency Trader closed") {
        this.setState({
          error_message: i18n.t("CURRENCY_TRADER_CLOSED")
        })
      }
    })
  }

  render() {
    const received_data = this.props.location.state
    const second_currency_symbol = this.props.location.state.secondCurrencySymbol


    let current_time = new Date().getHours();

    let available_timeframes = [];
    if (14 - current_time <= 0 || 14 - current_time > 2) {
      available_timeframes = [{ id: "in one hour", value: i18n.t("IN_ONE_HOUR") }, { id: "in two hours", value: i18n.t("IN_TWO_HOURS") }, { id: "by the end of the day", value: i18n.t("BY_THE_END_OF_THE_DAY") }];
    } else
      if (14 - current_time <= 1 && 14 - current_time <= 2) {
        available_timeframes = [{ id: "by the end of the day", value: i18n.t("BY_THE_END_OF_THE_DAY") }];
      } else
        if (14 - current_time <= 2) {
          available_timeframes = [{ id: "in one hour", value: i18n.t("IN_ONE_HOUR") }, { id: "by the end of the day", value: i18n.t("BY_THE_END_OF_THE_DAY") }];
        }
    return (
      <div>
        <ToolbarLogout />
        <div className={classes.ComponentBestOffer}>
          <div className={classes.Component}>
            <div className={classes.Head}>
              <div className={classes.TransactionText}>{i18n.t("RECAP_FIXED_ORDER")}</div>
            </div>
            <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
            <div className={classes.container} style={{ paddingTop: "0px" }}>
              <div className={classes.currenciesHeader} style={{ background: this.state.order_type === "Purchase" ? "#285A64" : "#BFD2C7" }}> </div>
              <div className={[classes.currencies, classes[this.state.order_type]].join(' ')}>
                <div className={classes.currencies_content}>
                  <p className={classes.someText}>{i18n.t("YOU_WOULD_LIKE_TO")} {this.state.type === "Purchase" ? i18n.t("BUY5") : i18n.t("SELL5")}</p>
                  <p className={classes.boldText}>{received_data.currencyDescription}:
                                  <NumberFormat style={{ marginLeft: "10px" }} displayType={"text"} thousandSeparator={true} prefix={received_data.currencySymbol} value={received_data.desired_amount} /> </p>

                </div>
              </div>

              <div className={[classes.currencies, classes[this.state.order_type]].join(' ')}>
                <div className={classes.currencies_content}>
                  <p className={classes.someText}>{i18n.t("AT_A_RATE_OF_AT")} {this.state.type === "Purchase" ? i18n.t("MOST") : i18n.t("LEAST")}</p>
                  <p className={classes.boldText}>{received_data.currencySymbol}1 =
                                    <NumberFormat style={{ marginLeft: "10px" }}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={this.state.second_currency === "ALL" ? "L" : null}
                      prefix={this.state.second_currency === "ALL" ? null : second_currency_symbol}
                      value={this.state.rate}
                    />
                  </p>

                </div>
              </div>

              <div className={[classes.currencies, classes[this.state.order_type]].join(' ')} >
                <div className={classes.currencies_content}>
                  <p className={classes.someText}>{i18n.t("FOR_A_TOTAL_OF_AT")} {this.state.type === "Purchase" ? i18n.t("MOST") : i18n.t("LEAST")}</p>
                  <p className={classes.boldText}>
                    <NumberFormat
                      style={{ marginLeft: "10px" }}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={second_currency_symbol !== "L" ? second_currency_symbol : null}
                      suffix={second_currency_symbol !== "L" ? null : second_currency_symbol}
                      value={this.state.total}
                    />
                  </p>
                </div>
              </div>

              {this.state.button_type === "NEXT" ?

                <div className={[classes.currencies, classes[this.state.order_type]].join(' ')} style={{ display: "flex", flexDirection: "column" }}>
                  <div className={classes.someText}>{i18n.t("IF_THESE_CONDITIONS_ARE_NOT_ME")} </div>
                  <div className="dropdown" style={{ marginTop: "10px", width: "50%" }}>
                    <Dropdown key="firstDropdown"
                      items={available_timeframes}
                      onSelectDropdown={this.handleDuration}
                    />
                  </div>
                  <div className={classes.someText} style={{ marginTop: "10px" }}>{i18n.t("YOUR_ORDER_WILL_BE_CANCELLED")}</div>
                </div>

                :

                <div className={[classes.currencies, classes[this.state.order_type]].join(' ')} style={{ display: "flex", flexDirection: "column" }}>
                  <div className={classes.someText}> {i18n.t("SELECT_THE_ACCOUNTS_YOU_WOULD")} </div>
                  <div className="dropdown" style={{ marginTop: "10px", width: "60%" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                      <div style={{ width: "40px", fontWeight: "bold", marginRight: "10px" }}>{this.state.currency}</div>
                      <Dropdown key="secondDropdown"
                        items={this.state.first_dropdown_list}
                        onSelectDropdown={this.handleFirstCurrencyBank}
                        selectedItem={this.state.first_dropdown_list.filter(account => account.primary === true)[0].value}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                      <div style={{ width: "40px", fontWeight: "bold", marginRight: "10px" }}>{this.state.second_currency}</div>
                      <Dropdown key="thirdDropdown"
                        items={this.state.second_dropdown_list}
                        onSelectDropdown={this.handleSecondCurrencyBank}
                        selectedItem={this.state.second_dropdown_list.filter(account => account.primary === true)[0].value}
                      />
                    </div>
                  </div>
                </div>
              }

            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px 30px" }}>
              {this.state.cancel_only ? null : <Button extraStyle={classes[!this.state.button_active ? "button_disabled" : null]} disabled={!this.state.button_active} clicked={() => this.onButtonClick(this.state.button_type)}> {this.state.button_type} </Button>}
              <Link to="/dashboard"><Button extraStyle={classes.cancelButton} > {i18n.t("CANCEL")} </Button> </Link>

            </div>
          </div>
        </div>
      </div>

    );
  }
}



export default compose(
  graphql(fixed_order_purchase, { name: 'purchase_mutation' }),
  graphql(fixed_order_sell, { name: 'sell_mutation' })
)(RecapOrder);
