import i18n from 'i18next';
import React, { Component } from 'react';
import ToolbarRegistration from '../../components/Toolbar/ToolbarRegistration';
import classes from './Register.module.css';
import MyLogin from './MyLogin/MyLogin';
import MyDailyLimit from './MyDailyLimit/MyDailyLimit';
import ServicePoint from './ServicePoint/ServicePoint';
import Administrator from './Administrator/Administrator';
import AdminDocuments from './AdminDocuments/AdminDocuments';
import Contract from './Contract/Contract';
import Final from './Final/Final';
import FirstFloor from '../../components/UI/FirstFloor/FirstFloor';
import Slider from '../../components/Slider/Slider';
import Headquarters from './Headquarters/Headquarters';
import BankAccount from './BankAccount/BankAccount';


class RegisterBusiness extends Component {
  state = {
    property: [
      {
        active: this.props.location.state ? 0 : 1,
        activated: 0
      },

      {
        active: this.props.location.state && (this.props.location.state.registrationPhase === "user_email_confirmed" ? 1 : 0),
        activated: 0
      },

      {
        active: this.props.location.state && (this.props.location.state.registrationPhase === "registration_service_point" ? 1 : 0),
        activated: 0
      },

      {
        active: this.props.location.state && (this.props.location.state.registrationPhase === "registration_headquarter" ? 1 : 0),
        activated: 0
      },

      {
        active: this.props.location.state && (this.props.location.state.registrationPhase === "registration_my_administrator" ? 1 : 0),
        activated: 0
      },

      {
        active: this.props.location.state && (this.props.location.state.registrationPhase === "registration_admin_documents" ? 1 : 0),
        activated: 0
      },

      {
        active: this.props.location.state && (this.props.location.state.registrationPhase === "registration_bank_account" ? 1 : 0),
        activated: 0
      },

      {
        active: this.props.location.state && (this.props.location.state.registrationPhase === "registration_daily_limit" ? 1 : 0),
        activated: 0
      },

      {
        active: 0,
        activated: 0
      }],


    screen: 'info',
    current: 0,
    user_id: this.props.location.state ? this.props.location.state.userId : null,
    user_email: this.props.location.state ? this.props.location.state.userEmail : null,
    account_type: this.props.location.typology
  }

  take_from_registration = (user_id, user_email) => {
    this.setState({
      user_id,
      user_email
    });

  };

  service = position => {
    switch (Number(position)) {
      case 0:
        return <MyLogin clicked={() => this.nextHandler(position)} account_type={this.state.account_type} send_from_registration={this.take_from_registration} />;
      case 1:
        return <ServicePoint clicked={() => this.nextHandler(position)} type={"company"} user_id={this.state.user_id} />;
      case 2:
        return <Headquarters clicked={() => this.nextHandler(position)} user_id={this.state.user_id} />;
      case 3:
        return <Administrator clicked={() => this.nextHandler(position)} active={this.state.current === 3} user_id={this.state.user_id} email={this.state.user_email} />;
      case 4:
        return <AdminDocuments clicked={() => this.nextHandler(position)} user_id={this.state.user_id} />;
      case 5:
        return <BankAccount clicked={() => this.nextHandler(position)} active={this.state.current === 5} user_id={this.state.user_id} />;
      case 6:
        return <MyDailyLimit clicked={() => this.nextHandler(position)} active={this.state.current === 6} user_id={this.state.user_id} />;
      case 7:
        return <Contract clicked={() => this.nextHandler(position)} active={this.state.current === 6} user_id={this.state.user_id} />;
      case 8:
        return <Final path={this.props} active={this.state.current === 7}></Final>;
      default:
        return <div>{i18n.t("ERROR")}</div>;
    }

  };

  nextHandler = position => {
    position = Number(position);
    const newActive = this.state.property; //active:1 activated:0
    let newCurrent = this.state.current; // current: 0

    newActive[position] = {
      active: 0,
      activated: 1
    };


    newActive[position + 1] = {
      active: 1,
      activated: 0
    };


    newCurrent = position + 1;


    this.setState({ property: newActive, current: newCurrent });

  };

  close = () => {
    //TORNA A LOGIN
  };


  sliderClickHandler = id => {
    const newActive = this.state.property;
    let newCurrent;
    if (this.state.property[id].activated === 1) {//se l'opzione era già stata sbloccata
      newActive[id] = {
        active: 1,
        activated: 0
      };


      newActive[this.state.current] = {
        active: 0,
        activated: 1
      };


      newCurrent = id;

      this.setState({ property: newActive, current: newCurrent });
    }
  };

  render() {

    let registerArray = [];

    for (let key in this.state.property) {
      registerArray.push({
        id: key,
        config: this.state.property[key],
        type: this.state.property[key].active ? "Active" : "Disabled"
      });

    }

    if (registerArray[8].type === "Active") {
      registerArray[8].type = "Final";
    }

    let register =
      <div>
        {registerArray.map((formElement) =>
          <div className={[classes.Component, classes["Position" + (formElement.id == 8 ? 9 : formElement.id) ], classes[formElement.type]].join(' ')} key={formElement.id} style={formElement === registerArray[5] && formElement.type === "Active" ? { width: "unset" } : null}>
            <FirstFloor state={formElement.config} />
            <div className={classes.Head} />
            {this.service(formElement.id)}
          </div>)}

      </div>;


    return (
      <div className={classes.Register}>

        <ToolbarRegistration />

        <Slider state={this.state.property} clicked={id => this.sliderClickHandler(Number(id))} current={2} />

        <div className={classes.Components}>

          {register}

        </div>
      </div>);

  }
}

export default RegisterBusiness;
