import i18n from 'i18next';
import React, { useState } from 'react';
import classes from './Fiscalization.module.css';
import Button from '../../../components/UI/Button/Button';
import NumberFormat from 'react-number-format';
import { graphql } from 'react-apollo';
import AddFiscalizationData from '../../../mutations/Fiscalization/AddFiscalizationData';
import compose from 'lodash/fp/compose';
import { useMutation } from 'react-apollo';


const Fiscalization = (props) => {

  const [addFiscalizationData] = useMutation(AddFiscalizationData);
  const [message, setMessage] = useState(null);
  const [tvsh, setTvsh] = useState(false);
  const [fiscData, setFiscData] = useState({
    tcrCode: "",
    businessUnitCode: "",
    operatorCode: "",
  });

  const handleInputChange = (event) => {
      event.persist();
      const fieldName = event.target.name;
      setFiscData({ ...fiscData, [fieldName]: event.target.value });
  }

  const onSave = () => {
    addFiscalizationData({
        variables: {
          attributes: {
            tcrCode: fiscData.tcrCode,
            businessUnitCode: fiscData.businessUnitCode,
            operatorCode: fiscData.operatorCode,
            tvsh: tvsh
          },
          user_id: props.user_id,
        }
    }).then(result => {
        setMessage(null)
        props.clicked();
    }).catch(error => {
        formatErrorMEssage(error.message)
    });
  }

  const formatErrorMEssage = (message) => {
    let new_msg = message.replace('GraphQL error: ',"").replaceAll(',', '\n')
    setMessage(<p style={{ color: "#9A2020", whiteSpace:"pre-line", textAlign:"start" }}>{new_msg}</p>)
  }


  return (
    <div className={classes.container}>
      <div>{message}</div>
      <div className={classes.firstForm}>
        <p className={classes.firstTitle}>{i18n.t("PROVIDE_FISCALIZATION_DATA")}</p>
        <div className={classes.singleInput}>
          <input
            className={classes.inputs}
            name="tcrCode"
            placeholder="TCR Code"
            onChange={handleInputChange}
          />
          <input
            className={classes.inputs}
            name="businessUnitCode"
            placeholder="Business Unit Code"
            onChange={handleInputChange}
          />
          <input
            className={classes.inputs}
            name="operatorCode"
            placeholder="Operator Code"
            onChange={handleInputChange}
          />
          <div style={{display:"flex", alignItems:"center", marginTop:"10px"}}>
            <input type="radio" name="demo" id="radio-one" checked={tvsh} className={classes.formRadio}
              value="privacy"
              description="privacy"
              readOnly={true}
              onClick={() => setTvsh(!tvsh)}
            />
            <div style={{fontWeight:600}}>TVSH</div>
          </div>
        </div>
      </div>


      <div className={classes.ButtonContainer}>
        <Button clicked={() => onSave()}>{i18n.t("NEXT")}</Button>
      </div>
    </div>);

}

export default Fiscalization
