import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './RegisterMarketViewer.module.css';
import Final from './Final/Final';
import MyLogin from './MyLogin/MyLogin';
import MyInfo from '../Register/MyInfo/MyInfo.js';
import FirstFloor from '../../components/UI/FirstFloor/FirstFloor';
import PaymentData from './PaymentData/PaymentData';
import Contract from './Contract/Contract';
import Slider from '../../components/Slider/Slider';
import ToolbarRegistration from '../../components/Toolbar/ToolbarRegistration';
class RegisterMarketViewer extends Component {
  state = {
    property: [
      {
        active: this.props.location.state ? 0 : 1,
        activated: 0
      },

      {
        active: this.props.location.state && (this.props.location.state.registrationPhase === "user_email_confirmed" ? 1 : 0),
        activated: 0
      },

      {
        active: this.props.location.state && (this.props.location.state.registrationPhase === "registration_my_administrator" ? 1 : 0),
        activated: 0
      },

      {
        active: 0,
        activated: 0
      },

      {
        active: 0,
        activated: 0
      }],




    current: 0,
    account_type: "market_viewer",
    user_id: this.props.location.state ? this.props.location.state.userId : null,
    user_email: this.props.location.state ? this.props.location.state.userEmail : null
  };


  take_from_registration = (user_id, user_email) => {
    this.setState({
      user_id,
      user_email
    })
  }

  service = (position) => {
    switch (Number(position)) {
      case 0:
        return <MyLogin clicked={() => this.nextHandler(position)} type={"market_viewer"} account_type={this.state.account_type} send_from_registration={this.take_from_registration} />
      case 1:
        return <MyInfo clicked={() => this.nextHandler(position)} user_id={this.state.user_id} email={this.state.user_email} />
      case 2:
        return <PaymentData clicked={() => this.nextHandler(position)} user_id={this.state.user_id} />
      case 3:
        return <Contract clicked={() => this.nextHandler(position)} active={this.state.current === 3} user_id={this.state.user_id} typology={"market_viewer"} />
      case 4:
        return <Final path={this.props} active={this.state.current === 4}></Final>
      default:
        return <div>{i18n.t("ERROR")}</div>;
    }

  };

  nextHandler = position => {
    position = Number(position);
    const newActive = this.state.property; //active:1 activated:0
    let newCurrent = this.state.current; // current: 0

    newActive[position] = {
      active: 0,
      activated: 1
    };


    newActive[position + 1] = {
      active: 1,
      activated: 0
    };


    newCurrent = position + 1;


    this.setState({ property: newActive, current: newCurrent });

  };

  close = () => {
    //TORNA A LOGIN
  };


  sliderClickHandler = id => {
    const newActive = this.state.property;
    let newCurrent;
    if (this.state.property[id].activated === 1) {//se l'opzione era già stata sbloccata
      newActive[id] = {
        active: 1,
        activated: 0
      };


      newActive[this.state.current] = {
        active: 0,
        activated: 1
      };


      newCurrent = id;

      this.setState({ property: newActive, current: newCurrent });
    }
  };

  render() {

    let registerArray = [];

    for (let key in this.state.property) {
      registerArray.push({
        id: key,
        config: this.state.property[key],
        type: this.state.property[key].active ? "Active" : "Disabled"
      });

    }

    if (registerArray[4].type === "Active") {
      registerArray[4].type = "Final";
    }
    let register =
      <div>
        {registerArray.map((formElement) =>
          <div className={[classes.Component, classes["Position" + formElement.id], classes[formElement.type]].join(' ')} key={formElement.id}>

            <FirstFloor state={formElement.config} />
            <div className={classes.Head} />

            {this.service(formElement.id)}

          </div>)}


      </div>;




    return (
      <div className={classes.Register}>


        <ToolbarRegistration />

        <Slider state={this.state.property} clicked={id => this.sliderClickHandler(Number(id))} current={4} />

        <div className={classes.Components}>

          {register}

        </div>


      </div>);

  }
}


export default RegisterMarketViewer;
