import i18n from 'i18next';
import React, { Component } from 'react';
import Button from '../../../components/UI/Button/Button';
import Plus from '../../../components/UI/Plus/Plus';
import classes from './Administrator.module.css';
import global from '../Register.module.css';
import { graphql } from 'react-apollo';
import update_user from '../../../mutations/Registration/UpdateUser';
import NumberFormat from 'react-number-format';

class Administrator extends Component {
  state = {
    numberofemail: 1,
    numberofphone: 1,
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    cityOfBirth: "",
    personalIDNumber: "",
    address: "",
    cellphone: "",
    city: "",
    country: "",
    postalCode: "",
    error_message: null,
    graphql_error: ""
  };

  plusHandler = type => {
    let num;
    let typetype;
    let placetype;

    if (type === 'email') {
      num = this.state.numberofemail;
      this.setState({ numberofemail: num + 1 });
      typetype = 'email';
      placetype = 'Email';
    }

    if (type === 'fax') {
      num = this.state.numberoffax;
      this.setState({ numberoffax: num + 1 });
      typetype = 'text';
      placetype = 'Fax';
    }

    if (type === 'phone') {
      num = this.state.numberofphone;
      this.setState({ numberofphone: num + 1 });
      typetype = 'text';
      placetype = 'Phone';
    }

    const updatedOrderForm = {
      ...this.state.orderForm
    };

    let updatedFormElement = {
      elementType: 'input',
      elementConfig: {
        type: typetype,
        placeholder: placetype
      },
      value: '',
      validation: {
        required: true
      },
      valid: true
    };

    updatedOrderForm[type][num] = updatedFormElement;
    this.setState({ orderForm: updatedOrderForm });
  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  updateUser = () => {
    const { firstName, lastName, dateOfBirth, cityOfBirth, personalIDNumber, address, cellphone, city, country, postalCode } = this.state;
    this.props.mutate({
      variables: { attributes: { firstName, lastName, dateOfBirth, cityOfBirth, personalIDNumber, address, cellphone, city, country, postalCode }, user_id: this.props.user_id }
    }).then(res => {
      this.props.clicked();
    }).catch(error => {
      console.log(error.message)
      const error_message = i18n.t("PLEASE_FILL_ALL_REQUIRED_FIELD");
      this.setState({
        error_message,
        graphql_error: error.message
      });
    });
  };

  check_input_error = (name) => {
    if (this.state.graphql_error.includes(name)) {
      return [classes.inputs, global.highlighted_input].join(' ')
    }
    else {
      return classes.inputs
    }
  }

  render() {
    console.log(this.state.error_message)
    return (
      <div className={classes.container}>
        <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
        <div className={classes.firstForm}>
          <p className={classes.firstTitle}>{this.props.type === "private_account" ? i18n.t("LET_US_KNOW_WHO_YOU_ARE") : i18n.t("ENTER_THE_DATA_FOR_YOUR_ACCOUN")}</p>
          <div className={classes.doubleInput}>
            <div style={{ width: "48%" }}>
              <input
                placeholder="Name"
                name="firstName"
                value={this.state.firstName}
                className={this.check_input_error("First name")}
                onChange={this.handleInput}
                autoComplete="new-password" />
            </div>
            <div style={{ width: "48%" }}>
              <input
                placeholder="Surname"
                name="lastName"
                value={this.state.lastName}
                className={this.check_input_error("Last name")}
                onChange={this.handleInput}
                autoComplete="new-password" />
            </div>
          </div>
          <div className={classes.doubleInput}>
            <div style={{ width: "38%" }}>
              <NumberFormat
                format="##/##/####"
                placeholder="Date of birth"
                name="dateOfBirth"
                value={this.state.dateOfBirth}
                className={this.check_input_error("Date of birth")}
                onChange={(e) => this.handleInput(e)} />
            </div>
            <div style={{ width: "58%" }}>
              <input
                placeholder="City of birth"
                name="cityOfBirth"
                value={this.state.cityOfBirth}
                className={this.check_input_error("City of birth")}
                onChange={this.handleInput} />
            </div>
          </div>
          <div className={classes.singleInput}>
            <input
              placeholder="Personal ID number"
              name="personalIDNumber"
              value={this.state.personalIDNumber}
              className={this.check_input_error("Personal id number")}
              onChange={this.handleInput} />
          </div>
        </div>
        <div className={classes.secondForm}>
          <p className={classes.firstTitle}>{this.props.type === "private_account" ? i18n.t("PLEASE_ENTER_YOUR_CONTACT_INFO") : i18n.t("CONTACT_INFORMATION_FOR_YOUR_A")}  </p>
          <div className={classes.singleInput}>
            <input
              placeholder="Street Address"
              name="address"
              value={this.state.address}
              className={this.check_input_error("Address")}
              onChange={this.handleInput} />
          </div>
          <div className={classes.doubleInput}>
            <div style={{ width: "31%" }}>
              <input
                placeholder="Postal code"
                name="postalCode"
                value={this.state.postalCode}
                className={this.check_input_error("Postal code")}
                onChange={this.handleInput} />
            </div>
            <div style={{ width: "31%" }}>
              <input
                placeholder="City"
                name="city"
                value={this.state.city}
                className={this.check_input_error("City")}
                onChange={this.handleInput} />
            </div>
            <div style={{ width: "31%" }}>
              <input
                placeholder="Country"
                name="country"
                value={this.state.country}
                className={this.check_input_error("Country")}
                onChange={this.handleInput} />
            </div>
          </div>
          <div className={classes.doubleInput}>
            <div style={{ width: "100%" }}>
              <div style={{ width: "100%", display: "flex", marginTop: "10px", justifyContent: "flex-end", alignItems: "center" }}>
                <div style={{ fontSize: "12px", marginRight: "auto" }}>{i18n.t("THIS_IS_THE_EMAIL_YOU_WILL_USE")}</div>
                <Plus />
              </div>
              <input
                placeholder="Email"
                name="email"
                value={this.props.email || ""}
                className={classes.inputs}
                readOnly={true} />
            </div>
          </div>
          <div className={classes.doubleInput}>
            <div style={{ width: "100%" }}>
              <div style={{ width: "100%", display: "flex", marginTop: "10px", justifyContent: "flex-end", alignItems: "center" }}>
                <div style={{ fontSize: "12px", textAlign: "initial", marginRight: "auto" }}>{i18n.t("THIS_IS_THE_NUMBER_WHERE_YOU_W")} <br /> {i18n.t("REGARDING_YOUR_ACCOUNT_YOU_CA")}</div>
                <Plus />
              </div>
              <input
                placeholder="Cell phone"
                name="cellphone"
                value={this.state.cellphone}
                className={this.check_input_error("Cellphone")}
                onChange={this.handleInput} />
            </div>
          </div>
        </div>
        <div className={classes.ButtonContainer}>
          <Button clicked={this.updateUser}>{i18n.t("NEXT")}</Button>
        </div>
      </div>);
  }
}

export default graphql(update_user)(Administrator);
