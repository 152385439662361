import gql from 'graphql-tag';

export default gql`

mutation ($attributes: DocumentInput!, $user_id: ID!, $signedBlobId: ID) {
  createDocument(attributes: $attributes, userId: $user_id, signedBlobId: $signedBlobId) {
    id
  }
}

`;
