import React, { Component } from 'react';
import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';

class PopupInputComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      dropi: null
    };


  }

  handleTypedInput = (value, id) => {
    this.props.handleInput(value, id);
  };


  render() {

    const dropdown_list = [
      { value: "ALL", id: 1 },
      { value: "EUR", id: 2 },
      { value: "USD", id: 3 },
      { value: "GBP", id: 4 },
      { value: "CHF", id: 5 }];



    const dropdown = <Dropdown boxShadow='none' items={this.props.dropi ? this.props.dropi : dropdown_list}

      onSelectDropdown={(dropdown_value, item_id) => this.handleTypedInput(dropdown_value, item_id)} />;


    const input = <input onChange={e => { this.handleTypedInput(e.target.value); }} value={null} style={{ width: "100%", height: "100%", border: "none", outline: "none" }} />;

    return (
      <div style={{ fontWeight: "600", display: "flex", flexDirection: "column", flex: 1, marginLeft: "5px", marginRight: "5px" }}>
        <div style={{ display: "flex", alignItems: "center", paddingLeft: "10px", height: '30px', backgroundColor: "#285A64", marginBottom: "10px", color: "white" }}> {this.props.description} </div>
        <div style={{ flex: 1, border: "2px solid #cccc", paddingLeft: "5px" }}> {this.props.type === "input" ? input : dropdown}</div>
      </div>);
  }
}



export default PopupInputComponent;