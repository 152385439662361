import i18n from 'i18next';
import React, { Component } from 'react';
import '../myprofile.scss';
import InfoCard from '../Extra/infoCard';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import create_bank_account from '../../../../mutations/MyProfile/NewBankAccount';
import delete_bank_account from '../../../../mutations/MyProfile/DeleteBankAccount';
import payments from '../../../../queries/MyProfile/Payments';
import { graphql } from 'react-apollo';
import compose from 'lodash/fp/compose';
import ArrowIcon from '../../../../components/UI/ArrowIcon/ArrowIcon';

class PaymentsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments_display: true,
      paymentsDetails: false,
      createNew: false,
      icon_show: true,
      bank_names: [
        { value: "Alpha Bank" },
        { value: "American Bank of Investments" },
        { value: "BKT" },
        { value: "Credins Bank" },
        { value: "First Investment Bank" },
        { value: "Intesa SanPaolo" },
        { value: "OTP Bank" },
        { value: "ProCredit Bank" },
        { value: "Raiffeisen" },
        { value: "Tirana Bank" },
        { value: "Union Bank" },
        { value: "United Bank of Albania" },
      ],

      new_bank: null,
      new_iban: null,
      primary: false,
      signed_blob_id: null,
      error_message: ""
    }
  }

  paymentsDetailsFunction() {
    var paymentsDetails = this.state.paymentsDetails;
    if (!paymentsDetails) {
      this.setState({ payments_display: false, paymentsDetails: true, createNew: false });
    } else {
      this.setState({ payments_display: true, paymentsDetails: false, createNew: false });
    }
  }

  addNewFunction() {
    this.setState({
      payments_display: false,
      paymentsDetails: false,
      createNew: true,
      icon_show: false
    });

  }

  createBankAccount = () => {
    this.props.create_bank_account({
      variables: { attributes: { currency: this.props.currency, bankName: this.state.new_bank, iban: this.state.new_iban, primary: this.props.defaultChecked || this.state.primary }, signed_blob_id: this.state.signed_blob_id },
      refetchQueries: [{ query: payments }]
    }).then(res => {
      this.setState({
        payments_display: true,
        createNew: false,
        icon_show: true,
        error_message: "",
        signed_blob_id: null
      });
    }).catch(error => {
      console.log(error)
      if (error.message === "GraphQL error: signed ID missing") {
        this.setState({
          error_message: "You must upload a verification document for your bank account"
        })
      }
      else if (error.message.includes("GraphQL error: Invalid input: Iban is invalid")) {
        this.setState({
          error_message: "Iban is invalid"
        })
      }
    })
  }

  cancelCard() {
    this.setState({
      payments_display: true,
      createNew: false,
      icon_show: true
    });

  }
  checkChange(type, index) {
    this.props.checkChange(type, index);
  }

  onSelectBank = selectedValue => {
    this.setState({
      new_bank: selectedValue
    });

  };

  handleNewIban = e => {
    this.setState({
      new_iban: e.target.value
    });

  };

  changePrimaryBank = (e, bank_account_id) => {
    e.stopPropagation();
    this.props.create_bank_account({
      variables: { bank_id: bank_account_id },
      refetchQueries: [{ query: payments }]
    }).catch(error => {
      console.log(error);
    });
  };

  deleteBankAccount = (e, bank_account_id) => {
    e.stopPropagation();
    this.props.delete_bank_account({
      variables: { bank_id: bank_account_id },
      refetchQueries: [{ query: payments }]
    }).then(
      this.props.onErrorFired(false)).catch(error => {
        if (error.message === "GraphQL error: Cannot delete") {
          this.props.onErrorFired(true);
        }
      });
  };

  getCurrencyDescription = currency => {
    switch (currency) {
      case 'ALL': {
        return "ALBANIAN LEK";
      }
      case 'EUR': {
        return "EUROS";
      }
      case 'USD': {
        return "US DOLLARS";
      }
      case 'GBP': {
        return "POUND STERLING";
      }
      case 'CHF': {
        return "SWISS FRANCS";
      }
      default: {
        return "N/A";
      }
    }

  };

  setFileAttributes = (file) => {
    this.setState({
      signed_blob_id: file.signedBlobId
    })
  }

  render() {
    var payments_display = this.props.item.bank_accounts.map((bank_account, index) => {
      return (
        <div className="col-md-12" key={index} style={bank_account.status == "approvation" ? {opacity:"50%"} : null}>
          <div className="pa_text_t1" style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
            {this.props.deleteMode ?
              <i className="fas fa-times-circle" style={{ color: "#D39C3E", marginRight: "10px", fontSize: "20px" }} onClick={e => this.deleteBankAccount(e, bank_account.id)}></i> :
              <span className={bank_account.primary ? "primary_little_round" : null}></span>}
            {bank_account.iban}
          </div>
        </div>);

    });


    let paymentsDetails_list = this.props.item.bank_accounts.map((bank_account, index) => {
      return (
        <div className="text-left" key={index}>
          <div className="d-flex">
            <div className="radio_design">
              <input type="radio" defaultChecked={bank_account.primary ? true : false} onClick={e => this.changePrimaryBank(e, bank_account.id)} name="primary_address" />
              <span className='round_design'></span>
              <span></span>
            </div>
            <div className="primary_text_s2">{bank_account.iban}</div>
          </div>
        </div>);

    });
    var paymentsDetails_display =
      <div className="col-md-12">
        <div className="primary_text_s1">{i18n.t("PRIMARY")}</div>
        {paymentsDetails_list}
      </div>;

    var createNew =
      <div onClick={event => { event.stopPropagation(); }}>
        <div className="checkBox_container" style={{ marginTop: 15 }}>
          <div style={{ fontSize: "13px", marginBottom: "5px", color: "#881401" }}>
            {this.state.error_message}
          </div>
          {/*<div className="checkBox_container_row">
            <div className="checkBox_icon_side">
              <div className="checkBox_design">
                <input type="checkbox" checked={this.props.defaultChecked || this.state.primary} readOnly={true} />
                <span className='round_design' onClick={() => this.props.defaultChecked ? null : this.setState({ primary: !this.state.primary })}></span>
              </div>
            </div>
            <div className="checkBox_text_side">{i18n.t("SET_AS_PRIMARY_ACCOUNT")}</div>
          </div>*/}
        </div>
        <div className="d-flex" style={{ marginTop: 15 }}>
          <div style={{ flex: 1 }}>
            <Dropdown
              items={this.state.bank_names}
              onSelectDropdown={selected_value => this.onSelectBank(selected_value)} />

          </div>
          <div style={{ flex: 2 }}>
            <input style={{ padding: "10px" }} type="text" className="info_card_input" placeholder="IBAN number" onChange={this.handleNewIban} />
          </div>
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginLeft: 10 }}>
            <div><ArrowIcon setFileAttributes={this.setFileAttributes} highlight={false} /></div>
          </div>
        </div>
        <div className="text-center">
          <div className="helpMiniText">{i18n.t("USE_THE")} <span className="icon_cirlce_type2"><i className="fas fa-arrow-up"></i></span> {i18n.t("ICON_TO_UPLOAD_A_BANK_STATEMEN")}</div>
        </div>
        <div className="text-center" style={{ marginTop: 15, marginBottom: 20, width: "100%" }}>
          <div className="button_type1" onClick={() => { this.createBankAccount(); }}>{i18n.t("SAVE")}</div>
          <div className="button_type2" onClick={() => { this.cancelCard(); }}>{i18n.t("CANCEL")}</div>
        </div>
      </div>;

    return (
      <div className={this.state.createNew ? 'col-md-12' : 'col-md-6'}>
        <InfoCard
          title={this.getCurrencyDescription(this.props.item.currency)}
          icon={this.state.icon_show && !this.props.deleteMode}
          parentEvent={this.state.createNew ? null : this.paymentsDetailsFunction.bind(this)}
          childEvent={this.addNewFunction.bind(this)}
          show_message={this.props.item.bank_accounts.length === 1 && this.props.deleteMode === true}>

          <div>
            {this.state.payments_display ? payments_display : null}
            {this.state.paymentsDetails ? paymentsDetails_display : null}
            {this.state.createNew ? createNew : null}
          </div>
        </InfoCard>
      </div>);

  }
}


export default graphql(payments)(compose(
  graphql(create_bank_account, { name: 'create_bank_account' }),
  graphql(delete_bank_account, { name: 'delete_bank_account' }))(
    PaymentsInfo));
