import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../BuySell.module.scss';
import Button from '../../../../../components/UI/Button/Button';
import ToolbarLogout from '../../../../../components/Toolbar/ToolbarLogout';
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';

class ModifyOffer extends Component {

  constructor(props) {
    super(props)
    const arrived_data = this.props.location.state
    this.state = {
      currency: arrived_data.currency,
      currencySymbol: arrived_data.currencySymbol,
      second_currency: arrived_data.second_currency,
      secondCurrencySymbol: arrived_data.secondCurrencySymbol,
      rate_radio_button: true,
      total_radio_button: false,
      rate: "",
      total: "",
      desired_amount: typeof (arrived_data.total_previously_desired) != "undefined" ? arrived_data.total_previously_desired : arrived_data.remaining_amount,
      total_previously_desired: arrived_data.total_previously_desired,
      order_type: arrived_data.order_type,
      currencyDescription: arrived_data.currencyDescription

    }
  }


  handleRadioButtons = e => {

    if (e.target.name === "rate_radio_button") {
      this.setState({
        rate_radio_button: e.target.checked,
        total_radio_button: false,
        total: ""
      })
    }
    else {
      this.setState({
        total_radio_button: e.target.checked,
        rate_radio_button: false,
        rate: ""
      });

    }

  };

  handleInput = value => {
    this.setState({
      total: value.value
    });

  };

  handleRate = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };

  recapOrder = () => {
    this.props.history.push({
      pathname: "/recaporder",
      state: this.state
    });
  }

  render() {
    return (
      <div>
        <ToolbarLogout />
        <div className={classes.ComponentBestOffer}>
          <div className={classes.Component}>
            <div className={classes.Head} >
              <div className={classes.TransactionText}>{i18n.t("MODIFY_YOUR_ORDER")}</div>
            </div>
            <div className={classes.container} style={{ padding: "20px 40px 40px" }}>
              <div className={classes.currenciesHeader} style={{ background: this.state.order_type === "Purchase" ? "#285A64" : "#BFD2C7" }}></div>
              <div className={[classes.fixedOrder, classes[this.state.order_type]].join(' ')}>
                <div style={{ lineHeight: "0.5" }}>
                  <p className={classes.someText}>{i18n.t("HOW_MUCH_ARE_YOU_WILLING_TO_PA")}</p>
                  <div className={classes.top_part}>

                    <div className={classes.left_part}>
                      <input type="radio" name="rate_radio_button" value="rate" id="rate" checked={this.state.rate_radio_button} onChange={this.handleRadioButtons} className={classes.formRadio} description="Rate" />
                      <p className={classes.someText}>{i18n.t("RATE2")}</p>

                    </div>
                    <div className={classes.right_part}>
                      <input type="number" name={"rate"} className={classes.inputs} value={this.state.rate} onChange={this.handleRate} disabled={!this.state.rate_radio_button} placeholder={this.state.order_type === "Purchase" ? i18n.t("MAX_RATE") : i18n.t("MIN_RATE")} />
                    </div>

                  </div>

                  <div className={classes.bottom_part}>

                    <div className={classes.left_part}>
                      <input type="radio" name="total_radio_button" value="total" id="total" checked={this.state.total_radio_button} onChange={this.handleRadioButtons} className={classes.formRadio} description="Total" />
                      <p className={classes.someText}>{i18n.t("TOTAL1")}</p>
                    </div>
                    <div className={classes.right_part} style={{ width: "265px" }}>
                      <NumberFormat
                        name={"total"}
                        className={classes.inputs}
                        value={this.state.total}
                        onValueChange={value => this.handleInput(value)}
                        disabled={!this.state.total_radio_button}
                        placeholder={"Max value"}
                        thousandSeparator={true} />

                      <p className={classes.someText} style={{ marginLeft: "5px" }}>{this.state.secondCurrencySymbol}</p>
                    </div>
                  </div>

                </div>
              </div>




              <div className={classes.buttons} style={{ marginBottom: "0px" }}>
                <Button clicked={this.recapOrder}> {i18n.t("NEXT")} </Button>
                <Link to="/dashboard">  <Button extraStyle={classes.cancelButton}> {i18n.t("CANCEL")} </Button> </Link>
              </div>
            </div>
          </div>
        </div>
      </div>);

  }
}



export default ModifyOffer;
