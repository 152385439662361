import i18n from 'i18next';
import React, { Component } from 'react';
import Button from '../../../components/UI/Button/Button';
import Privacy from '../../../components/Terms/Privacy';
import classes from './Contract.module.css';
import { graphql } from 'react-apollo';
import upload_signed_contract from '../../../mutations/Registration/UploadSignedContract';
import create_direct_upload from '../../../mutations/FileUpload/UploadFile';
import { getFileMetadata, directUpload } from "../../../services/fileUpload";
import compose from 'lodash/fp/compose';
import RegistrationDocument from "../RegistrationDocument";
import send_payment_instructions from '../../../mutations/Registration/SendPaymentInstructions';

class Contract extends Component {
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
    this.state = {
      privacy: false,
      filename: null,
      signedBlobId: null,
      error_message: null,
      privacy_checked: false
    };

  }

  privacyVisualizer = () => {
    this.setState({ privacy: true });
  };

  privacyClose = () => {
    this.setState({ privacy: false });
  };

  handleClick = () => {
    this.myInput.current.click();
  };

  uploadFile = file => {
    getFileMetadata(file).then(input => {
      return this.props.createDirectUpload({ variables: { input: input } }).then(({ data }) => {
        const uploadData = data.directUpload;
        this.setState({
          filename: uploadData.filename,
          signedBlobId: uploadData.signedBlobId,
          error_message: null
        });

        return directUpload(uploadData.url, JSON.parse(uploadData.headers), file);
      });
    });
  };



  finishRegistration = () => {
    let error_message = null
    if (this.state.signedBlobId == null) {
      error_message = i18n.t("YOU_MUST_UPLOAD_THE_SIGNED_CON");
      this.setState({ error_message: error_message });
    }
    else if (!this.state.privacy_checked) {
      error_message = i18n.t("PLEASE_ACCEPT_PRIVACY_STATEMEN");
      this.setState({ error_message: error_message });
    }
    else {
      this.props.mutate({
        variables: { user_id: this.props.user_id, signedBlobId: this.state.signedBlobId },
      }).then(res => {
        this.props.clicked()
      }).catch(error => {
        console.log(error)
      })
    }
  }

  sendPaymentInstructions = () => {
    this.props.sendPaymentInstructions({
      variables: { user_id: this.props.user_id }
    }).catch(error => {
      console.log(error)
    });
  };

  render() {
    return (
      <div className={classes.container}>
        <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
        <div className={classes.firstForm}>
          <p className={classes.firstTitle}> {i18n.t("PLEASE_HAVE_YOUR_ACCOUNT_ADMIN")} <br /> {i18n.t("READ_AND_SIGN_THE_CONTRACT_AVA")}</p>
          <p style={{ fontSize: "12px" }}>{i18n.t("IF_YOU_NEED_EXTRA_TIME_DON_T")} <br /> {i18n.t("BEEN_SAVED_TO_YOUR_ACCOUNT_TH")} <br /> {i18n.t("WILL_AUTOMATICALLY_BE_DIRECTED")}</p>
          <RegistrationDocument type={"contract"}>
            <Button extraStyle={classes.buttonDownload}> {i18n.t("DOWNLOAD_CONTRACT")} </Button>
          </RegistrationDocument>
        </div>

        <div className={classes.secondForm}>
          <p className={classes.firstTitle}>{i18n.t("UPLOAD_THE_SIGNED_CONTRACT_HER")}</p>

          <div className={classes.singleInput}>
            <input type="file" id="file" ref={this.myInput} onChange={e => e.target.files[0] ? this.uploadFile(e.target.files[0]) : null} style={{ display: "none" }} />
            <Button extraStyle={classes.buttonUpload} clicked={this.handleClick}>
              <div className={classes.arrowContainer}>
                <i className="fas fa-arrow-up" style={{ fontSize: "20px" }}></i>
              </div>
              {i18n.t("UPLOAD")}
            </Button>
          </div>

          {this.state.filename ?
            <div style={{ color: "#D39C3E", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <i className="fas fa-check" style={{ fontSize: "15px", marginRight: "5px" }} />
              <div style={{ fontSize: "10px" }}>{this.state.filename}</div>
            </div> :

            null}
          <Privacy clicked={() => this.privacyClose()} show={this.state.privacy} />

          <div style={{ width: "85%", justifyContent: "center", display: "flex", alignItems: "center", margin: "20px" }}>
            <input type="radio" name="demo" id="radio-one" checked={this.state.privacy_checked} className={classes.formRadio}
              value="privacy"
              description="privacy"
              readOnly={true}
              onClick={() => this.setState({ privacy_checked: true, error_message: null })} />

            <div style={{ fontWeight: "bold" }}>{i18n.t("I_HAVE_READ_THE")}
              <RegistrationDocument type={"privacy_policy"}>
                <strong> {i18n.t("PRIVACY_STATEMENT1")} </strong>
              </RegistrationDocument>
            </div>
          </div>

          {this.props.typology === "market_viewer" ?
            <div style={{ fontWeight: "bold", margin: "20px" }}>
              <span style={{ color: "#D39C3E", cursor: "pointer" }} onClick={this.sendPaymentInstructions}> {i18n.t("RESEND")} </span>
              {i18n.t("PAYMENT_INSTRUCTIONS_EMAIL")}
            </div>
            :
            null
          }

          <div>
            <Button clicked={this.finishRegistration}>{i18n.t("SIGN_UP")}</Button>
          </div>
        </div>
      </div>
    );
  }
}


export default compose(
  graphql(upload_signed_contract),
  graphql(create_direct_upload, { name: 'createDirectUpload' }),
  graphql(send_payment_instructions, { name: 'sendPaymentInstructions' })
)(Contract);
