import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import registration_document from '../../queries/RegistrationDocuments/RegistrationDocument'

const RegistrationDocument = (props) => {
  const { loading, data } = useQuery(registration_document, {
    variables: { typology: props.type }
  });

  if (!loading) {
    return (
      <a href={data.registrationDocument}>
        {props.children}
      </a>
    )
  }
  else {
    return null
  }
}

export default RegistrationDocument;
