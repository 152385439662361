import i18n from 'i18next';
import React, { useState } from 'react';
import classes2 from '../../../CurrencyTrader/DossierModal/BuySell.module.scss';
import Button from '../../../../../components/UI/Button/Button';
import PopupInputComponent from './PopupInputComponent';
import DailySettingsRate from '../../DailySettings/DailySettingsRate';
import get_service_point_shifts_query from '../../../../../queries/CashManager/ServicePointShifts';
import get_exchange_rates_query from '../../../../../queries/CashManager/ExchangeRates';
import create_exchange_rate_mutation from '../../../../../mutations/CashManager/CreateExchangeRate';
import not_updated_service_point_shifts from '../../../../../queries/CashManager/NotUpdatedServicePointShift';
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from '@apollo/react-hooks';

const StartingPopup = props => {

  const { error, refetch } = useQuery(get_service_point_shifts_query);
  const { loading: loading_exchange, error: error_exchange, data: data_exchange, refetch: refetch_exchange } = useQuery(get_exchange_rates_query, {
    variables: { date: props.date }
  });

  const { refetch: refetch_not_updated } = useQuery(not_updated_service_point_shifts);


  const [goCreateExchangeRateMutation] = useMutation(create_exchange_rate_mutation);
  const [dropdown_value, set_dropdown_value] = useState(null);
  const [amount_value, set_amount_value] = useState(null);
  const [rate_value, set_rate_value] = useState(null);
  const [done] = useState(false);

  var dateFormat = require('dateformat');

  var currentdate = new Date();
  const current_date = ("0" + currentdate.getDate()) + "/" + ("0" + (currentdate.getMonth() + 1)).slice(-2) + "/" + currentdate.getFullYear();

  refetch();
  refetch_exchange();
  refetch_not_updated();

  const handleClickedNewExchangeRate = () => {
    goCreateExchangeRateMutation({
      variables: {
        attributes: {
          currency: dropdown_value,
          purchaseRate: parseFloat(amount_value),
          saleRate: parseFloat(rate_value)
        }
      }
    });



  };

  if (loading_exchange) return null;
  if (error_exchange) return `Error! ${error}`;
  var listItems_exchange = null;
  if (data_exchange.exchangeRates) {

    listItems_exchange = data_exchange.exchangeRates.map(record => {

      return (
        <div style={{ width: "90%", margin: "auto", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", marginBottom: "10px" }}>
          <DailySettingsRate
            key={record.id}
            currency={record.currency}
            purchase_rate={record.purchaseRate.toString()}
            sales_rate={record.saleRate.toString()}
            type={"exchange_rate"}
            popup={true}
            date_of_creation={dateFormat(record.datetime, "dd/mm/yyyy")}
            flex={1}
            marginLeft={"0px"} />
        </div>);

    });
  }

  return (
    <div style={{ marginTop: "25px" }}>
      <div style={{ width: "80%", height: "20%", margin: "auto", display: "flex", justifyContent: "space-between" }}>
        <PopupInputComponent type="dropdown" description={"Currency"} handleInput={value => set_dropdown_value(value)} value={done} />
        <PopupInputComponent type="input" description={"Purchase rate"} handleInput={value => set_amount_value(value)} value={done} />
        <PopupInputComponent type="input" description={"Sales rate"} handleInput={value => set_rate_value(value)} value={done} />
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px", fontWeight: "bold", marginTop: "10px", height: "15px" }}><span style={{ color: "#D39C3E", marginLeft: "3px" }}></span></div>

      <div style={{ display: "flex", width: "80%", height: "20%", margin: "auto", justifyContent: "flex-end", marginTop: "20px", marginBottom: "15px" }}>
        <div><Button clicked={handleClickedNewExchangeRate}>{i18n.t("SAVE")}</Button></div>
        <div><Button extraStyle={classes2.cancelButton}>{i18n.t("CANCEL")}</Button></div>
      </div>
      {listItems_exchange}
    </div>);

};

export default StartingPopup;
