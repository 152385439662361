import i18n from 'i18next';
import React, { Component } from 'react';
import Circle from '../UI/Circle/Circle';
import classes from './slider.module.css';

class Slider extends Component {
  state = {
    chosenAccountType: this.props.current
  };


  showSubComp = () => {
    switch (this.state.chosenAccountType) {
      case 1:
        return (
          <ul className={classes.PrivateAccountSlider}>
            <hr className={classes.hrPrivate} />
            <li>{i18n.t("MY_LOGIN")}  <Circle circleType={this.props.state[0]} /></li>
            <li>{i18n.t("MY_SERVICE_POINT")}  <Circle circleType={this.props.state[1]} /></li>
            <li>{i18n.t("MY_HEADQUARTERS")}  <Circle circleType={this.props.state[2]} /></li>
            <li>{i18n.t("MY_ADMINISTRATOR")}  <Circle circleType={this.props.state[3]} /></li>
            <li>{i18n.t("ADMIN_DOCUMENTS")}   <Circle circleType={this.props.state[4]} /></li>
            <li>{i18n.t("MY_BANK_ACCOUNT")}  <Circle circleType={this.props.state[5]} /></li>
            <li>{i18n.t("MY_DAILY_LIMIT")}  <Circle circleType={this.props.state[6]} /></li>
            <li>{i18n.t("FISCALIZATION_DATA")}  <Circle circleType={this.props.state[7]} /></li>
            <li>{i18n.t("CONTRACT_PRIVACY")}  <Circle circleType={this.props.state[8]} /></li>
          </ul>);

      case 2:
        return (
          <ul className={classes.PrivateAccountSlider}>
            <hr className={classes.hrPrivate} />
            <li>{i18n.t("MY_LOGIN")} <Circle circleType={this.props.state[0]} /></li>
            <li>{i18n.t("MY_COMPANY")}  <Circle circleType={this.props.state[1]} /></li>
            <li>{i18n.t("MY_ADMINISTRATOR")}  <Circle circleType={this.props.state[2]} /></li>
            <li>{i18n.t("EMAIL_CONFIRMATION")}<Circle circleType={this.props.state[3]} /></li>
            <li>{i18n.t("ADMIN_DOCUMENTS")}   <Circle circleType={this.props.state[4]} /></li>
            <li>{i18n.t("MY_BANK_ACCOUNT")}  <Circle circleType={this.props.state[5]} /></li>
            <li>{i18n.t("DOCUMENTATION")}<Circle circleType={this.props.state[6]} /></li>
            <li>{i18n.t("CONTRACT_PRIVACY")}  <Circle circleType={this.props.state[7]} /></li>
          </ul>);

      case 3:
        return (
          <ul className={classes.PrivateAccountSlider}>
            <hr className={classes.hrPrivate} />
            <li>{i18n.t("MY_LOGIN")}  <Circle circleType={this.props.state[0]} /></li>
            <li>{i18n.t("MY_INFORMATION")} <Circle circleType={this.props.state[1]} /></li>
            <li>{i18n.t("MY_DOCUMENTS")} <Circle circleType={this.props.state[2]} /></li>
            <li>{i18n.t("MY_BANK_ACCOUNT")}  <Circle circleType={this.props.state[3]} /></li>
            <li>{i18n.t("DAILY_LIMIT")} <Circle circleType={this.props.state[4]} /></li>
            <li>{i18n.t("CONTRACT_PRIVACY")}  <Circle circleType={this.props.state[5]} /></li>
          </ul>);

      case 4:
        return (
          <ul className={classes.MarketViewerSlider}>
            <hr className={classes.hr} />
            <li>{i18n.t("MY_LOGIN")} <Circle circleType={this.props.state[0]} /></li>
            <li>{i18n.t("MY_INFORMATION")}<Circle circleType={this.props.state[1]} /></li>
            <li>{i18n.t("PAYMENT_DATA")} <Circle circleType={this.props.state[2]} /></li>
            <li>{i18n.t("CONTRACT_PRIVACY")}  <Circle circleType={this.props.state[3]} /></li>
          </ul>);

      default:
        return (
          <ul className={classes.container}>
            <li>{i18n.t("MY_SERVICE_POINT")}  <Circle circleType={this.props.state[0]} /></li>
            <li>{i18n.t("MY_ADMINISTRATOR")}  <Circle circleType={this.props.state[1]} /></li>
            <li>{i18n.t("EMAIL_CONFIRMATION")}<Circle circleType={this.props.state[2]} /></li>
            <li>{i18n.t("ADMIN_DOCUMENTS")}   <Circle circleType={this.props.state[3]} /></li>
            <li>{i18n.t("MY_BANK_ACCOUNT")}  <Circle circleType={this.props.state[4]} /></li>
            <li>{i18n.t("DOCUMENTATION")}<Circle circleType={this.props.state[5]} /></li>
            <li>{i18n.t("CONTRACT_PRIVACY")}  <Circle circleType={this.props.state[6]} /></li>
          </ul>);
    }



  };

  render() {


    return (
      <div>
        {this.showSubComp()}
      </div>);

  }
}




export default Slider;
