import i18n from 'i18next'; 
import React, { useState } from 'react';
import classes from './BuySell.module.scss';
import CheckMark from '../../../../assets/icons/small_check_mark.svg';
import NumberFormat from 'react-number-format';

const PartialOption = props => {

  const [selected, setOptionSelected] = useState(false);

  const setSelected = condition => {
    setOptionSelected(condition);
    props.change_selected_options(props.order_id, condition);
  };

  return (
    <div className={[classes.currencies, classes[props.order_type]].join(' ')} style={{ width: "256px", height: "180px", paddingTop: "10px", flexDirection: "column" }}>
      <div style={{ alignSelf: "flex-start", justifySelf: "flex-start", width: "100%", position: "relative" }}>
        <div onClick={() => setSelected(true)} style={{ float: "left", borderRadius: "20px", background: "white", height: "25px", width: "25px", marginLeft: "10px", border: "1px solid #285A64" }}></div>
        {selected ? <img onClick={() => setSelected(false)} src={CheckMark} style={{ position: "absolute", width: "25px", height: "20px", left: 13 }} alt="checkmark" /> : null}
      </div>
      <div className={classes.currencies_content} style={{ marginTop: "20px" }}>
        <div className={classes.someText} style={{ marginBottom: "15px" }}>{i18n.t("ONLINE_OFFER")} {props.number}</div>
        <div className={classes.someText} style={{ marginBottom: "15px" }}>
          <NumberFormat style={{ marginLeft: "10px" }} displayType={"text"} thousandSeparator={true} prefix={props.currencySymbol} value={props.amount} />
          <span> @</span>
        </div>
        <div className={classes.boldText} style={{ marginBottom: "15px" }}>{props.rate}</div>
      </div>
    </div>);

};

export default PartialOption;