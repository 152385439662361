import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../BuySell.module.scss';
import ToolbarLogout from '../../../../../components/Toolbar/ToolbarLogout';
import CheckMark from '../../../../../assets/icons/big_check_mark.svg';
import NumberFormat from 'react-number-format';

class TransactionCompleted extends Component {

  constructor(props) {

    super(props);
    this.state = {
      currency: null,
      currencySymbol: null,
      currencyDescription: null,
      second_currency: null,
      secondCurrencySymbol: null,
      total_amount: null,
      average_rate: null,
      total_price: null,
      remaining_amount: null,
      best_offers: null,
      total: null,
      order_type: null,
      type: null
    };

  }

  imageClick = () => {
    this.props.history.push({
      pathname: '/dashboard'
    });

  };

  componentDidMount = () => {
    const arrived_data = this.props.location.state;
    if (arrived_data.page === "best_offer") {
      this.setState({
        total_amount: arrived_data.desired_amount,
        total_price: arrived_data.total_value,
        average_rate: arrived_data.average_rate,
        currencySymbol: arrived_data.currencySymbol,
        remaining_amount: arrived_data.remaining_amount,
        best_offers: arrived_data.best_offer_list,
        total: arrived_data.total_value,
        order_type: arrived_data.order_type,
        currency: arrived_data.currency,
        second_currency: arrived_data.second_currency,
        secondCurrencySymbol: arrived_data.secondCurrencySymbol,
        currencyDescription: arrived_data.currencyDescription,
        type: arrived_data.type
      });

    }
    if (arrived_data.page === "eur_transaction" || arrived_data.page === "recap") {
      this.setState({
        order_type: arrived_data.order_type
      });

    }
  };

  onAddNewFlexibleOrder = () => {
    this.props.history.push({
      pathname: "/modifyoffer",
      state: this.state
    });

  };



  render() {
    let offer_list = null;
    if (this.state.type === "all") {
      offer_list = this.state.best_offers.map(offer => {
        return (
          <div className={classes.someText} key={offer.order_id}>
            <NumberFormat
              displayType={"text"}
              thousandSeparator={true}
              prefix={this.state.currencySymbol}
              value={offer.amount_offered} /> @ {offer.rate}
          </div>);
      });
    }

    return (
      <div>
        <ToolbarLogout />
        <div className={classes.ComponentBestOffer}>
          <div className={classes.Component}>
            <div className={classes.Head}>
              <div className={classes.TransactionText}>{i18n.t("TRANSACTION_COMPLETED")}</div>
              <i
                className="fas fa-times-circle"
                style={{
                  cursor: "pointer",
                  color: "white",
                  fontSize: "20.33px",
                  marginLeft: "10px",
                  marginRight: "15px"
                }}

                onClick={() => this.imageClick()}>
              </i>
            </div>
            <div className={classes.container} style={{ padding: "30px 40px" }}>
              <div className={classes.currenciesHeader} style={{ background: this.state.order_type === "Purchase" ? "#285A64" : "#BFD2C7" }}></div>
              <div className={[classes.currencies, classes[this.state.order_type]].join(' ')} style={{ flexDirection: "column", height: "100%", paddingTop: "30px", paddingBottom: "30px" }}>
                <div className={classes.someText}>{i18n.t("THANK_YOU_FOR_YOUR_ORDER")}</div>
                <img src={CheckMark} style={{ width: "192px", height: "147px", color: "#285a64", marginTop: "10px", marginBottom: "10px" }} alt="checkmark" />


                {this.state.type === "all" ?
                  <div>
                    <div className={classes.someText}>{i18n.t("YOU")} {this.state.order_type === "Purchase" ? i18n.t("PURCHASED3") : i18n.t("SOLD")}</div>
                    {offer_list}
                    <div className={classes.someText} style={{ marginTop: "20px", marginBottom: "20px" }}>
                      {i18n.t("YOUR_TOTAL_IS")}
                      <span>
                        <NumberFormat displayType={"text"}
                          style={{ marginLeft: "5px" }}
                          thousandSeparator={true}
                          suffix={this.state.secondCurrency === "ALL" ? "L" : null}
                          prefix={this.state.secondCurrency === "ALL" ? null : this.state.secondCurrencySymbol}
                          value={this.state.total} />

                      </span>
                    </div>
                    <div className={classes.someText}>{i18n.t("WE_SENT_YOU_AN_EMAIL_CONFIRMAT")} <br />
                      {i18n.t("YOU_CAN_ALSO_FIND_THE_DETAIL_O")} <br />
                      {i18n.t("THE_CURRENCY_TRADER_UNDER_THE1")}
                    </div>
                  </div> :

                  this.state.type === "partial" || this.state.type === "single" ?

                    <div>
                      <div className={classes.someText}>
                        {i18n.t("YOU")} {this.state.order_type === "Purchase" ? i18n.t("PURCHASED") : i18n.t("SOLD")}
                        <NumberFormat
                          style={{ marginLeft: "5px" }}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={this.state.currency === "ALL" ? "L" : null}
                          prefix={this.state.currency === "ALL" ? null : this.state.currencySymbol}
                          value={this.state.total_amount} />

                        <br />
                        {i18n.t("AT_A")} {this.state.type === "partial" ? i18n.t("WEIGHTED_AVERAGE") : i18n.t("RATE3")} {i18n.t("OF")} <span> </span>
                        {this.state.currencySymbol}
                        1 = {this.state.second_currency !== "ALL" ? this.state.secondCurrencySymbol : "L"}
                        {this.state.average_rate}
                        {this.state.second_currency !== "ALL" ? null : "L"}
                        <br />
                        {i18n.t("FOR_A_TOTAL_OF")}
                        <NumberFormat
                          style={{ marginLeft: "5px" }}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={this.state.second_currency === "ALL" ? "L" : null}
                          prefix={this.state.second_currency === "ALL" ? null : this.state.secondCurrencySymbol}
                          value={this.state.total_price} />

                      </div>

                      <div className={classes.someText} style={{ marginTop: "20px" }}>{i18n.t("WE_SENT_YOU_AN_EMAIL_CONFIRMAT")} <br />
                        {i18n.t("YOU_CAN_ALSO_FIND_THE_DETAIL_O")} <br />
                        {i18n.t("THE_CURRENCY_TRADER_UNDER_THE1")}
                      </div>
                    </div> :



                    <div>
                      <div className={classes.someText}> {i18n.t("WE_WILL_SEND_EMAIL_UPDATES")} <br /> {i18n.t("REGARDING_THE_SATUS_OF_YOUR_OR")} </div>
                      <div className={classes.someText}>
                        {i18n.t("YOU_CAN_ALSO_FIND_THE_DETAIL_O")} <br />
                        {i18n.t("THE_CURRENCY_TRADER_UNDER_THE1")}
                      </div>
                    </div>}


              </div>

              {
                ["partial", "all"].includes(this.state.type) && this.state.remaining_amount >= 100 ?
                  <div className={[classes.currencies, classes[this.state.order_type]].join(' ')} style={{ flexDirection: "column", height: "100%", paddingTop: "30px", paddingBottom: "30px" }}>
                    <div className={classes.someText}>
                      {i18n.t("WOULD_YOU_LIKE_TO_CREATE_A_FIX")} <br />
                      {i18n.t("FOR_THE_REMAINING")}
                      <span>
                        <NumberFormat
                          style={{ marginLeft: "5px" }}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={this.state.currencySymbol}
                          value={this.state.remaining_amount} />
                        ?
                      </span>
                      <span style={{ color: "#D39C3E", cursor: "pointer" }} onClick={this.onAddNewFlexibleOrder}> {i18n.t("CLICK_HERE2")}</span>
                    </div>
                  </div> :

                  null}

            </div>


          </div>
        </div>
      </div>);

  }
}



export default TransactionCompleted;
