import React from 'react';
import Language from './Language/Language'
import NavigationItems from './NavigationItems/NavigationItems';
import classes from './Toolbar.module.css';

const ToolbarFooter = (props) => (
    <header className={classes.Toolbar}>
        <div className={classes.footerToolbar}>
            <div>Email: info@uchange.al</div>
            <div>Tel: +355 68 20 12590</div>
        </div>
    </header>
);

export default ToolbarFooter;
