import i18n from 'i18next';
import React, { Component } from 'react';
import Button from '../../../components/UI/Button/Button';
import BankComponent from './BankComponent';
import classes from './BankAccount.module.css';
import { graphql } from 'react-apollo';
import create_bank_account from '../../../mutations/Registration/CreateBankAccount';
import RegistrationDocument from "../RegistrationDocument";

class BankAccountSP extends Component {
  state = {
    formIsValid: false,
    termcondition: false,
    bank_accounts: [
      { currency: "ALL", symbol: "L", iban: "", bank_name: "", signed_blob_id: null },
      { currency: "EUR", symbol: "€", iban: "", bank_name: "", signed_blob_id: null },
      { currency: "GBP", symbol: "£", iban: "", bank_name: "", signed_blob_id: null },
      { currency: "USD", symbol: "$", iban: "", bank_name: "", signed_blob_id: null },
      { currency: "CHF", symbol: "₣", iban: "", bank_name: "", signed_blob_id: null }],

    bank_names: [
      { value: "Alpha Bank", id: "Alpha Bank" },
      { value: "American Bank of Investments", id: "American Bank of Investments" },
      { value: "BKT", id: "BKT" },
      { value: "Credins Bank", id: "Credins Bank" },
      { value: "First Investment Bank", id: "First Investment Bank" },
      { value: "Intesa SanPaolo", id: "Intesa SanPaolo" },
      { value: "OTP Bank", id: "OTP Bank" },
      { value: "ProCredit Bank", id: "ProCredit Bank" },
      { value: "Raiffeisen", id: "Raiffeisen" },
      { value: "Tirana Bank", id: "Tirana Bank" },
      { value: "Union Bank", id: "Union Bank" },
      { value: "United Bank of Albania", id: "United Bank of Albania" },
    ],

    terms_and_conditions: false,
    error_message: null
  };



  termVisualizer = () => {

  };

  handleIban = (e, currency) => {
    const bank_accounts = [...this.state.bank_accounts];
    bank_accounts.map(bank_account => {
      if (bank_account.currency === currency) {
        bank_account.iban = e.target.value;
      }
      return null
    });

    this.setState({
      bank_accounts
    });

  };

  createBankAccounts = () => {
    const bank_account_list = this.state.bank_accounts.filter(bank_account => bank_account.bank_name !== "" && bank_account.iban !== "");

    if (this.state.terms_and_conditions) {
      this.props.mutate({
        variables: { bank_account_list, user_id: this.props.user_id },
      }).then(res => {
        this.props.clicked()
      }).catch(error => {
        console.log(error);
        if (error.message === "GraphQL error: signed ID missing") {
          this.setState({
            error_message: i18n.t("YOU_MUST_UPLOAD_VERIFICATION_D")
          });
        }
        else if (error.message === "GraphQL error: Invalid input: Bank accounts is invalid") {
          this.setState({
            error_message: i18n.t("INVALID_IBAN")
          });
        }
      });
    } else {
      this.setState({
        error_message: i18n.t("PLEASE_CONFIRM_TERMS_AND_CONDI")
      });

    }
  };

  onSelectBank = (selectedValue, id, currency) => {
    const bank_accounts = [...this.state.bank_accounts];
    bank_accounts.forEach(bank_account => {
      if (bank_account.currency === currency) {
        bank_account.bank_name = id;
      }
    });

    this.setState({
      bank_accounts
    });

  };

  assignSignedID = (currency, signed_blob_id) => {
    const bank_accounts = [...this.state.bank_accounts];
    const bank_account = bank_accounts.find(bank => bank.currency === currency);
    bank_account.signed_blob_id = signed_blob_id;

    this.setState({
      bank_accounts
    });

  };

  render() {
    const bank_accounts = this.state.bank_accounts.map((bank_account, index) => {
      return (
        <BankComponent
          key={index}
          symbol={bank_account.symbol}
          iban={bank_account.iban}
          currency={bank_account.currency}
          bank_names={this.state.bank_names}
          onSelectBank={this.onSelectBank}
          handleIban={this.handleIban}
          assignSignedID={this.assignSignedID} />);


    });

    return (
      <div className={classes.container}>
        <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
        <div className={classes.firstForm}>
          <p className={classes.firstTitle}>{i18n.t("ENTER_THE_INFORMATION_FOR_EACH")} <br /> {i18n.t("ASSOCIATED_WITH_YOUR_SERVICE_P")}</p>

          <div className={classes.doubleInput}>
            {bank_accounts}
          </div>
        </div>


        <div className={classes.secondForm} style={{ marginBottom: "100px" }}>

          <div className={classes.singleInput}>
            <p className={classes.firstTitle}>
              {i18n.t("YOU_MUST_ALSO_UPLOAD_A_STATEME")} <br /> {i18n.t("EACH_OF_THE_ACCOUNTS_REPORTED")} <br />
              {i18n.t("USE_THE")}
              <span className={classes.arrowIcon}>
                <span className={classes.arrowContainer}>
                  <i className="fas fa-arrow-up" style={{ fontSize: "20px" }}></i>
                </span>
              </span>
              {i18n.t("ICON_NEXT_TO_EACH_ACCOUNT")} <br /> {i18n.t("TO_UPLOAD_YOUR_STATEMENTS")}
            </p>

          </div>

          <div style={{ width: "85%", justifyContent: "center", display: "flex", alignItems: "center", margin: "20px" }}>
            <input type="radio" name="demo" id="radio-one" className={classes.formRadio}
              value="conditions"
              checked={this.state.terms_and_conditions}
              description="conditions"
              readOnly={true}
              onClick={() => this.setState({ terms_and_conditions: !this.state.terms_and_conditions })} />

            <RegistrationDocument type={"terms_conditions"}>
              <div style={{ fontWeight: "bold", color: "#D39C3E" }} onClick={() => this.termVisualizer()}>{i18n.t("I_HAVE_READ_THE")} {i18n.t("TERMS_AND_CONDITIONS1")} </div>
            </RegistrationDocument>
          </div>

        </div>
        <div className={classes.ButtonContainer}>
          <Button clicked={this.createBankAccounts}>{i18n.t("NEXT")}</Button>
        </div>
      </div>);

  }
}


export default graphql(create_bank_account)(BankAccountSP);
