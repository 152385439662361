import i18n from 'i18next'; 
import React, { Component } from 'react';
import classes from '../../../../CurrencyTrader/DossierModal/BuySell.module.scss';
import Exit from '../../../../../../components/UI/Cros/Cros';
import { Link } from "react-router-dom";
import ToolbarLogout from '../../../../../../components/Toolbar/ToolbarLogout';

class SoldBusiness extends Component {

    render() {
        return (
            <div>
                <ToolbarLogout />
                <div className={classes.Component} style={{ height: "750px" }}>
                    <div className={classes.Head}>
                        <p className={classes.TransactionText}>{i18n.t("EXISTING_CLIENTS")}</p>
                        <div style={{ marginRight: "10px" }}>
                            <Link to="/soldtransaction"><Exit /> </Link>
                        </div>

                    </div>
                    <p className={classes.subtitle}>{i18n.t("BUSINESSES")}</p>
                    <div className={classes.container} style={{ padding: "10px 40px" }}>
                        <div className={classes.currenciesHeader}></div>
                        <div style={{ background: "white", display: "flex", justifyContent: "center", marginTop: "30px" }}>

                            <div className={classes.searchBar}>
                                <i className={"fas fa-search "} style={{ color: '#285A64', fontSize: '20px', justifyContent: 'baseline', margin: "5px" }}></i>
                            </div>


                        </div>
                    </div>

                </div>
            </div>);

    }
}



export default SoldBusiness;