import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './MyDailyLimit.module.css';
import Button from '../../../components/UI/Button/Button';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import currenciesObject from "../../../data/currencies";
import NumberFormat from 'react-number-format';
import { graphql } from 'react-apollo';
import create_daily_limit from '../../../mutations/Registration/CreateDailyLimit';
import standard_limits from '../../../queries/Limits/StandardLimits';
import compose from 'lodash/fp/compose';


class MyDailyLimit extends Component {

  currencies = currenciesObject[0];

  state = {
    firstDropdownItems: [
      { value: this.currencies['EUR'].description, id: this.currencies['EUR'].shortDsc },
      { value: this.currencies['USD'].description, id: this.currencies['USD'].shortDsc },
      { value: this.currencies['AUD'].description, id: this.currencies['AUD'].shortDsc },
      { value: this.currencies['CAD'].description, id: this.currencies['CAD'].shortDsc },
      { value: this.currencies['GBP'].description, id: this.currencies['GBP'].shortDsc },
      { value: this.currencies['CHF'].description, id: this.currencies['CHF'].shortDsc },
      { value: this.currencies['JPY'].description, id: this.currencies['JPY'].shortDsc },
      { value: this.currencies['SEK'].description, id: this.currencies['SEK'].shortDsc },
      { value: this.currencies['NOK'].description, id: this.currencies['NOK'].shortDsc },
      { value: this.currencies['DKK'].description, id: this.currencies['DKK'].shortDsc }],


    secondDropdownItems: [
      { value: i18n.t("LESS_THAN_L"), id: 1 },
      { value: i18n.t("L2"), id: 2 },
      { value: i18n.t("L3"), id: 3 },
      { value: i18n.t("MORE_THAN_L"), id: 4 }],


    thirdDropdownItems: [
      { value: i18n.t("ST_WEEK_OF_THE_MONTH"), id: 1 },
      { value: i18n.t("ND_WEEK_OF_THE_MONTH"), id: 2 },
      { value: i18n.t("RD_WEEK_OF_THE_MONTH"), id: 3 },
      { value: i18n.t("TH_WEEK_OF_THE_MONTH"), id: 4 }],

    desired_daily_limit: false,
    daily_limit_calcultion: false,
    preferred_currency: "",
    preferred_trading_amount: "",
    preferred_trading_period: "",
    trading_daily_limit: null,
    error_message: null,
    standard_limits: [],
    guarantee: 0
  };


  handleNext = () => {
    this.props.clicked();
  };

  onSelectDropdown1 = async (selectedValue, id) => {
    await this.setState({
      preferred_currency: id
    });

    this.dropdowns_completed();
  };

  onSelectDropdown2 = async (selectedValue, id) => {
    const limit = this.state.standard_limits[id - 1].limit
    await this.setState({
      preferred_trading_amount: id,
      trading_daily_limit: limit,
      guarantee: this.getGuarantee(parseInt(limit))
    });

    this.dropdowns_completed();
  };

  onSelectDropdown3 = async (selectedValue, id) => {
    await this.setState({
      preferred_trading_period: id
    });

    this.dropdowns_completed();
  };

  dropdowns_completed = () => {
    const { preferred_currency, preferred_trading_amount, preferred_trading_period } = this.state;

    if (preferred_currency && preferred_trading_amount && preferred_trading_period) {
      this.setState({
        daily_limit_calcultion: true
      });
    }
  };

  createDailyLimit = () => {
    const { preferred_currency, preferred_trading_amount, preferred_trading_period, trading_daily_limit, guarantee } = this.state;
    this.props.create_daily_limit({
      variables: { preferred_currency, preferred_trading_amount, preferred_trading_period, trading_daily_limit, guarantee: guarantee, user_id: this.props.user_id }
    }).then(res => {
      this.props.clicked();
    }).catch(error => {
      console.log(error)
      this.setState({
        error_message: i18n.t("PLEASE_FILL_ALL_REQUIRED_FIELD")
      });

    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.standard_limits !== this.props.standard_limits) {
      this.setState({
        standard_limits: this.props.standard_limits.standardLimits
      })
    }
  }

  getGuarantee(amount) {
    if (0 <= amount && amount <= 1000000) {
      return 15000;
    }
    else if (1000001 <= amount && amount <= 3000000) {
      return 45000;
    }
    else if (3000001 <= amount && amount <= 5000000) {
      return 75000;
    }
    else if (5000001 <= amount) {
      return 186000;
    }
  }

  handleDailyLimitChange = (e) => {
    this.setState({
      trading_daily_limit: e.value === "" ? null : e.value,
      guarantee: typeof (e.floatValue) == "undefined" ? 0 : this.getGuarantee(e.floatValue)
    })
  }

  render() {
    return (
      <div className={classes.container}>
        <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
        <div className={classes.firstForm}>
          <p className={classes.firstTitle}>{i18n.t("WHAT_IS_THE_CURRENCY_YOU_MOST")}</p>
          <Dropdown key="firstDropdown" items={this.state.firstDropdownItems}
            onSelectDropdown={this.onSelectDropdown1} />


          <p className={classes.firstTitle}>{i18n.t("HOW_MUCH_CURRENCY_DO_YOU_CHANG")}</p>
          <Dropdown key="secondDropdown" items={this.state.secondDropdownItems}
            onSelectDropdown={this.onSelectDropdown2} />


          <p className={classes.firstTitle}>{i18n.t("WHEN_DO_YOU_GENERALLY_CHANGE_C")}</p>
          <Dropdown key="thirdDropdown" items={this.state.thirdDropdownItems}
            onSelectDropdown={this.onSelectDropdown3} />




          {
            this.state.daily_limit_calcultion ?
              <div>
                <div style={{ fontSize: "12px", marginTop: "30px", marginBottom: "15px" }}>{i18n.t("BASED_ON_YOUR_ANSWERS_YOUR_TR")}</div>
                <div style={{ fontWeight: "bold" }}>
                  <NumberFormat style={{ marginLeft: "5px" }}
                    thousandSeparator={true} suffix="L"
                    displayType={this.state.desired_daily_limit ? null : "text"}
                    value={this.state.trading_daily_limit}
                    onValueChange={(e) => this.handleDailyLimitChange(e)}
                  />
                </div>

                <div style={{ fontSize: "12px", marginTop: "30px", marginBottom: "15px" }}>
                  {i18n.t("IN_ORDER_TO_BEGIN_TRADING_YOU")} <br />
                  {i18n.t("AMOUNT_OR_L_INTO_A_H", { amount: this.state.guarantee.toLocaleString(undefined, { maximumFractionDigits: 2 }) })} <br />
                  {i18n.t("GUARANTEE_IN_CASE_OF_LATE_OR")}
                </div>

                <div style={{ fontSize: "12px", fontWeight: "bold" }}>{i18n.t("TOO_HIGH_TOO_LOW_SET_YOUR_OW")}
                  <span style={{ color: "#D39C3E", cursor: "pointer" }} onClick={() => this.setState({ desired_daily_limit: true })}> {i18n.t("HERE")}</span>
                </div>
              </div> :
              null
          }


          <div style={{ width: "90%", display: "flex", justifyContent: "flex-end", marginTop: "30px", marginBottom: "30px", position: "absolute", bottom: "0px" }}>
            <Button clicked={this.createDailyLimit}>{i18n.t("NEXT")}</Button>
          </div>
        </div>
      </div>);

  }
}


export default (compose(
  graphql(create_daily_limit, { name: 'create_daily_limit' }),
  graphql(standard_limits, { name: 'standard_limits' })
)(MyDailyLimit));
