import i18n from 'i18next';
import React, { Component } from 'react';
import Button from '../../../components/UI/Button/Button';
import classes from './AdminDocuments.module.css';
import global from '../Register.module.css';
import { graphql } from 'react-apollo';
import create_document from '../../../mutations/Registration/CreateDocument';
import { getFileMetadata, directUpload } from "../../../services/fileUpload";
import create_direct_upload from '../../../mutations/FileUpload/UploadFile';
import compose from 'lodash/fp/compose';
import NumberFormat from 'react-number-format';

class AdminDocuments extends Component {
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
    this.state = {
      typology: null,
      description: "",
      number: "",
      expirationDate: "",
      issueDate: "",
      issueEntity: "",
      filename: null,
      signedBlobId: null,
      graphql_error: ""
    };

  }

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };

  createDocument = () => {
    const { typology, description, number, expirationDate, issueDate, issueEntity, signedBlobId } = this.state;
    this.props.mutate({
      variables: { attributes: { typology, description, number, expirationDate, issueDate, issueEntity }, user_id: this.props.user_id, signedBlobId }
    }).then(res => {
      this.props.clicked();
    }).catch(error => {
      console.log(error.message);
      let error_message = i18n.t("PLEASE_FILL_ALL_REQUIRED_FIELD");
      if (error.message === "GraphQL error: Invalid input: Typology can't be blank")
        error_message = i18n.t("SELECT_DOCUMENT_TYPE");
      if (error.message.includes('Identity document'))
        error_message = i18n.t("UPLOAD_ALL_REQUIRED_DOCUMENTS");
      this.setState({
        error_message,
        graphql_error: error.message
      });

    });
  };

  handleClick = () => {
    this.myInput.current.click();
  };

  uploadFile = file => {
    getFileMetadata(file).then(input => {
      return this.props.createDirectUpload({ variables: { input: input } }).then(({ data }) => {
        const uploadData = data.directUpload;
        this.setState({
          filename: uploadData.filename,
          signedBlobId: uploadData.signedBlobId
        });

        return directUpload(uploadData.url, JSON.parse(uploadData.headers), file);
      });
    });
  };

  check_input_error = (name) => {
    if (this.state.graphql_error.includes(name)) {
      return [classes.inputs, global.highlighted_input].join(' ')
    }
    else {
      return classes.inputs
    }
  }


  render() {

    return (
      <div className={classes.container}>
        <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
        <div className={classes.firstForm}>
          <p className={classes.firstTitle}> {i18n.t("ENTER_THE_INFORMATION_FROM_YOU")} </p>

          <div className={classes.radioBtns}>
            <div style={{ width: "31%", justifyContent: "center", display: "flex", alignItems: "center" }}>
              <input type="radio" name="demo" id="radio-one" className={classes.formRadio}
                value="passport"
                checked={this.state.typology === "passport" ? true : false}
                name="typology"
                onChange={this.handleInput} />

              <div>{i18n.t("PASSPORT1")}</div>
            </div>

            <div style={{ width: "31%", justifyContent: "center", display: "flex", alignItems: "center" }}>
              <input type="radio" name="demo" id="radio-two" className={classes.formRadio}
                value="id_card"
                checked={this.state.typology === "id_card" ? true : false}
                name="typology"
                onChange={this.handleInput} />

              <div>{i18n.t("IDENTITY_CARD")}</div>
            </div>


          </div>


          <div style={{ width: "85%" }}>
            <input
              placeholder="Name (as written on document)"
              name="description"
              value={this.state.description}
              className={this.check_input_error("Description")}
              onChange={this.handleInput} />

          </div>

          <div className={classes.doubleInput}>
            <div style={{ width: "50%" }}>
              <input
                placeholder="Number"
                name="number"
                value={this.state.number}
                className={this.check_input_error("Number")}
                onChange={this.handleInput} />
            </div>
            <div style={{ width: "45%" }}>
            <NumberFormat
              format="##/##/####"
              placeholder="Expiration date"
              name="expirationDate"
              value={this.state.expirationDate}
              className={this.check_input_error("Expiration date")}
              onChange={this.handleInput}  />
            </div>
          </div>
          <div className={classes.doubleInput}>
            <div style={{ width: "35%" }}>
            <NumberFormat
              format="##/##/####"
              placeholder="Date of issue"
              name="issueDate"
              value={this.state.issueDate}
              className={this.check_input_error("Issue date")}
              onChange={this.handleInput}  />
            </div>
            <div style={{ width: "60%" }}>
              <input
                placeholder="Issuing entity"
                name="issueEntity"
                value={this.state.issueEntity}
                className={this.check_input_error("Issue entity")}
                onChange={this.handleInput} />

            </div>
          </div>
        </div>
        <div className={classes.secondForm}>
          <p className={classes.firstTitle}> {i18n.t("UPLOAD_A_COPY_OF_THE_DOCUMENT")} </p>

          <Button extraStyle={classes.buttonUpload} clicked={() => this.handleClick()}>
            <input type="file" id="file" ref={this.myInput} onChange={e => e.target.files[0] ? this.uploadFile(e.target.files[0]) : null} style={{ display: "none" }} />
            <div className={classes.arrowContainer}>
              <i className="fas fa-arrow-up" style={{ fontSize: "20px" }}></i>
            </div>
            {i18n.t("UPLOAD")}
          </Button>

          {this.state.filename ?
            <div style={{ color: "#D39C3E", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px" }}>
              <i className="fas fa-check" style={{ fontSize: "15px", marginRight: "5px" }} />
              <div style={{ fontSize: "10px" }}>{this.state.filename}</div>
            </div> :

            null}
        </div>

        <div style={{ width: "90%", display: "flex", justifyContent: "flex-end", marginTop: "30px", position: "absolute", bottom: "20px" }}>
          <Button extraStyle={classes.buttonNext} clicked={this.createDocument}>{i18n.t("NEXT")}</Button>
        </div>
      </div>);

  }
}


export default compose(
  graphql(create_document),
  graphql(create_direct_upload, { name: 'createDirectUpload' }))(
    AdminDocuments);
