import i18n from 'i18next';
import React, { Component } from 'react';
import ToolbarLogin from '../../../components/Toolbar/ToolbarLogin';

import classes from '../Register.module.css';


class AccountSelection extends Component {

  constructor(props) {
    super();
  }

  servicePointHandler = () => {
    this.props.history.push({
      pathname: '/servicepointaccount',
      typology: "service_point"
    });
  }

  businessAccountHandler = () => {
    this.props.history.push({
      pathname: '/businessaccount',
      typology: "business_account"
    });
  }

  privateAccountHandler = () => {
    this.props.history.push({
      pathname: '/privateaccount',
      typology: "private_account"
    });

  }

  marketViewerHandler = () => {
    this.props.history.push({
      pathname: '/marketviewer',
      typology: "market_viewer"
    });

  }

  bankAccountHandler = () => {
    this.props.history.push({
      pathname: '/businessaccount',
      typology: "bank_account"
    });
  }

  render() {
    return (
      <div className={classes.AccountSelection}>
        <ToolbarLogin />
        <div className={classes.Component1}>
          <div className={classes.header}> </div>
          <div className={classes.welcomeText}>
            <p className={classes.welcomeText1}>{i18n.t("WELCOME_TO_CAESAR_S_PORTAL")}</p>
            <p className={classes.welcomeText2}>{i18n.t("WHAT_TYPE_OF_ACCOUNT_WOULD_YOU")} <br />
              {i18n.t("LIKE_TO_OPEN")}</p>
          </div>

          <div className={classes.accountTypeContainer}>
            <div className={[classes.accountType, "col"].join(' ')} onClick={() => this.servicePointHandler()}>
              <p className={classes.accountTypeText}> {i18n.t("SERVICE")}<br /> {i18n.t("POINT")} </p>
            </div>
            <div className={[classes.accountType, "col"].join(' ')} onClick={() => this.businessAccountHandler()}>
              <p className={classes.accountTypeText}> {i18n.t("ACCOUNT1")}<br />  {i18n.t("BUSINESS2")} </p>
            </div>
            <div className={[classes.accountType, "col"].join(' ')} onClick={() => this.bankAccountHandler()}>
              <p className={classes.accountTypeText}> {i18n.t("BANK")} </p>
            </div>
            <div className={[classes.accountType, "col"].join(' ')} onClick={() => this.privateAccountHandler()}>
              <p className={classes.accountTypeText}> {i18n.t("ACCOUNT1")} <br /> {i18n.t("PRIVATE")} </p>
            </div>
            <div className={[classes.accountType, "col"].join(' ')} onClick={() => this.marketViewerHandler()}>
              <p className={classes.accountTypeText}> {i18n.t("MARKET")} <br /> {i18n.t("VIEWER")} </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default AccountSelection;
