import i18n from 'i18next';
import React, { useState } from 'react';
import classes from '../CashManager.module.scss';
import TopUpComponent from './TopUpComponent/TopUpComponent';
import TopUpTransfer from './TopUpComponent/TopUpTransfer';
import get_top_ups from '../../../../queries/CashManager/TopUps';
import create_top_up from '../../../../mutations/CashManager/CreateTopUp';
import getSymbolFromCurrency from 'currency-symbol-map';
import TopUpsFilter from '../../../Modals/TopUpsFilter/TopUpsFilter';
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from '@apollo/react-hooks';
import New from '../../../Modals/New/New';
import FourDots from '../../../../components/UI/Icons/FourDots/FourDots';
import SearchEngine from './SearchEngine/SearchEngine';
import { attr } from 'highcharts';
import LoggedUser from '../../../../queries/LoggedUser';

const TopUps = props => {

  var dateFormat = require('dateformat');
  var currentdate = new Date();
  const current_date = ("0" + currentdate.getDate()) + "/" + ("0" + (currentdate.getMonth() + 1)).slice(-2) + "/" + currentdate.getFullYear();
  const current_time = " " + currentdate.getHours() + ":" + currentdate.getMinutes();
  const [userId, setUserId] = useState(null);

  const loggedUser = useQuery(LoggedUser, {
    onCompleted: () => {
      if (!loggedUser.loading && !loggedUser.error) {
        setUserId(loggedUser.data.currentUser.id);
      }
    }
  })
  const [date, setDate] = useState(current_date);
  const [categoriesSelectedList, setCategoriesSelectedList] = useState([]);
  const [attributes, setAttributes] = useState({
    category: null,
    description: null,
    maxDate: dateFormat(new Date, "dd/mm/yyyy"),
    minDate: dateFormat(new Date, "dd/mm/yyyy"),
    sort: null,
  });

  const { loading, data, refetch } = useQuery(get_top_ups, {
    variables:
    {
      attributes: attributes
    }
  });

  const [goMutation] = useMutation(create_top_up);


  const [newTopUpExchange, setNewTopUpExchange] = useState(false);
  const [newTopUpWithdrawal, setNewTopUpWithdrawal] = useState(false);
  const [newTopUpDesposit, setNewTopUpDeposit] = useState(false);
  const [newTopUpPayment, setNewTopUpPayment] = useState(false);
  const [newTopUpTransfer, setNewTopUpTransfer] = useState(false);
  const [topUpType, setTopUpType] = useState(null);
  const [newPlus, setNewPlus] = useState(false);
  const [message, setMessage] = useState(false);
  const [topUpsFilter, setTopUpsFilter] = useState(false);



  const handleClickedNew = (type) => {
    var n = topUpType.lastIndexOf('_');
    var result = topUpType.substring(n + 1).toLowerCase();

    const paid_currency = type.third_element === null ? null : type.third_element
    goMutation({
      variables:
      {
        attributes:
        {
          topUpAmountCurrency: type.currency ? type.currency.toUpperCase() : null,
          topUpAmountCents: type.first_element,
          rate: parseFloat(type.second_element),
          topUpPaidCurrency: paid_currency ? paid_currency.toUpperCase() : paid_currency,
          description: newTopUpExchange ? null : type.description,
          category: result
        }
      }
    }).then(
      result => {
        // success callback
      },
      error => {
        console.log(error);

        if (error.message === "GraphQL error: You dont have enough currency balance to complete this TopUp Exchange") {
          setMessage("You don't have enough currency balance to complete this TopUp Exchange");
        } else
          if (error.message === "GraphQL error: You can't withdrawal the amount requested. Not enough balance") {
            setMessage("You can't withdrawal the amount requested. Not enough balance");
          } else
            if (error.message === "GraphQL error: Currency session is not opened") {
              setMessage("Currency session is not opened");
            }
      });
    ;
    close_opened_windows()
  };

  refetch();

  var listItems = null;

  if (!loading) {
    if (data.topUps) {
      var date_displayed = null;
      var last_description = data.topUps.length > 0 ? data.topUps[data.topUps.length - 1].description : null
      listItems = data.topUps.map(record => {

        const type = record.topUpPaidCurrency ? "top_up_Exchange" : "top_up_Withdrawal";

        if (dateFormat(record.datetime, "dd/mm/yyyy") === dateFormat(currentdate, "dd/mm/yyyy")) {
          date_displayed = i18n.t("TODAY1");
        } else {
          date_displayed = dateFormat(record.datetime, "dd/mm/yyyy");
        }
        if(record.category == "transfer"){
          return(
            <TopUpTransfer
              key={record.id}
              editable={false}
              current_user={props.current_user}
              amount={record.topUpAmountCents}
              currency={record.topUpAmountCurrency}
              currency_symbol={getSymbolFromCurrency(record.topUpAmountCurrency)}
              issued_user={record.issuedToUserId}
              time={dateFormat(record.datetime, "HH:MM")}
              date={date_displayed}
              creator={record.user.name}
            />
          )
        }else{
          return (
            <TopUpComponent
              key={record.id}
              type={type}
              category={record.category}
              top_up_id={record.id}
              currency={record.topUpAmountCurrency}
              currency_symbol={getSymbolFromCurrency(record.topUpAmountCurrency)}
              amount={record.topUpAmountCents}
              date={date_displayed}
              time={dateFormat(record.datetime, "HH:MM")}
              rate={record.rate}
              paid_in={record.topUpPaidCurrency}
              action_handeled={true}
              onChildClicked={handleClickedNew}
              date_of_creation={dateFormat(record.datetime, "dd/mm/yyyy")}
              description={record.description}
              creator={record.user.name}
            />
          );
        }
      });
    }
  }

  const newModalToggle = () => {
    setNewPlus(!newPlus);
  };

  const close_opened_windows = () => {
    setNewTopUpExchange(false);
    setNewTopUpWithdrawal(false);
    setNewTopUpDeposit(false);
    setNewTopUpPayment(false);
    setNewTopUpTransfer(false);
  }

  const handleClickedModal = type => {
    close_opened_windows()
    setTopUpType(type)
    eval("setNewTopUp" + type.slice(7, type.length))(true)
    setNewPlus(!newPlus)
  };

  const topUpsFilterToggle = () => {
    setTopUpsFilter(!topUpsFilter);
  };

  const getDateFromCalendar = date => {
    setDate(dateFormat(date, "dd/m/yyyy"));
  };

  const closeFilterModal = (filters) => {

    setTopUpsFilter(false);
    if (filters) {
      setCategoriesSelectedList(filters.selectedList);
      let categoriesArray = [];
      filters.selectedList.forEach(item => categoriesArray.push(item.value));
      setAttributes({
        ...attributes,
        minDate: filters.min_date,
        maxDate: filters.max_date,
        category: categoriesArray.length > 0 ? categoriesArray : null
      });
    }
  }

  const searchFilter = (description) => {
    if (description !== null && description !== "")
      setAttributes({ ...attributes, description: description, sort: 'ASC' });
    else
      setAttributes({ ...attributes, description: null, sort: null });
  }

  const toggleSort = (sort) => {
    if (attributes.description !== null)
      setAttributes({ ...attributes, sort: sort });
    else
      setAttributes({ ...attributes, sort: null });
  }

  const removeDescription = () => {
    setAttributes({ ...attributes, description: null, sort: null });
  }

  const removeCategory = (category) => {
    let temp = attributes.category;
    const index = temp.indexOf(category);
    temp.splice(index, 1);
    setAttributes({ ...attributes, category: temp.length > 0 ? temp : null });
    let tempArray = categoriesSelectedList;
    const index1 = tempArray.findIndex(item => item.value === category);
    tempArray.splice(index1, 1);
    setCategoriesSelectedList([...tempArray]);
  }

  let descriptionHTML = null;
  if (attributes.description)
    descriptionHTML = <div><i className="fas fa-times-circle" style={{ color: "#d39c3e" }} onClick={removeDescription}></i> {attributes.description}</div>
  let categoryHTML = null;
  if (attributes.category)
    categoryHTML = attributes.category.map(item => <div><i className="fas fa-times-circle"
      style={{ color: "#d39c3e" }} onClick={() => removeCategory(item)}></i> {item}</div >)

  let url = `${process.env.REACT_APP_HOME_URL}/file/top_ups?user_id=${userId}&min_date=${attributes.minDate}&max_date=${attributes.maxDate}`;

  if (attributes.description)
    url = url + `&description=${attributes.description}`;

  if (attributes.sort)
    url = url + `&sort=${attributes.sort}`;

  if (attributes.category) {
    let categoryURL = "";
    attributes.category.forEach(category => categoryURL += "&category[]=" + category);
    url = url + `${categoryURL}`
  }

  return (
    <div
      style={{
        paddingLeft: "80px",
        paddingRight: "80px",
        paddingTop: "55px",
        paddingBottom: "55px",
        minHeight: "600px",
        display: "flex",
        flexDirection: "column"
      }}>
      {message ? <div style={{ color: "#992020", fontFamily: "Open Sans", fontWeight: "bold", marginBottom: "15px" }}>{message}</div> : null}
      <div className="d-flex align-items-center">
        <div>
          <b>
            <div className={classes.daily_settings_big_text}>{date === current_date ? i18n.t("TODAY_S_TOP_UPS") : ` ${i18n.t("TOP_UPS_FOR")} ${date} `}</div>
          </b>
        </div>

        <div className="position-relative">
          <div onClick={() => topUpsFilterToggle()}>
            <FourDots size={18} dots_size={7.37} />
          </div>

          {topUpsFilter ?
            <TopUpsFilter date={date}
              getDate={getDateFromCalendar}
              closeModal={closeFilterModal}
              attributes={attributes}
              current_user={props.current_user}
              categoriesSelectedList={categoriesSelectedList} />
            : null}
        </div>

        <div className="position-relative">
          <i
            className="fas fa-plus"
            style={{
              color: "rgb(211, 156, 62)",
              fontSize: "18px",
              marginLeft: "10px"
            }}

            onClick={() => newModalToggle()}>
          </i>

          {newPlus ? <New
            current_user={props.current_user}
            first_description={"Exchange"}
            second_description={"Deposit"}
            third_description={"Withdrawal"}
            fourth_description={"Payment"}
            fifth_description={"Transfer"}
            onChildClicked={handleClickedModal}
            closeModal={newModalToggle} /> :
            null}
        </div>

        <a href={userId ? url : ""} style={{ marginLeft: 15 }}>
          <i className="fas fa-share-square" style={{
            color: "rgb(211, 156, 62)",
            fontSize: "18px",
            marginRight: "10px"
          }}></i>
        </a>
      </div>

      <div style={{ display: "flex", flexDirection: "column", flex: 1, marginTop: "15px", width: "90%", marginLeft: "auto", marginRight: "auto" }}>
        <div style={{ display: "flex", alignSelf: "center" }}>
          <SearchEngine placeholder="Search by description" filter_search_filed={searchFilter} value={attributes.description ? attributes.description : null} />
          {attributes.sort === "DESC" ?
            <i className="fas fa-sort-down fa-2x" style={{ color: "#d39c3e" }} onClick={() => toggleSort("ASC")}></i> :
            <i className="fas fa-sort-up fa-2x" style={{ color: "#d39c3e", position: "relative", top: 15 }} onClick={() => toggleSort("DESC")}></i>}
        </div>
        <div style={{ display: "flex", alignSelf: "center" }}>
          {descriptionHTML} {categoryHTML}
        </div>
        <div style={{ marginTop: 20, marginBottom: "30.39px" }}>
          <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
            {newTopUpExchange || newTopUpWithdrawal || newTopUpDesposit || newTopUpPayment ?
              <TopUpComponent
                type={["top_up_Payment", "top_up_Deposit"].includes(topUpType) ? "top_up_Withdrawal" : topUpType}
                date={current_date}
                time={current_time}
                action_handeled={true}
                onChildClicked={handleClickedNew}
                modifiable={true}
                last_description={last_description}
                default_negative={["top_up_Payment", "top_up_Withdrawal"].includes(topUpType) ? true : false}
                date_of_creation={dateFormat(new Date(), i18n.t("DD_MM_YYYY"))} />
              :
              (newTopUpTransfer ?
                <TopUpTransfer
                  editable={true}
                  current_user={props.current_user}
                  close_input_window={() => setNewTopUpTransfer(false)}
                  set_error_message={(error_message) => setMessage(error_message)}
                />
              :
              null)}
            {listItems}
          </div>
        </div>
      </div>
    </div>);

};



export default TopUps;
