import i18n from 'i18next';
import React, { Component } from 'react';
import Button from '../../../components/UI/Button/Button';
import Plus from '../../../components/UI/Plus/Plus';
import classes from './servicepoint.module.css';
import global from '../Register.module.css';
import { graphql } from 'react-apollo';
import create_service_point from '../../../mutations/Registration/CreateServicePoint';


class ServicePoint extends Component {
  state = {
    dataWUIsValid: false,
    contactInformationIsValid: false,
    contactsIsValid: false,
    address: "",
    city: "",
    commercialName: "",
    country: "",
    email: [""],
    fax: [""],
    identificationNumber: "",
    phone: [""],
    postalCode: "",
    error_message: null,
    graphql_error: ""
  };


  plusHandler = type => {
    const data = this.state[type];
    data.push("");
    this.setState({
      [type]: data
    });

  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };

  handleArrays = (e, i) => {
    const data = this.state[e.target.name];
    data[i] = e.target.value;
    this.setState({
      [e.target.name]: data
    });

  };

  createServicePoint = () => {
    let { address, city, commercialName, country, email, fax, identificationNumber, phone, postalCode } = this.state;
    phone = phone.filter(phone => phone !== "");
    email = email.filter(phone => phone !== "");
    fax = fax.filter(phone => phone !== "");
    this.props.mutate({
      variables: { attributes: { address, city, commercialName, country, email, fax, identificationNumber, phone, postalCode, fiscalCode: identificationNumber }, user_id: this.props.user_id }
    }).then(res => {
      this.props.clicked();
    }).catch(error => {
      console.log(error)
      const error_message = i18n.t("PLEASE_FILL_ALL_REQUIRED_FIELD");
      this.setState({
        error_message,
        graphql_error: error.message
      });

    });
  };

  check_input_error = (name) => {
    if (this.state.graphql_error.includes(name)) {
      return [classes.inputs, global.highlighted_input].join(' ')
    }
    else {
      return classes.inputs
    }
  }


  render() {
    const emails = this.state.email.map((email, i) => {
      return (
        <input
          key={i}
          placeholder="Email"
          name="email"
          value={email}
          className={this.check_input_error("Email")}
          onChange={e => this.handleArrays(e, i)} />);


    });

    const phones = this.state.phone.map((phone, i) => {
      return (
        <input
          key={i}
          placeholder="Phone"
          name="phone"
          value={phone}
          className={this.check_input_error("Phone")}
          onChange={e => this.handleArrays(e, i)} />);


    });

    const faxes = this.state.fax.map((fax, i) => {
      return (
        <input
          key={i}
          placeholder="Fax (Optional)"
          name="fax"
          value={fax}
          className={classes.inputs}
          onChange={e => this.handleArrays(e, i)} />);


    });

    return (
      <div className={classes.container}>
        <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
        <div className={classes.firstForm}>
          <p className={classes.firstTitle}>{i18n.t("ENTER_THE_DATA_FOR_YOUR")} {this.props.type}</p>
          <div className={classes.singleInput}>
            <input
              placeholder="Commercial name"
              name="commercialName"
              value={this.state.commercialName}
              className={this.check_input_error("Commercial name")}
              onChange={this.handleInput} />

          </div>
          <div className={classes.singleInput}>
            <input
              placeholder="NUIS"
              name="identificationNumber"
              value={this.state.identificationNumber}
              className={this.check_input_error("Identification number")}
              onChange={this.handleInput} />

          </div>
        </div>

        <div className={classes.secondForm} style={{ marginBottom: "100px" }}>
          <p className={classes.firstTitle}>{i18n.t("CONTACT_INFORMATION_FOR_YOUR")} {this.props.type}</p>

          <div className={classes.singleInput}>
            <input
              placeholder="Street address"
              name="address"
              value={this.state.address}
              className={this.check_input_error("Address")}
              onChange={this.handleInput} />

          </div>

          <div className={classes.doubleInput}>
            <div style={{ width: "31%" }}>
              <input
                placeholder="Postal code"
                name="postalCode"
                value={this.state.postalCode}
                className={this.check_input_error("Postal code")}
                onChange={this.handleInput} />

            </div>

            <div style={{ width: "31%" }}>
              <input
                placeholder="City"
                name="city"
                value={this.state.city}
                className={this.check_input_error("City")}
                onChange={this.handleInput} />

            </div>
            <div style={{ width: "31%" }}>
              <input
                placeholder="Country"
                name="country"
                value={this.state.country}
                className={this.check_input_error("Country")}
                onChange={this.handleInput} />

            </div>
          </div>

          <div className={classes.doubleInput}>
            <div style={{ width: "100%" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                <Plus clicked={() => this.plusHandler("email")} />
              </div>
              {emails}
            </div>


          </div>

          <div style={{ width: "85%", display: "flex" }}>
            <div style={{ width: "50%", display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
              <Plus clicked={() => this.plusHandler("phone")} />
            </div>
            <div style={{ width: "50%", display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
              <Plus clicked={() => this.plusHandler("fax")} />
            </div>
          </div>
          <div className={classes.doubleInput}>

            <div style={{ width: "48%" }}>
              {phones}
            </div>

            <div style={{ width: "48%" }}>
              {faxes}
            </div>

          </div>

        </div>
        <div className={classes.ButtonContainer}>
          <Button clicked={() => this.createServicePoint()}>{i18n.t("NEXT")}</Button>
        </div>
      </div>);



  }
}


export default graphql(create_service_point)(ServicePoint);
