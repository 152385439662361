import i18n from 'i18next';
import React, { Component } from 'react';
import Button from '../../../components/UI/Button/Button';
import classes from './MyInfo.module.css';
import { graphql } from 'react-apollo';
import update_user from '../../../mutations/Registration/UpdateUser';

class MyInfo extends Component {

  state = {
    firstName: "",
    lastName: "",
    companyName: "",
    companyIdentificationNumber: "",
    companyFiscalCode: "",
    error_message: null
  };


  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };

  updateUser = () => {
    const { firstName, lastName, companyName, companyIdentificationNumber, companyFiscalCode } = this.state;
    this.props.mutate({
      variables: { attributes: { firstName, lastName, companyName, companyIdentificationNumber, companyFiscalCode }, user_id: this.props.user_id }
    }).then(res => {
      this.props.clicked();
    }).catch(error => {

      const error_message = i18n.t("PLEASE_FILL_ALL_REQUIRED_FIELD");
      this.setState({
        error_message
      });

    });
  };


  render() {
    return (
      <div className={classes.container}>
        <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
        <div className={classes.firstForm}>
          <p className={classes.firstTitle}>{i18n.t("LET_US_KNOW_WHO_YOU_ARE")}</p>
          <div className={classes.doubleInput}>
            <div style={{ width: "48%" }}>
              <input
                style={this.state.error_message && this.state.firstName === "" ? { border: "1px solid #ff7070" } : null}
                placeholder="Name"
                name="firstName"
                value={this.state.firstName}
                className={classes.inputs}
                onChange={this.handleInput}
                autoComplete="new-password" />

            </div>

            <div style={{ width: "48%" }}>
              <input
                style={this.state.error_message && this.state.lastName === "" ? { border: "1px solid #ff7070" } : null}
                placeholder="Surname"
                name="lastName"
                value={this.state.lastName}
                className={classes.inputs}
                onChange={this.handleInput}
                autoComplete="new-password" />

            </div>
          </div>
          <div className={classes.singleInput}>
            <input
              placeholder="Email address"
              name="email"
              value={this.props.email || ""}
              className={classes.inputs}
              readOnly={true}
              autoComplete="new-password" />

          </div>
        </div>

        <div className={classes.secondForm}>
          <p className={classes.firstTitle}>{i18n.t("DO_YOU_REPRESENT_A_COMPANY")}</p>

          <div className={classes.singleInput}>
            <input
              placeholder="Company name"
              name="companyName"
              value={this.state.companyName}
              className={classes.inputs}
              onChange={this.handleInput} />

          </div>
          <div className={classes.doubleInput}>
            <div style={{ width: "48%" }}>
              <input
                placeholder="Identification number"
                name="companyIdentificationNumber"
                value={this.state.companyIdentificationNumber}
                className={classes.inputs}
                onChange={this.handleInput} />

            </div>

            <div style={{ width: "48%" }}>
              <input
                placeholder="Fiscal code"
                name="companyFiscalCode"
                value={this.state.fiscalCode}
                className={classes.inputs}
                onChange={this.handleInput} />

            </div>
          </div>
        </div>
        <div className={classes.ButtonContainer}>
          <Button clicked={this.updateUser}>{i18n.t("NEXT")}</Button>
        </div>
      </div>);


  }
}


export default graphql(update_user)(MyInfo);