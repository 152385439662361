import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../BuySell.module.scss';
import Button from '../../../../../components/UI/Button/Button';
import ToolbarLogout from '../../../../../components/Toolbar/ToolbarLogout';
import NumberFormat from 'react-number-format';
import best_offer from '../../../../../queries/CurrencyTrader/BestOffer';
import { graphql } from 'react-apollo';
import flexible_order_purchase from '../../../../../mutations/CurrencyTrader/CreateFlexiblePurchaseOrder';
import flexible_order_sale from '../../../../../mutations/CurrencyTrader/CreateFlexibleSaleOrder';
import get_currency_trader_orders from '../../../../../queries/CurrencyTrader/GetOrders';
import compose from 'lodash/fp/compose';


class BestOffer extends Component {
  constructor(props) {
    super(props);
    const arrived_data = this.props.location.state
    this.state = {
      market_rate: arrived_data.market_rate,
      total_previously_desired: arrived_data.total_previously_desired || arrived_data.desired_amount,
      desired_amount: arrived_data.desired_amount,
      currency: arrived_data.currency,
      currencySymbol: arrived_data.currencySymbol,
      best_offer_list: arrived_data.best_offer_list,
      average_rate: null,
      total_value: null,
      loading: true,
      selection_finished: false,
      single_selected: false,
      single_offer: false,
      remaining_amount: 0,
      order_type: arrived_data.order_type,
      currencyDescription: arrived_data.currencyDescription,
      second_currency: arrived_data.second_currency,
      secondCurrencySymbol: arrived_data.secondCurrencySymbol,
      error_message: null,
      button_active: true
    }
  }


  componentDidMount = () => {
    const arrived_data = this.props.location.state
    if (arrived_data.page === "partial_acceptance") {
      this.get_best_offer(arrived_data.accepted_options, arrived_data.total_previously_desired)
      this.setState({
        selection_finished: true,
        total_previously_desired: arrived_data.total_previously_desired
      })

      if (arrived_data.accepted_options.length === 1) {
        this.setState({
          single_selected: true
        })
      }
    }
    else {
      if (arrived_data.best_offer_list.length === 1) {
        this.setState({
          single_offer: true
        })
      }
      this.get_best_offer(arrived_data.best_offer_list, this.state.desired_amount)
    }
  }

  get_best_offer = (best_offer_list, total_desired_amount) => {
    let total_value = 0
    let desired_after_partial = 0

    best_offer_list.forEach(offer => {
      total_value = total_value + offer.rate * offer.amount_offered
      desired_after_partial = desired_after_partial + offer.amount_offered
    })
    const average_rate = total_value / desired_after_partial
    let remaining_amount = total_desired_amount - desired_after_partial

    this.setState({
      best_offer_list,
      average_rate: average_rate.toFixed(4),
      total_value: total_value.toFixed(2),
      remaining_amount,
      desired_amount: desired_after_partial,
      loading: false
    })
  }

  partialAcceptance = () => {
    this.props.history.push({
      pathname: "/partialacceptance",
      state: this.state
    });
  }

  confirmOrder = (type) => {
    this.setState({
      button_active: false
    })
    if (this.state.order_type === "Purchase") {
      this.onCreatePurchaseOrder(type)
    }
    else {
      this.onCreateSaleOrder(type)
    }
  }

  onCreatePurchaseOrder = (type) => {
    this.props.purchase_mutation({
      variables: {
        attributes: {
          amountPurchasedCents: this.state.desired_amount.toString(),
          amountPurchasedCurrency: this.state.currency,
          amountPaidCents: this.state.total_value.toString(),
          amountPaidCurrency: this.state.second_currency,
          status: "completed",
          datetime: new Date()
        },
        offer_list: this.state.best_offer_list
      },
      refetchQueries: [{ query: get_currency_trader_orders }]
    }).then(res => {
      this.props.history.push({
        pathname: "/completedorder",
        state: { ...this.state, type, page: "best_offer" }
      });
    }).catch(error => {
      console.log(error)
      this.setState({
        button_active: true
      })
      if (error.message === "GraphQL error: Not enough limit remaining") {
        this.setState({
          error_message: i18n.t("THE_REQUESTED_AMOUNT_EXCEEDS_Y")
        })
      }
      else if (error.message === "GraphQL error: Currency Trader closed") {
        this.setState({
          error_message: i18n.t("CURRENCY_TRADER_CLOSED")
        })
      }
      else if (error.message === "GraphQL error: grater than available" || error.message === "GraphQL error: Order not found") {
        this.setState({
          error_message: i18n.t("BEST_OFFER_CHANGED")
        })
      }
    })
  }

  onCreateSaleOrder = (type) => {
    this.props.sell_mutation({
      variables: {
        attributes: {
          amountSoldCents: this.state.desired_amount.toString(),
          amountSoldCurrency: this.state.currency,
          amountPaidCents: this.state.total_value.toString(),
          amountPaidCurrency: this.state.second_currency,
          status: "completed",
          datetime: new Date()
        },
        offer_list: this.state.best_offer_list
      },
      refetchQueries: [{ query: get_currency_trader_orders }]
    }).then(res => {
      this.props.history.push({
        pathname: "/completedorder",
        state: { ...this.state, type, page: "best_offer" }
      });
    }).catch(error => {
      console.log(error)
      this.setState({
        button_active: true
      })
      if (error.message === "GraphQL error: Not enough limit remaining") {
        this.setState({
          error_message: i18n.t("THE_REQUESTED_AMOUNT_EXCEEDS_Y")
        })
      }
      else if (error.message === "GraphQL error: Currency Trader closed") {
        this.setState({
          error_message: i18n.t("CURRENCY_TRADER_CLOSED")
        })
      }
      else if (error.message === "GraphQL error: grater than available" || error.message === "GraphQL error: Order not found") {
        this.setState({
          error_message: i18n.t("BEST_OFFER_CHANGED")
        })
      }
    })
  }

  onCancelPartialAcceptance = () => {
    this.get_best_offer(this.props.location.state.best_offer_list, this.props.location.state.desired_amount)
    this.setState({
      selection_finished: false,
      single_selected: false
    })
  }

  onModifyOffer = () => {

    if (this.state.error_message) {
      this.props.history.push({
        pathname: "/dashboard",
      });
    }
    else {
      this.props.history.push({
        pathname: "/modifyoffer",
        state: this.state
      });
    }
  }



  render() {
    let best_offers = null
    if (!this.state.loading) {
      best_offers = this.state.best_offer_list.map(offer => {
        return <div className={classes.boldText} key={offer.order_id}>
          <NumberFormat style={{ marginLeft: "10px" }} displayType={"text"} thousandSeparator={true} prefix={this.state.currencySymbol} value={offer.amount_offered} /> @ {offer.rate.toFixed(2)}
        </div>
      })
    }

    return (
      <div>
        <ToolbarLogout />
        <div className={classes.ComponentBestOffer}>
          <div className={classes.Component}>
            <div className={classes.Head}>
              <div className={classes.TransactionText}>{i18n.t("OUR_BEST_OFFER")}</div>
            </div>
            <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
            <div className={classes.container}>
              <div className={classes.currenciesHeader} style={{ background: this.state.order_type === "Purchase" ? "#285A64" : "#BFD2C7" }}> </div>
              <div className={[classes.currencies, classes[this.state.order_type]].join(' ')}>
                <div className={classes.currencies_content} >
                  <div className={classes.someText} style={{ marginBottom: "20px" }}>{i18n.t("YOUR_TOTAL_WILL_BE")}</div>
                  <div className={classes.boldText}>
                    <NumberFormat
                      name={"total"}
                      displayType={"text"}
                      value={this.state.total_value}
                      thousandSeparator={true}
                      suffix={this.state.second_currency === "ALL" ? "L" : null}
                      prefix={this.state.second_currency === "ALL" ? null : this.state.secondCurrencySymbol}
                    />

                  </div>
                  <div style={{ marginTop: "10px" }}>{i18n.t("FOR")}
                    <span> <NumberFormat
                      name={"total"}
                      displayType={"text"}
                      value={this.state.desired_amount}
                      thousandSeparator={true}
                      prefix={this.state.currencySymbol}
                    />
                    </span>
                  </div>
                </div>
              </div>

              <div className={[classes.currencies, classes[this.state.order_type]].join(' ')}>
                <div className={classes.currencies_content} >
                  <div className={classes.someText} style={{ marginBottom: "20px" }}>{this.state.single_offer || this.state.single_selected ? i18n.t("THE_EXCHANGE_RATE_IS") : i18n.t("THE_WEIGHTED_AVERAGE_IS")}</div>
                  <div className={classes.boldText}>
                    {this.state.currencySymbol}
                                      1 = {this.state.second_currency !== "ALL" ? this.state.secondCurrencySymbol : null}
                    {this.state.average_rate}
                    {this.state.second_currency !== "ALL" ? null : "L"}
                  </div>
                  <div style={{ marginTop: "10px" }}>{i18n.t("THE_CURRENT_MARKET_RATE_IS")} <span style={{ color: "#659278" }}> {this.state.market_rate} L </span> </div>
                </div>
              </div>

              {this.state.single_offer || this.state.single_selected ?
                null :

                <div className={[classes.currencies, classes[this.state.order_type]].join(' ')}>
                  <div className={classes.currencies_content}>
                    <div className={classes.someText} style={{ marginBottom: "20px" }}>{i18n.t("THE_EXCHANGE_RATES_ARE")}</div>
                    {best_offers}
                  </div>
                </div>}


              <div className={[classes.currencies, classes[this.state.order_type]].join(' ')} style={{ height: "80px" }}>
                {this.state.selection_finished ?
                  <div className={classes.someText}>{i18n.t("PLEASE_CONFIRM_AND_SUBMIT_YOUR")}</div> :
                  this.state.single_offer ?
                    <div className={classes.someText}>{i18n.t("WOULD_YOU_LIKE_TO_ACCEPT_THIS")}</div> :

                    <div className={classes.someText}>{i18n.t("WOULD_YOU_LIKE_TO_ACCEPT_ALL_O")}</div>}

              </div>
            </div>

            {
              this.state.selection_finished ?
                <div className={classes.buttons_center}>
                  <Button extraStyle={classes[!this.state.button_active ? "button_disabled" : null]} disabled={!this.state.button_active} clicked={() => this.confirmOrder("partial")}> {i18n.t("CONFIRM")} </Button>
                  <Button extraStyle={classes.cancelButton} clicked={this.onCancelPartialAcceptance}> {i18n.t("CANCEL")} </Button>
                </div>
                : this.state.single_offer ?
                  <div className={classes.buttons_center}>
                    <Button extraStyle={classes[!this.state.button_active ? "button_disabled" : null]} disabled={!this.state.button_active} clicked={() => this.confirmOrder("single")}> {i18n.t("YES1")} </Button>
                    <Button extraStyle={classes.cancelButton} clicked={this.onModifyOffer}> {this.state.error_message ? i18n.t("CANCEL") : i18n.t("NO")} </Button>
                  </div>
                  :
                  <div className={classes.buttons_center}>
                    <Button extraStyle={classes[!this.state.button_active ? "button_disabled" : null]} disabled={!this.state.button_active} clicked={() => this.confirmOrder("all")}> {i18n.t("ALL")}</Button>
                    <Button extraStyle={classes.cancelButton} clicked={this.partialAcceptance}> {i18n.t("PART")} </Button>
                    <Button extraStyle={classes.cancelButton} clicked={this.onModifyOffer}> {this.state.error_message ? i18n.t("CANCEL") : i18n.t("NONE")} </Button>
                  </div>

            }



          </div>
        </div>
      </div>
    );
  }
}




export default graphql(best_offer)(compose(
  graphql(flexible_order_purchase, { name: 'purchase_mutation' }),
  graphql(flexible_order_sale, { name: 'sell_mutation' })
)(BestOffer));
