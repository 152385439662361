import React from 'react';

const FilteredComponent = props => {

  return (
    <div style={{ display: "flex", fontSize: "14px", fontWeight: "bold", fontFamily: "Open Sans", marginRight: "20px", marginTop: "10px" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <i
          className="fas fa-times-circle"
          style={{
            color: "rgb(211, 156, 62)",
            fontSize: "18px",
            marginRight: "5px",
            cursor: "pointer"
          }}

          onClick={() => props.onRemoveFilter(props.property.replace(/ /g, "_"), props.value)}></i>
        <div>{props.property.replace(props.property.charAt(0), props.property.charAt(0).toUpperCase())}: {props.value}</div>
      </div>
    </div>);

};

export default FilteredComponent;