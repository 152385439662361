import i18n from 'i18next'; 
import React, { Component } from 'react';

import classes from './Privacy.module.css';

import Exit from '../UI/Cros/Cros';

class Privacy extends Component {

  render() {
    let privacy;
    let string = <div className={classes.singleInput}>
      <p className={classes.privacyStatements}> {i18n.t("LOREM_IPSUM_DOLOR_SIT_AMET_CO")}</p>
      <p className={classes.privacyStatements}> {i18n.t("FAUCIBUS_PULVINAR_ELEMENTUM_IN")} </p>
      <p className={classes.privacyStatements}> {i18n.t("ODIO_UT_ENIM_BLANDIT_VOLUTPAT")}</p>
      <p className={classes.privacyStatements}> {i18n.t("SEM_NULLA_PHARETRA_DIAM_SIT_AM")} </p>
    </div>;

    if (this.props.show) {
      privacy =
        <div className={classes.Component}>
          <div className={classes.Head}> <div className={classes.Exit}> <Exit clicked={this.props.clicked} /> </div></div>

          <div className={classes.container}>
            <div className={classes.firstForm}>
              <p className={classes.firstTitle}> {i18n.t("PRIVACY_STATEMENT")} </p>

              {string}
            </div>
          </div>
        </div>;

    } else {
      privacy = null;
    }

    return (
      <div className={classes.Privacy}>
        {privacy}
      </div>);

  }
}


export default Privacy;