import i18n from 'i18next';
import React, { useState } from 'react';
import classes2 from '../../../CurrencyTrader/DossierModal/BuySell.module.scss';
import Button from '../../../../../components/UI/Button/Button';
import PopupInputComponent from './PopupInputComponent';
import DailySettingsRate from '../../DailySettings/DailySettingsRate';
import get_service_point_shifts_query from '../../../../../queries/CashManager/ServicePointShifts';
import create_service_point_shift_mutation from '../../../../../mutations/CashManager/CreateServicePointShift';
import not_updated_service_point_shifts from '../../../../../queries/CashManager/NotUpdatedServicePointShift';
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from '@apollo/react-hooks';


const PopupPeriod = props => {
  const { loading, error, data, refetch } = useQuery(get_service_point_shifts_query);

  const [goCreateServicePointShiftMutation] = useMutation(create_service_point_shift_mutation);
  const { refetch: refetch_not_updated } = useQuery(not_updated_service_point_shifts);

  const [dropdown_value, set_dropdown_value] = useState(null);
  const [amount_value, set_amount_value] = useState(null);
  const [rate_value, set_rate_value] = useState(null);
  const [done] = useState(false);

  var dateFormat = require('dateformat');

  var currentdate = new Date();
  const current_date = ("0" + currentdate.getDate()) + "/" + ("0" + (currentdate.getMonth() + 1)).slice(-2) + "/" + currentdate.getFullYear();
  const current_time = " " + currentdate.getHours() + ":" + currentdate.getMinutes();

  refetch();
  refetch_not_updated();

  const dropdown_func = (value, id) => {
    set_dropdown_value(value);
  };

  const handleClickedNewShift = () => {
    goCreateServicePointShiftMutation({
      variables: {
        attributes: {
          shiftNumber: 1,
          amountOpenedCurrency: dropdown_value,
          amountOpenedCents: amount_value,
          averageOpenedRate: parseFloat(rate_value)
        },
        mismatch_ignored: true
      }
    });



  };

  const handleClickedNewShiftPencil = type => {
    goCreateServicePointShiftMutation({
      variables: {
        attributes: {
          id: type.id,
          shiftNumber: 1,
          amountOpenedCurrency: type.first_element,
          amountOpenedCents: type.second_element,
          averageOpenedRate: parseFloat(type.third_element)
        },
        mismatch_ignored: true
      }
    });



  };

  if (loading) return null;
  if (error) return `Error! ${error}`;
  var start_session_listItems = null;
  if (data.servicePointShifts) {
    start_session_listItems = data.servicePointShifts.map(record => {
      if (dateFormat(record.timeOpened, "dd/mm/yyyy") === dateFormat(currentdate, "dd/mm/yyyy")) {
        return (
          <div key={record.id.toString() + "open"} style={{ width: "90%", margin: "auto", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", marginBottom: "10px" }}>
            <DailySettingsRate
              period_id={record.id}
              type={"period"}
              period_type={"open"}
              currency={record.amountOpenedCurrency}
              amount={record.amountOpenedCents}
              action={"open"}
              date={dateFormat(record.timeOpened, "dd/mm/yyyy")}
              time={dateFormat(record.timeOpened, "HH:MM")}
              ave_rate={record.averageOpenedRate}
              action_handeled={true}
              input={props.input}
              date_of_creation={dateFormat(record.timeOpened, "dd/mm/yyyy")}
              flex={2}
              marginLeft={"10px"} />

          </div>);

      }
      return null;
    });
  }

  return (
    <div style={{ flex: 1, marginTop: "25px" }}>
      <div style={{ width: "80%", height: "20%", margin: "auto", display: "flex", justifyContent: "space-between" }}>
        <PopupInputComponent type="dropdown" description={"Currency"} handleInput={(value, id) => dropdown_func(value, id)} value={done} />
        <PopupInputComponent type="input" description={"Amount"} handleInput={value => set_amount_value(value)} value={done} />
        <PopupInputComponent type="input" description={"Ave. Rate"} handleInput={value => set_rate_value(value)} value={done} />
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px", fontWeight: "bold", marginTop: "10px" }}> {i18n.t("WOULD_YOU_LIKE_TO_USE_INDICATI")} <span style={{ color: "#D39C3E", marginLeft: "3px" }}>{i18n.t("CLICK_HERE1")}</span></div>
      <div style={{ display: "flex", width: "80%", margin: "auto", justifyContent: "flex-end", marginTop: "20px", marginBottom: "15px" }}>
        <div><Button clicked={handleClickedNewShift}>{i18n.t("SAVE")}</Button></div>
        <div><Button extraStyle={classes2.cancelButton}>{i18n.t("CANCEL")}</Button></div>
      </div>
      {start_session_listItems}
      <div style={{ width: "80%", height: "20%", margin: "auto", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", color: "red", fontWeight: "bold", fontSize: "12px" }}>{props.message ? i18n.t("YOU_MUST_ADD_AN_OPENING_VALUE") : null}</div>
    </div>);

};


export default PopupPeriod;
