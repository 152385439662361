import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../../CurrencyTrader.module.scss';
import currenciesObject from "../../../../../data/currencies";
import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';

class OrderInitialization extends Component {

  currencies = currenciesObject[0];

  constructor(props) {
    super(props);
    this.checkboxes = [];
    this.state = {
      active_option: 0,
      checkboxes: this.props.available_currencies,
      currency: null,
      second_currency: null
    };

  }

  onCheckCurrency = e => {
    e.persist();
    let checkboxes = this.checkboxes;
    checkboxes.forEach(item => {
      if (item.value === e.target.value) {
        item.isChecked = e.target.checked;
      } else {
        item.isChecked = false;
      }
    });

    this.setState({ checkboxes: checkboxes });
    this.props.send({ currency: e.target.value });
  };

  onSelectDropdown1 = (selectedValue, id) => {
    this.props.send({ currency: id, type: "currency" });
  };

  onSelectDropdown2 = (selectedValue, id) => {
    this.props.send({ currency: id, type: "second_currency" });
  };


  render() {

    const order_type = this.props.order_type;

    if (this.checkboxes.length === 0) {
      this.checkboxes = [
        ...this.state.checkboxes];

    }
    let key = 0;
    const new_registration_checkboxes = this.checkboxes.map(checkbox => {
      return (
        <div className={classes.radioBlock} key={key++}>
          <input type="radio" name={checkbox.value} id="radio-one" className={classes.formRadio}
            value={checkbox.value}
            description={checkbox.value}
            checked={checkbox.isChecked}
            onChange={this.onCheckCurrency} />


          <div className={classes.currencyContainer}>
            <p className={classes.bigText}>{checkbox.value}</p>
            <p className={classes.smallText}>{this.currencies[checkbox.value].area}</p>
          </div>

        </div>);

    });

    const dropdown_currencies = this.props.available_currencies.map(currency => {
      return { value: currency.description, id: currency.value };
    });

    const crossCurrency =
      <div>
        <div className={classes.currenciesDropdown} style={{ background: order_type === "Purchase" ? "#DAE2E4" : "#F3F7F5" }}>
          <div style={{ fontWeight: "bold" }}>{i18n.t("CURRENCY_YOU_WOULD_LIKE_TO")} {this.props.order_type === "Purchase" ? i18n.t("BUY") : i18n.t("SELL")}</div>
          <div style={{ width: "280px", marginTop: "4px" }}>
            <Dropdown key="firstDropdown" items={dropdown_currencies} onSelectDropdown={this.onSelectDropdown1} />
          </div>
        </div>
        <div className={classes.currenciesDropdown} style={{ background: order_type === "Purchase" ? "#DAE2E4" : "#f3f7f5" }}>
          <div style={{ fontWeight: "bold" }}>{this.props.order_type === "Purchase" ? i18n.t("CURRENCY_YOU_ARE_USING_TO_PAY") : i18n.t("CURRENCY_YOU_WOULD_LIKE_TO_REC")}</div>
          <div style={{ width: "280px", marginTop: "4px" }}>
            <Dropdown key="secondDropdown" items={dropdown_currencies} onSelectDropdown={this.onSelectDropdown2} />
          </div>
        </div>
      </div>;


    return (
      <div>
        <div className={classes.checkoutHeader} style={{ background: order_type === "Purchase" ? "#285A64" : "#BFD2C7" }}> </div>

        {
          this.props.cross_currency ?
            <div>
              {crossCurrency}
            </div> :

            <div className={classes.checkoutCurrencies} style={{ background: order_type === "Purchase" ? "#DAE2E4" : "#f3f7f5" }}>
              <div className={classes.radioBtns}>
                {new_registration_checkboxes}
              </div>
            </div>}


        {this.props.show_message ? <p style={{ color: "#992020", textAlign: "center" }}>{i18n.t("YOU_NEED_TO_SELECT_ONE_CURRENC")}</p> : ""}
      </div>);

  }
}




export default OrderInitialization;
