import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../../CurrencyTrader/CurrencyTrader.module.scss';
import Button from '../../../../components/UI/Button/Button';
import Bought from './Bought/Bought';
import Sold from './Sold/Sold';
import { withRouter } from "react-router";
import coin from "../../../../assets/icons/coin.png";

class CheckoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_option: 0,
      typeOfTransaction: 'purchase', //or sale
      currencyYouGet: 'EUR',
      currencyYouGive: 'ALL',
      otherCurrencies: false,
      show_message: false
    };



    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }


  showSubComponent = () => {

    switch (this.state.active_option) {
      case 0:
        return <Bought send={this.sendData.bind(this)} available_currencies={this.props.currencies} otherCurrencies={this.state.otherCurrencies} show_message={this.state.show_message} />;
      case 1:
        return <Sold send={this.sendData.bind(this)} available_currencies={this.props.currencies} otherCurrencies={this.state.otherCurrencies} show_message={this.state.show_message} />;
      default:
        return null
    }

  };

  childEvent(event) {
    event.stopPropagation();
    if (this.props.childEvent) {
      this.props.childEvent();
    }
  }

  sendData = (obj) => {
    this.setState(obj);
  }

  handleNext = path => {
    if (this.state.currencyYouGet == null) {
      this.setState({
        show_message: true
      });

    } else {
      this.props.history.push({
        pathname: path,
        state: { current_user: this.props.current_user, currencyYouGet: this.state.currencyYouGet, currencyYouGive: this.state.currencyYouGive, cross_currency_active: !this.state.otherCurrencies }
      });

    }
  };

  toggleOtherCurrencies() {
    this.setState({
      otherCurrencies: !this.state.otherCurrencies
    });

  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
     * Set the wrapper ref
     */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
    * close modal if clicked on outside of element
    */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.id !== 'checkoutModalIconCM') {
      this.props.closeCheckoutModal();
    }
  }



  render() {
    var redirectTo = null;
    if (this.state.active_option === 0) {
      redirectTo = <Button clicked={() => this.handleNext("/boughtregistration")}> {i18n.t("NEXT")} </Button>
    }
    else {
      redirectTo = <Button clicked={() => this.handleNext("/soldregistration")}> {i18n.t("NEXT")} </Button>
    }

    return (
      <div ref={this.setWrapperRef} className={[classes.modal_item, classes.checkoutModal].join(' ')} onClick={this.childEvent.bind(this)} >
        <div className={classes.modal_header}>
          <div className={classes.dossierHeader}>{i18n.t("NEW_REGISTRATION")}</div>
          <div onClick={this.toggleOtherCurrencies.bind(this)}>
            <img className={classes.dossierModalHeaderIcon} src={coin} alt="coin" style={(this.state.otherCurrencies ? { opacity: "30%" } : { opacity: "100%" })} />
          </div>
        </div>

        <div className={[classes.modal_body, classes.dossierBody].join(' ')} >
          <div className={classes.options}>
            <p className={classes.someText}>{i18n.t("I_AM")}</p>
            <div className="d-flex">
              <p className={classes.optionText} style={this.state.active_option === 0 ? { opacity: 1 } : { opacity: 0.28 }} onClick={() => { this.setState({ active_option: 0 }) }}>{i18n.t("BUY3")}</p>
              <div style={{ height: "25px", border: "1px solid #D39C3E", opacity: "0.28", margin: "0 5px" }}></div>
              <p className={classes.optionText} style={this.state.active_option === 1 ? { opacity: 1 } : { opacity: 0.28 }} onClick={() => { this.setState({ active_option: 1 }) }}>{i18n.t("SELL3")}</p>

            </div>

          </div>

          {this.showSubComponent()}

          <div style={{ display: "flex", justifyContent: "flex-end", margin: "15px 0 0" }}>
            {redirectTo}
            <Button extraStyle={classes.cancelButton} clicked={() => { this.props.modal_open(); }}> {i18n.t("CANCEL")} </Button>
          </div>
        </div>
      </div>);

  }
}




export default withRouter(CheckoutModal);
