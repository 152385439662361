import i18n from "i18next";
import React, { Component } from "react";
import classes from "./TopUpComponent.module.scss";
import NumberFormat from "react-number-format";

var dateFormat = require("dateformat");

class TopUpComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifiable:
        this.props.modifiable || this.props.confirmation_modify ? true : false,
      pencil_opacity:
        this.props.requested_without_closing && this.props.amount == null
          ? true
          : false,
      currency: this.props.currency,
      first_element_value:
        this.props.type === "top_up_Exchange" ||
        this.props.type === "top_up_Withdrawal"
          ? this.props.amount
          : null,
      second_element_value:
        this.props.type === "top_up_Exchange" ||
        this.props.type === "top_up_Withdrawal"
          ? this.props.rate
          : null,
      third_element_value:
        this.props.type === "top_up_Exchange" ? this.props.paid_in : null,
      description: this.props.last_description || this.props.description || "",
    };
  }

  onModifyValues = () => {
    this.setState({
      modifiable: true,
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleClickedPencil();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.modifiable !== this.props.modifiable) {
      this.setState({
        modifiable: this.props.modifiable,
        pencil_opacity: this.props.modifiable,
      });
    } else if (
      prevProps.confirmation_modify !== this.props.confirmation_modify
    ) {
      this.setState({
        modifiable: this.props.confirmation_modify,
        pencil_opacity: this.props.confirmation_modify,
      });
    }
  }

  amountChoosenInput = (value) => {
    this.setState({ first_element_value: value.value });
  };

  render() {
    const color = this.props.type === "top-up" ? "#285A64" : "#649177";
    const input = (
      <input
        type="text"
        onKeyDown={this.handleKeyDown}
        name="name"
        onChange={(e) => {
          this.setState({ currency: e.target.value });
        }}
        value={this.state.currency || ""}
        style={{
          border: "none",
          width: "40px",
          borderBottom: "1px solid #CCCCCC",
          outline: "none",
        }}
      />
    );
    const first_input = [
      "top_up_Exchange",
      "top_up_Withdrawal",
      "top_up_Payment",
    ].includes(this.props.type) ? (
      <NumberFormat
        className={classes.nr_format_input}
        thousandSeparator={true}
        prefix={
          !this.state.first_element_value && this.props.default_negative
            ? "-"
            : ""
        }
        value={this.state.first_element_value}
        onValueChange={(value) => this.amountChoosenInput(value)}
      />
    ) : (
      <input
        type="text"
        name="name"
        onKeyDown={this.handleKeyDown}
        onChange={(e) => {
          this.setState({ first_element_value: e.target.value });
        }}
        value={this.state.first_element_value || ""}
        className={classes.nr_format_input}
      />
    );

    const second_input = (
      <input
        type="number"
        name="name"
        onKeyDown={this.handleKeyDown}
        onChange={(e) => {
          this.setState({ second_element_value: Math.abs(e.target.value) });
        }}
        value={this.state.second_element_value || ""}
        className={classes.nr_format_input}
      />
    );
    const third_input = (
      <input
        type="text"
        name="name"
        onKeyDown={this.handleKeyDown}
        onChange={(e) => {
          this.setState({ third_element_value: e.target.value });
        }}
        value={this.state.third_element_value || ""}
        className={classes.nr_format_input}
      />
    );

    const flex_value =
      this.props.type === "top_up_Exchange" ||
      this.props.type === "top_up_Withdrawal"
        ? 5
        : 2;
    const border_color =
      this.props.requested_without_closing && this.props.amount === null
        ? "red"
        : "#CCCC";

    var first_description = null;
    var second_description = null;
    var third_description = null;
    var first_description_value = null;
    var second_description_value = null;
    var third_description_value = null;
    var description = null;
    var text = null;

    if (this.props.type === "top_up_Exchange") {
      first_description = i18n.t("AMOUNT");
      second_description = i18n.t("RATE");
      third_description = i18n.t("PAID_IN");
      first_description_value = this.props.amount;
      second_description_value = this.props.rate;
      third_description_value = this.props.paid_in;
      description = null;
      text = null;
    } else if (this.props.type === "top_up_Withdrawal" || "top_up_Payment") {
      first_description_value = this.props.amount;
      second_description_value = this.props.rate;
      first_description = i18n.t("AMOUNT");
      second_description = i18n.t("RATE");
      third_description = null;
      third_description_value = null;
      description = i18n.t("DESCRIPTION");
      text = null;
    }

    return (
      <div style={{display: "flex", flexDirection: "row", flex: 1, marginTop: "5px", alignItems: "center"}}>
        <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
            <div style={{ flex: 1 }}>
              <span className={classes.daily_settings_card_small_text} style={{ color: color, marginRight: "5px" }}>
                {!this.props.currency ? input : this.props.currency}
              </span>
            </div>
          </div>

          {this.props.type !== "exchange-rate" && this.props.action_handeled ? (
            <div style={{marginRight: "5.13px",flex: 1,display: "flex",justifyContent: "flex-end",flexDirection: "column",fontWeight: "bold"}}>
              <div
                style={{fontSize: "9px",display: "flex",justifyContent: "flex-end"}}>
                {this.props.confirmation ? (
                  <div
                    onClick={() => this.props.onConfirmMismatch()}
                    style={{fontSize: "16px",color: "rgb(211, 156, 62)",cursor: "pointer"}}>
                    {i18n.t("OK")}
                  </div>
                ) : (
                  (this.props.action || "") + " " + (this.props.date || "")
                )}
              </div>
              <div style={{fontSize: "9px",display: "flex",justifyContent: "flex-end"}}>
                {this.props.time}
              </div>
            </div>
          ) : null}
        </div>

        <div style={{ display: "flex", flex: flex_value, position:"relative", alignItems:"center"}}>
          <div style={{width: "-webkit-fill-available",border: `1px solid ${border_color}`,width:"100%", position:"relative"}}>
            <div style={{display: "flex",flex: 2,flexDirection: "row",height: "35px",marginLeft: "3px",}}>
              <div style={{display: "flex",flex: 1,alignItems: "center",marginLeft: this.props.marginLeft}}>
                <div style={{ display: "flex" }}>
                  <span className={classes.daily_settings_extra_small_text}>
                    {first_description}
                  </span>
                </div>

                <div style={{ display: "flex", flex: this.props.flex }}>
                  <span className={classes.daily_settings_card_small_text}>
                    {this.props.input || this.state.modifiable ? (
                      first_input
                    ) : (
                      <NumberFormat
                        prefix={
                          this.props.currency_symbol === "L"
                            ? null
                            : this.props.currency_symbol
                        }
                        suffix={
                          this.props.currency_symbol === "L"
                            ? this.props.currency_symbol
                            : null
                        }
                        thousandSeparator={true}
                        displayType={"text"}
                        value={first_description_value}
                      />
                    )}
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <span className={classes.daily_settings_extra_small_text}>
                    {second_description}
                  </span>
                </div>
                <div>
                  <span className={classes.daily_settings_card_small_text}>
                    {this.props.input || this.state.modifiable
                      ? second_input
                      : second_description_value}
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                <div>
                  <span className={classes.daily_settings_extra_small_text}>
                    {third_description}
                  </span>
                </div>
                <div>
                  <span className={classes.daily_settings_card_small_text}>
                    {(this.props.input || this.state.modifiable) &&
                    this.props.type == "top_up_Exchange"
                      ? third_input
                      : third_description_value}
                  </span>
                </div>
              </div>
            </div>
            <div style={{fontSize: "9px", marginBottom: "3px",marginLeft: "3px",display: "flex",flexDirection: "row",alignItems: "center"}}>
              <div style={{ flex: 2, display: "flex", justifyContent:"center", alignItems:"center"}}>
                <div>{description}</div>
                {(this.props.input || this.state.modifiable) &&
                this.props.type != "top_up_Exchange" ? (
                  <input
                    type="text"
                    value={this.state.description}
                    onKeyDown={this.handleKeyDown}
                    value={this.state.description}
                    onChange={(e) => {
                      this.setState({ description: e.target.value });
                    }}
                    style={{flex: 5, border: "none",width: "40px",borderBottom: "1px solid #CCCCCC",outline: "none", marginRight: "10px", marginLeft: "10px"}}
                  />
                ) : (
                  <div style={{ flex: 1, fontWeight: "bold", marginLeft: "5px" }}>
                    {this.props.type != "top_up_Exchange" ?
                      this.state.description
                      :
                      null
                    }
                  </div>
                )}
              </div>
              {this.props.input || this.state.modifiable ? null : (
              <div style={{ display: "flex", flex: 1, alignItems: "center" }}>

                  <div>
                    <span className={classes.daily_settings_extra_small_text}>
                      {i18n.t("TYPE")}
                    </span>
                  </div>

                <div>
                  <span className={classes.daily_settings_extra_small_text} style={{ fontWeight: "bold" }}>
                    {i18n.t(
                      this.props.category.charAt(0).toUpperCase() +
                        this.props.category.slice(1)
                    )}
                  </span>
                </div>
              </div>
              )}
            </div>
          </div>
          <div style={{ position:"absolute", display: "flex", minWidth:"100px", right: "-105px"}}>
            {(this.props.date_of_creation ===
              dateFormat(new Date(), "dd/mm/yyyy") &&
              this.props.type !== "top_up_Withdrawal" &&
              this.props.type !== "top_up_Exchange") ||
            this.props.modifiable === true ? (
              <i
                className={"fas fa-pen"}
                style={{cursor: "pointer",color: "rgb(211, 156, 62)",fontSize: "15.33px",marginLeft: "10px",opacity: this.state.pencil_opacity ? 0.5 : 1}}
                onClick={() => this.handleClickedPencil()}
              ></i>
            ) : (
              <div>
                <span style={{fontSize:"9px", marginRight:"3px"}}>{i18n.t("BY")}</span>
                <b className={classes.daily_settings_extra_small_text}>{this.props.creator}</b>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  handleClickedPencil = () => {
    if (this.state.modifiable === true) {
      const id = this.props.period_id
        ? this.props.period_id
        : this.props.top_up_id
        ? this.props.top_up_id
        : null;
      const currency = this.state.currency;
      const first_element = this.state.first_element_value;
      const second_element = this.state.second_element_value;
      const third_element = this.state.third_element_value;
      const description = this.state.description;
      const child_values = {
        id,
        currency,
        first_element,
        second_element,
        third_element,
        description,
      };
      this.props.onChildClicked(child_values);
      this.setState({
        modifiable: false,
      });
    }
    this.setState({
      modifiable: !this.state.modifiable,
      pencil_opacity: !this.state.pencil_opacity,
    });
  };

  handleClickedX = () => {
    this.props.onChildClicked(this.props.period_id);
  };
}

export default TopUpComponent;
