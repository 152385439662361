import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../BuySell.module.scss';
import Button from '../../../../../components/UI/Button/Button';
import ToolbarLogout from '../../../../../components/Toolbar/ToolbarLogout';
import PartialOption from '../PartialOption';
import NumberFormat from 'react-number-format';


class PartialAcceptance extends Component {
  constructor(props) {
    super(props);

    const arrived_data = this.props.location.state;
    this.state = {
      total_value: arrived_data.total_value,
      desired_amount: arrived_data.desired_amount,
      best_offer_list: arrived_data.best_offer_list,
      currency: arrived_data.currency,
      currencySymbol: arrived_data.currencySymbol,
      market_rate: arrived_data.market_rate,
      average_rate: arrived_data.average_rate,
      order_type: arrived_data.order_type,
      currencyDescription: arrived_data.currencyDescription,
      second_currency: arrived_data.second_currency,
      secondCurrencySymbol: arrived_data.secondCurrencySymbol,
      total_previously_desired: arrived_data.total_previously_desired,
      accepted_options: []
    };

  }

  afterSelect = () => {
    if (this.state.accepted_options.length === this.state.best_offer_list.length) {
      this.onCancelSelect();
    } else {
      this.props.history.push({
        pathname: "/bestoffer",
        state: { ...this.state, page: "partial_acceptance" }
      });

    }
  };

  onCancelSelect = () => {
    this.props.history.push({
      pathname: "/bestoffer",
      state: this.state
    });

  };

  change_selected_options = (order_id, condition) => {
    let accepted_options = [...this.state.accepted_options];
    const list = this.state.best_offer_list;

    list.map(elem => {
      if (elem.order_id === order_id) {
        if (condition === true) {
          accepted_options.push(elem);
        } else {
          accepted_options = accepted_options.filter(function (element) {
            return element.order_id !== order_id;
          });
        }

        this.setState({
          accepted_options
        });

      }
      return null;
    });
  };


  render() {
    let number = 1;
    const offers = this.state.best_offer_list.map(offer => {
      return (
        <PartialOption
          order_id={offer.order_id}
          key={offer.order_id}
          number={number++}
          amount={offer.amount_offered}
          rate={offer.rate}
          currencySymbol={this.state.currencySymbol}
          change_selected_options={this.change_selected_options}
          order_type={this.state.order_type} />);


    });

    return (
      <div>
        <ToolbarLogout />
        <div className={classes.ComponentBestOffer}>
          <div className={classes.Component}>
            <div className={classes.Head}>
              <div className={classes.TransactionText}>{i18n.t("PARTIAL_ACCEPTANCE")}</div>
            </div>
            <div className={classes.container} style={{ marginTop: "30px" }}>
              <div className={classes.currenciesHeader} style={{ background: this.state.order_type === "Purchase" ? "#285A64" : "#BFD2C7" }}> </div>
              <div className={[classes.currencies, classes[this.state.order_type]].join(' ')}>
                <div className={classes.currencies_content}>
                  <div className={classes.someText} style={{ marginBottom: "20px" }}>{i18n.t("YOUR_TOTAL_WILL_BE")}</div>
                  <div className={classes.boldText}>
                    <NumberFormat
                      style={{ marginLeft: "10px" }}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={this.state.second_currency === "ALL" ? "L" : null}
                      prefix={this.state.second_currency === "ALL" ? null : this.state.secondCurrencySymbol}
                      value={this.state.total_value} />

                  </div>
                  <div style={{ marginTop: "10px" }}>{i18n.t("FOR")} <NumberFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={this.state.currencySymbol}
                    value={this.state.desired_amount} />

                  </div>
                </div>
              </div>

              <div className={[classes.currencies, classes[this.state.order_type]].join(' ')}>
                <div className={classes.currencies_content}>
                  <div className={classes.someText}>{i18n.t("SELECT_WICH_ITEMS_YOU_WOULD_LI")}</div>
                </div>
              </div>

              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                {offers}
              </div>


            </div>

            <div className={classes.buttons_center}>
              <Button clicked={this.endProcess} clicked={this.afterSelect}> {i18n.t("NEXT")} </Button>
              <Button extraStyle={classes.cancelButton} clicked={this.onCancelSelect}> {i18n.t("CANCEL")} </Button>
            </div>



          </div>
        </div>
      </div>);


  }
}




export default PartialAcceptance;
