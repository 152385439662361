import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './PaymentData.module.css';
import Button from '../../../components/UI/Button/Button';
import { graphql } from 'react-apollo';
import send_payment_instructions from '../../../mutations/Registration/SendPaymentInstructions';


class PaymentData extends Component {

  sendPaymentInstructions = () => {
    this.props.mutate({
      variables: { user_id: this.props.user_id }
    }).then(res => {
      this.props.clicked();
    }).catch(error => {
      console.log(error)
    });
  };

  render() {
    return (
      <div className={classes.container}>
        <div className={classes.firstForm}>
          <p className={classes.firstTitle}>{i18n.t("SUBSCRIPTION_FEE_IS")}</p>
        </div>

        <div className={classes.secondForm}>
          <p className={classes.fee}>200 LEK / {i18n.t("MONTH")}</p>
        </div>

        <div className={classes.firstForm}>
          <p className={classes.firstTitle}>{i18n.t("PAYMENT_ACCEPTANCE")}</p>
        </div>

        <div className={classes.firstForm}>
          <p className={classes.firstTitle}>{i18n.t("PAYMENT_INSTRUCTIONS")}</p>
        </div>

        <div className={classes.ButtonContainer}>
          <Button clicked={this.createCreditCard} clicked={this.sendPaymentInstructions}>{i18n.t("NEXT")}</Button>
        </div>
      </div>);

  }
}


export default graphql(send_payment_instructions)(PaymentData);
