import i18n from 'i18next';
import React, { Component } from 'react';
import Button from '../../../../../components/UI/Button/Button';
import classes from '../BuySell.module.scss';
import { Link } from "react-router-dom";
import ToolbarLogout from '../../../../../components/Toolbar/ToolbarLogout';
import Graph from '../../../MarketRates/graph/graph';
import MarketRatesGraph from '../../../MarketRates/MarketRatesGraph/Graph';
import NumberFormat from 'react-number-format';
import currenciesObject from "../../../../../data/currencies";
import { API } from 'aws-amplify';
import Amplify from 'aws-amplify';
import best_offer from '../../../../../queries/CurrencyTrader/BestOffer';
import MarketRates from '../../../../../queries/MarketRates/MarketRates2';

Amplify.configure({
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
    graphql_headers: async () => ({
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    })
  }
});




class BuyTransaction extends Component {

  currencies = currenciesObject[0];

  constructor(props) {
    super();
    this.state = {
      active_option: 0,
      currency: props.location.state.currency,
      currencyDescription: null,
      currencySymbol: null,
      second_currency: props.location.state.second_currency,
      secondCurrencySymbol: null,
      order_type: props.location.state.order_type,
      otherOption: false,
      switch: false,
      rate_radio_button: true,
      total_radio_button: false,
      rate: "",
      total: "",
      desired_amount: "",
      market_rate: 124.4,
      error_message: null
    };


  }

  onSelectDropdown(selectedValue, id) {
    var value = selectedValue.slice(0, 3);
    this.setState({ currency: value });
    this.setCurrencyData(value);
  }

  setCurrencyData(value) {
    this.setState({
      currencyDescription: this.currencies[this.state.currency].description,
      currencySymbol: this.currencies[this.state.currency].symbol,
      secondCurrencySymbol: this.currencies[this.state.second_currency].symbol
    });


    if (value === "OTHER") {
      this.setState({ otherOption: true });
    }
  }


  componentDidMount = async () => {
    this.setCurrencyData(this.state.currency);
    this.getMarketRates(this.state.currency);
  };

  getMarketRates = async (currency) => {
    let marketRates = await API.graphql({
      query: MarketRates,
      variables: { currency: currency }
    });

    if (this.state.order_type === "Purchase")
      this.setState({ market_rate: marketRates.data.marketRates[0].buyRate })
    else
      this.setState({ market_rate: marketRates.data.marketRates[0].sellRate })

  }

  handleRadioButtons = e => {

    if (e.target.name === "rate_radio_button") {
      this.setState({
        rate_radio_button: e.target.checked,
        total_radio_button: false,
        total: ""
      });

    } else {
      this.setState({
        total_radio_button: e.target.checked,
        rate_radio_button: false,
        rate: ""
      });
    }
  };

  handleNumberFormatInput = (values, input) => {
    this.setState({
      [input]: values.value
    });

  };

  handleBuy = () => {
    const path = this.state.active_option === 0 ? "/bestoffer" : "/recaporder";
    const { rate, total, desired_amount } = this.state;

    if (this.state.active_option === 0 && desired_amount === "") {
      this.setState({
        error_message: i18n.t("PLEASE_FILL_REQUIRED_FIELDS")
      });

    } else
      if (this.state.active_option === 1) {
        if (this.state.desired_amount === "") {
          this.setState({
            error_message: i18n.t("PLEASE_FILL_REQUIRED_FIELDS")
          });

        } else
          if (rate === "" && total === "") {
            this.setState({
              error_message: i18n.t("PLEASE_FILL_REQUIRED_FIELDS")
            });

          } else {
            if (this.checkAmountRespected(desired_amount)) {
              this.continueTransaction(path);
            }
          }
      } else {
        if (this.checkAmountRespected(desired_amount)) {
          this.continueTransaction(path);
        }
      }
  };


  continueTransaction = async path => {
    if (path === "/recaporder") {
      this.props.history.push({
        pathname: path,
        state: this.state
      });

    } else

      if (path === "/bestoffer") {
        let best_offer_list = await API.graphql({
          query: best_offer,
          variables: { order_type: this.state.order_type, desired_amount: this.state.desired_amount, currency: this.state.currency, paid_currency: this.state.second_currency }
        });

        best_offer_list = best_offer_list.data.bestOffer.bestOffer;

        if (best_offer_list.length === 0) {
          this.setState({
            error_message: i18n.t("FOR_THE_MOMENT_THERE_ARE_NOT_E")
          });

        } else {
          this.props.history.push({
            pathname: path,
            state: { ...this.state, best_offer_list }
          });

        }

      }
  };


  checkAmountRespected = desired_amount => {
    if (parseFloat(desired_amount) < 100 || parseFloat(desired_amount) % 50 !== 0) {
      this.setState({
        error_message: i18n.t("THE_AMOUNT_MUST_BE_AT_LEAST")
      });
      return false;
    }
    return true;
  };




  render() {
    const flexibleOrder =
      <div className={[classes.currencies2, classes[this.state.order_type]].join(' ')}>
        <div style={{ height: "60px", lineHeight: "1" }}>
          <p className={classes.someText}>{i18n.t("THE_MARKET_RATE_IS")}</p>
          <div style={{ display: "flex" }}>
            <div className={classes.boldText}>{this.state.currencySymbol} 1 = <span className={classes.lightGreenText}> {this.state.market_rate} {this.state.secondCurrencySymbol}</span></div>

          </div>
        </div>
      </div>;


    const fixedOrder =
      <div className={[classes.fixedOrder, classes[this.state.order_type]].join(' ')}>
        <div style={{ lineHeight: "0.5" }}>
          <p className={classes.someText}>{i18n.t("HOW_MUCH_ARE_YOU_WILLING_TO")} {this.state.order_type === "Purchase" ? i18n.t("PAY1") : i18n.t("ACCEPT1")}?</p>
          <div className={classes.top_part}>

            <div className={classes.left_part}>
              <input type="radio" name="rate_radio_button" value="rate" id="rate" checked={this.state.rate_radio_button} onChange={this.handleRadioButtons} className={classes.formRadio} description="Rate" />
              <p className={classes.someText}>{i18n.t("RATE2")}</p>

            </div>
            <div className={classes.right_part}>
              <NumberFormat
                name={"total"}
                className={classes.inputs}
                value={parseFloat(this.state.rate).toFixed(2)}
                allowNegative={false}
                onValueChange={(values, input) => this.handleNumberFormatInput(values, "rate")}
                disabled={!this.state.rate_radio_button}
                placeholder={this.state.order_type === "Purchase" ? i18n.t("MAX_RATE") : i18n.t("MIN_RATE")}
                thousandSeparator={true}
              />
            </div>

          </div>

          <div className={classes.bottom_part}>

            <div className={classes.left_part}>
              <input type="radio" name="total_radio_button" value="total" id="total" checked={this.state.total_radio_button} onChange={this.handleRadioButtons} className={classes.formRadio} description="Total" />
              <p className={classes.someText}>{i18n.t("TOTAL1")}</p>
            </div>
            <div className={classes.right_part} style={{ width: "265px" }}>
              <NumberFormat
                name={"total"}
                className={classes.inputs}
                value={this.state.total}
                allowNegative={false}
                onValueChange={(values, input) => this.handleNumberFormatInput(values, "total")}
                disabled={!this.state.total_radio_button}
                placeholder={this.state.order_type === "Purchase" ? i18n.t("MAX_VALUE") : i18n.t("MIN_VALUE")}
                thousandSeparator={true} />

              <p className={classes.someText} style={{ marginLeft: "5px" }}>{this.state.secondCurrencySymbol}</p>
            </div>
          </div>

        </div>
      </div>;


    return (
      <div>
        <ToolbarLogout />
        <div className={classes.newRegistration} style={this.state.currency === "ALL" || this.state.second_currency === "ALL" ? { justifyContent: "flex-start" } : { justifyContent: "center" }}>
          {this.state.currency === "ALL" || this.state.second_currency === "ALL" ?
            <div className={classes.graphComponent}>
              <div className={classes.Head}>
                <div className={classes.TransactionText}>{i18n.t("MARKET_RATES")}</div>
              </div>
              <div className={classes.graphContainer}>
                {/* <Graph currencyType={this.state.currency} showMaxIcon={false} showInWidget={true} /> */}
                <MarketRatesGraph panel={i18n.t("NEW_TRANSACTION")} currency={this.state.currency} showMaxIcon={false} showInWidget={true} width={480} />
              </div>
            </div> : null}
          <div className={classes.Component} style={{ margin: "35px 25px" }}>
            <div className={classes.Head}>
              <div className={classes.TransactionText}>{i18n.t("NEW_TRANSACTION")}</div>
            </div>

            <div className={classes.container} style={{ paddingTop: "0px" }}>
              <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
              <div className={classes.firstPart}>
                <div className={classes.currenciesHeader} style={{ background: this.state.order_type === "Purchase" ? "#285A64" : "#BFD2C7" }}></div>
                <div className={[classes.currencies, classes[this.state.order_type]].join(' ')}>
                  <div style={{ height: "80px" }}>
                    <p className={classes.someText}>{i18n.t("COMPLETE_YOUR_ORDER_TO")} {this.state.order_type === "Purchase" ? i18n.t("PURCHASE1") : i18n.t("SALE1")}</p>
                    <p className={classes.boldText}>{this.state.currencyDescription}</p>
                  </div>
                </div>
              </div>

              <div className={classes.options}>
                <p className={classes.someText}>{i18n.t("I_WOULD_LIKE_TO_PLACE_A")}</p>
                <div className="d-flex">
                  <p className={classes.optionText} style={this.state.active_option === 0 ? { opacity: 1 } : { opacity: 0.28 }} onClick={() => { this.setState({ active_option: 0, error_message: null }); }}>{i18n.t("FLEXIBLE_ORDER")} </p>
                  <div style={{ height: "25px", border: "1px solid #D39C3E", opacity: "0.28", margin: "-5px 5px" }}></div>
                  <p className={classes.optionText} style={this.state.active_option === 1 ? { opacity: 1 } : { opacity: 0.28 }} onClick={() => { this.setState({ active_option: 1, error_message: null }); }}>{i18n.t("FIXED_ORDER")} </p>
                </div>

              </div>
              <div className={classes.secondPart}>
                <div className={classes.currenciesHeader} style={{ background: this.state.order_type === "Purchase" ? "#285A64" : "#BFD2C7" }}></div>
                <div className={[classes.currencies1, classes[this.state.order_type]].join(' ')}>
                  <div style={{ lineHeight: "1", marginTop: "15px" }}>
                    <p className={classes.someText}>{this.state.order_type === "Purchase" ? i18n.t("HOW_MUCH_WOULD_YOU_LIKE_TO_BUY") : i18n.t("HOW_MUCH_WOULD_YOU_LIKE_TO_SEL")}</p>
                      <NumberFormat
                        name="desired_amount"
                        className={classes.inputs}
                        value={this.state.desired_amount}
                        placeholder={this.state.currencySymbol + " 0.00"}
                        thousandSeparator={true}
                        allowNegative={false}
                        prefix={this.state.currencySymbol}
                        onValueChange={(values, input) => this.handleNumberFormatInput(values, "desired_amount")}
                      />
                    {this.state.active_option === 0 ? <div> <p>{this.state.currencySymbol}{i18n.t("MINIMUM")} {this.state.order_type === "Purchase" ? i18n.t("PURCHASE1") : i18n.t("SALE1")}</p></div> : null}
                  </div>
                </div>



                {this.state.active_option === 0 ? (this.state.currency === "ALL" || this.state.second_currency === "ALL" ? flexibleOrder : null) : fixedOrder}

                <div className={classes.buttons} style={{ marginBottom: "0px" }}>
                  <Button clicked={this.handleBuy}> {this.state.order_type === "Purchase" ? i18n.t("BUY1") : i18n.t("SELL1")} </Button>
                  <Link to="/dashboard"> <Button extraStyle={classes.cancelButton}> {i18n.t("CANCEL")} </Button></Link>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>);

  }
}


export default BuyTransaction;
