import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './Headquarters.module.css';
import global from '../Register.module.css';
import Button from '../../../components/UI/Button/Button';
import Plus from '../../../components/UI/Plus/Plus';
import ArrowIcon from '../../../components/UI/ArrowIcon/ArrowIcon';
import { graphql } from 'react-apollo';
import create_headquarter from '../../../mutations/Registration/CreateHeadquarter';
import ShareholderComponent from './ShareholderComponent';

class Headquarters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "form",
      address: "",
      ceoName: "",
      ceoSurname: "",
      city: "",
      country: "",
      email: "",
      licenceNumber: "",
      postalCode: "",
      registrationNumber: "",
      shareholders: [{ name: "", quota: null, position: 1 }],
      error_message: null,
      user_id: this.props.user_id,
      shareholder_counter: 1,
      licence_filename: null,
      registration_filename: null,
      registrationSignedId: null,
      licenceSignedId: null,
      graphql_error: ""
    };

  }


  showInfoHandler = () => {
    this.setState({
      screen: "info"
    });

  };

  showFormHandler = () => {
    this.setState({
      screen: "form"
    });

  };

  handleInput = e => {
    if (e.target.name === "shareholders") {

    }
    this.setState({
      [e.target.name]: e.target.value
    });

  };

  createHeadquarter = () => {
    let { address, ceoName, ceoSurname, city, country, email, licenceNumber, postalCode, registrationNumber, shareholders, registrationSignedId, licenceSignedId } = this.state;
    shareholders = shareholders.filter(shareholder => shareholder !== "");
    this.props.mutate({
      variables: {
        attributes: {
          ceo: ceoName + " " + ceoSurname,
          address,
          city,
          country,
          email,
          licenceNumber,
          postalCode,
          registrationNumber
        },

        shareholder_list: shareholders,
        user_id: this.props.user_id,
        registrationSignedId,
        licenceSignedId
      }
    }).then(res => {
      this.props.clicked();
    }).catch(error => {
      console.log(error);
      const error_message = i18n.t("PLEASE_FILL_ALL_REQUIRED_FIELD");
      this.setState({
        error_message,
        graphql_error: error.message
      });

    });
  };

  findIndex = (position) => {
    let value;
    const position_length = position.toString().length
    if (position.toString().charAt(position_length - 1) == 1) {
      value = position.toString().substring(0, position_length - 1);
    }
    else {
      const prev = (position - 1).toString();
      const childs = this.state.shareholders.filter(shareholderi => shareholderi.position.toString().startsWith(prev))
      value = childs[childs.length - 1]["position"]
    }

    const array = this.state.shareholders
    for (var i = 0; i < array.length; i += 1) {
      if (array[i]["position"] === parseInt(value)) {
        return i;
      }
    }
    return -1;
  }

  addQuota = (position, quota) => {
    let shareholders = [...this.state.shareholders]
    const obj = shareholders.find(shareholder => shareholder.position === position)
    obj.quota = quota
    this.setState({
      shareholders
    })
  }

  addShareholderName = (position, name) => {
    let shareholders = [...this.state.shareholders]
    const obj = shareholders.find(shareholder => shareholder.position === position)
    obj.name = name
    this.setState({
      shareholders
    })
  }

  addShareholder = (position) => {
    const position_length = position.toString().length
    const previous_element_index = this.findIndex(position)
    let shareholders = [...this.state.shareholders]
    if (position.toString().length == 1) {
      shareholders.push({ name: "", quota: null, position: position })
    }
    else {
      shareholders.splice(previous_element_index + 1, 0, { name: "", quota: null, position: position });
      shareholders.join()
    }
    this.setState({
      shareholder_counter: position_length > 1 ? this.state.shareholder_counter : this.state.shareholder_counter + 1,
      shareholders
    })
  }

  setFileAttributes = (file, type) => {
    if (type === "registration") {
      this.setState({
        registration_filename: file.filename,
        registrationSignedId: file.signedBlobId
      });
    } else {
      this.setState({
        licence_filename: file.filename,
        licenceSignedId: file.signedBlobId
      });
    }
  };

  check_input_error = (name) => {
    if (this.state.graphql_error.includes(name)) {
      return [classes.inputs, global.highlighted_input].join(' ')
    }
    else {
      return classes.inputs
    }
  }

  render() {
    const shareholders = this.state.shareholders.map((shareholder, i) => {
      const position_length = shareholder.position.toString().length;
      return (
        <ShareholderComponent
          key={i}
          padding_counter={position_length}
          input_name={shareholder.position}
          handleNewchild={this.addShareholder}
          shareholders={this.state.shareholders}
          addQuota={quota => this.addQuota(shareholder.position, quota)}
          addShareholderName={name => this.addShareholderName(shareholder.position, name)}
          name={shareholder.name}
          editable={true}
          plus={position_length != 1 && shareholder.position.toString().charAt(position_length - 1) == 1 ? true : false} />);

    });
    return (
      <div className={classes.container}>
        <div style={{ height: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
        <div className={classes.firstForm}>
          <div className={classes.firstTitle}> {i18n.t("ENTER_THE_NAME_AND_QUOTA_OF_AL")} </div>
          <div style={{ fontSize: "12px", marginBottom: "10px" }}>
            {i18n.t("IF_THE_SHAREHOLDER_IS_BUSINESS")} <br />
            {i18n.t("ENTER_THE_NAME_OF_QUOTA_OF_ALL")}
          </div>
          <div className={classes.doubleInput}>
            <div style={{ width: "48%" }}>
              <input
                placeholder="CEO Name"
                name="ceoName"
                value={this.state.ceoName}
                className={this.check_input_error("Ceo")}
                onChange={this.handleInput} />

            </div>

            <div style={{ width: "48%" }}>
              <input
                placeholder="CEO Surname"
                name="ceoSurname"
                value={this.state.ceoSurname}
                className={this.check_input_error("Ceo")}
                onChange={this.handleInput} />
            </div>
          </div>

          <div style={{ width: "85%" }}>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
              <Plus clicked={this.state.shareholder_counter === 9 ? null : () => this.addShareholder(this.state.shareholder_counter + 1)} />
            </div>
          </div>
          <div style={{ maxHeight: "300px", overflow: "auto", width: "100%" }}>
            {shareholders}
          </div>
        </div>

        <div className={classes.secondForm}>
          <p className={classes.firstTitle}>{i18n.t("CONTACT_INFORMATION_FOR_YOUR_H")}</p>

          <div className={classes.singleInput}>
            <input
              placeholder="Street address"
              name="address"
              value={this.state.address}
              className={this.check_input_error("Address")}
              onChange={this.handleInput} />
          </div>

          <div className={classes.doubleInput}>
            <div style={{ width: "31%" }}>
              <input
                placeholder="Postal code"
                name="postalCode"
                value={this.state.postalCode}
                className={this.check_input_error("Postal code")}
                onChange={this.handleInput} />

            </div>

            <div style={{ width: "31%" }}>
              <input
                placeholder="City"
                name="city"
                value={this.state.city}
                className={this.check_input_error("City")}
                onChange={this.handleInput} />
            </div>
            <div style={{ width: "31%" }}>
              <input
                placeholder="Country"
                name="country"
                value={this.state.country}
                className={this.check_input_error("Country")}
                onChange={this.handleInput} />
            </div>
          </div>
          <div className={classes.singleInput}>
            <input
              placeholder="Email"
              name="email"
              value={this.state.email}
              className={this.check_input_error("Email")}
              onChange={this.handleInput} />
          </div>
        </div>
        <div className={classes.secondForm} style={{ marginBottom: "100px" }}>
          <p className={classes.firstTitle}>{i18n.t("UPLOAD_YOUR_REGISTRATION_LIC")} </p>

          <div className={classes.singleInput} style={{ display: "flex", flexDirection: "row" }}>
            <input
              placeholder="Registration certificate number"
              className={this.check_input_error("Registration number")}
              name="registrationNumber"
              value={this.state.registrationNumber}
              onChange={this.handleInput}
              style={{ marginRight: "10px" }} />
            <ArrowIcon setFileAttributes={file => this.setFileAttributes(file, "registration")} highlight={this.state.error_message ? true : false} />
          </div>

          {this.state.registration_filename ?
            <div style={{ width: "300px", display: "flex", justifyContent: "flex-end", alignItems: "center", color: "#D39C3E", marginTop: "5px" }}>
              <i className="fas fa-check" style={{ fontSize: "15px", marginRight: "5px" }} />
              <div style={{ fontSize: "10px" }}>{this.state.registration_filename}</div>
            </div> :

            null}

          <div className={classes.singleInput} style={{ display: "flex", flexDirection: "row" }}>
            <input
              placeholder="Licence certificate number"
              name="licenceNumber"
              value={this.state.licenceNumber}
              className={this.check_input_error("Licence number")}
              onChange={this.handleInput}
              style={{ marginRight: "10px" }} />


            <ArrowIcon setFileAttributes={file => this.setFileAttributes(file, "licence")} highlight={this.state.error_message ? true : false} />

          </div>

          {this.state.licence_filename ?
            <div style={{ width: "300px", display: "flex", justifyContent: "flex-end", alignItems: "center", color: "#D39C3E", marginTop: "5px" }}>
              <i className="fas fa-check" style={{ fontSize: "15px", marginRight: "5px" }} />
              <div style={{ fontSize: "10px" }}>{this.state.licence_filename}</div>
            </div> :
            null}
        </div>
        <div className={classes.ButtonContainer}>
          <Button clicked={this.createHeadquarter}>{i18n.t("NEXT")}</Button>
        </div>
      </div>);
  }
}


export default graphql(create_headquarter)(Headquarters);
