import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import classes from '../CashManager.module.scss';
import PeriodComponent from './Periods/PeriodComponent';
import ExchangeRateComponent from './ExchangeRates/ExchangeRateComponent';
import ExchangeRateException from './ExchangeRateExceptions/ExchangeRateException';
import Button from '../../../../components/UI/Button/Button';
import SettingsCalendar from '../../../Modals/SettingsCalendar/SettingsCalendar';
import New from '../../../Modals/New/New';
import get_service_point_shifts_query from '../../../../queries/CashManager/ServicePointShifts';
import not_updated_service_point_shifts from '../../../../queries/CashManager/NotUpdatedServicePointShift';
import automatic_periods from '../../../../mutations/CashManager/automaticPeriods';
import get_personalizations from '../../../../queries/Personalization/Personalization';
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { graphql } from 'react-apollo';
import compose from 'lodash/fp/compose';
import { API, graphqlOperation } from 'aws-amplify';
import Amplify from 'aws-amplify';

function DailySettings(props) {

  const [newPeriodType, setNewPeriodType] = useState(true);
  const [requestedWithoutClosing, setRequestedWithoutClosing] = useState(false);
  const [settingsCalendar, setsettingsCalendar] = useState(false);
  const [newPlus, setNewPlus] = useState(false);
  const [exceptionComponent, setExceptionComponent] = useState(false);
  var dateFormat = require('dateformat');
  var currentDate = new Date(Date.now());
  const current_date = ("0" + currentDate.getDate()) + "/" + ("0" + (currentDate.getMonth() + 1)).slice(-2) + "/" + currentDate.getFullYear();
  const [date, setDate] = useState(current_date);
  const [message, setMessage] = useState(false);
  const [storeClosedMessage, setStoreClosedMessage] = useState(false);
  const [done, setDone] = useState(false);
  const [value, setValue] = useState(false);
  const [come_from_update, setComeFromUpdate] = useState(false);
  const [current_shift, setCurrentShift] = useState(null);
  const [confirmation_message, setConfirmationMessage] = useState(false);
  const { loading, data } = useQuery(get_service_point_shifts_query, {
    variables: { date: date }
  });
  const { data: data_not_updated_today } = useQuery(not_updated_service_point_shifts, {
    variables: { date: current_date }
  });
  const [automaticPeriods] = useMutation(automatic_periods, { refetchQueries: [{ query: get_personalizations }] });

  Amplify.configure({
    API: {
      graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
      graphql_headers: async () => ({
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    }
  });


  const fetchData = async () => {
    try {
      const fetched_data = await API.graphql(graphqlOperation(not_updated_service_point_shifts, {
        variables: { date: current_date }
      }));

      if (fetched_data.data.notUpdatedServicePointShift.length !== 0) {
        setDate(dateFormat(fetched_data.data.notUpdatedServicePointShift[0].timeOpened, "dd/mm/yyyy"));
        setMessage(true);
        setRequestedWithoutClosing(true);
      } else {
        if (come_from_update) {
          props.inform_parent();
        }
        setDone(true);
        setMessage(false);
      }

    } catch (err) {

    }
  };

  useEffect(() => {
    fetchData();
  }, [value]);


  const handleClickedNew = type => {
    if (type === "Period") {
      if (data_not_updated_today.notUpdatedServicePointShift.length === 0) {
        automaticPeriods({
          variables: { shift_number: max_shift === -Infinity ? 1 : max_shift + 1 }
        }).then(result => {
            setNewPeriodType(true);
            setNewPeriodType(true);
            setNewPlus(false);
            setRequestedWithoutClosing(false);
          }).catch(error => {
            setNewPlus(false);
            setStoreClosedMessage(true)
          });

      } else {
        setNewPeriodType(true);
        setNewPlus(false);
        setRequestedWithoutClosing(true);
      }
    }
  };

  const settingsCalendarToggle = () => {
    setsettingsCalendar(!settingsCalendar);
  };

  const newModalToggle = () => {
    setNewPlus(!newPlus);
  };

  const handler = () => {
    setNewPeriodType(true);
    setValue(!value);

  };

  const handler2 = async () => {
    await setComeFromUpdate(true);
    setValue(!value);

  };

  const onClickCurrntDay = () => {
    setDate(current_date);
    setMessage(false);
    setRequestedWithoutClosing(false);

  };

  const getDateFromCalendar = date => {
    setDate(dateFormat(date, "dd/mm/yyyy"));
    setExceptionComponent(false)
  };

  const handleMismatch = () => {
    setConfirmationMessage(true);
    setMessage(true);
  };

  var shifts = [];
  var max_shift = 0;

  if (!loading) {
    max_shift = Math.max.apply(Math, data.servicePointShifts.map(function (o) { return o.shiftNumber; }));
    if (current_shift !== max_shift) {
      setCurrentShift(max_shift);
    }
    for (let i = 1; i <= max_shift; i++) {
      shifts.push(<PeriodComponent key={i} new_session={false} new_period={false} parenti={handler2} date={date} mismatch_happened={handleMismatch} shift_number={i} requested_without_closing={requestedWithoutClosing} />);
    }
  }

  const text_message = confirmation_message ? <div>{i18n.t("NOTICE_YOUR_ENTRY_DOES_NOT_MA")}<br />{i18n.t("PLEASE_REVISE_OR_CLICK_OK_TO")}</div> :
    <div>{i18n.t("BEFORE_OPENING_TODAY_S_SESSION")}</div>;
  const store_closed = <div>{i18n.t("STORE_CLOSED")}<br />{i18n.t("PLEASE_WAIT_FOR_ADMIN_TO_OPEN")}</div>
  return (
    <div style={{ paddingLeft: "80px", paddingRight: "80px", paddingTop: "55px", paddingBottom: "55px", minHeight: "600px", display: "flex", flexDirection: "column" }}>
      {message ? <div style={{ color: "#992020", fontFamily: "Open Sans", fontWeight: "bold", fontSize: "15px", marginBottom: "15px" }}>{text_message}</div> : null}
      {storeClosedMessage ? <div style={{ color: "#992020", fontFamily: "Open Sans", fontWeight: "bold", fontSize: "15px", marginBottom: "15px" }}>{store_closed}</div> : null}
      <div className="d-flex align-items-center">
        <div>
          <b>
            <div className={classes.daily_settings_big_text}>{date !== current_date ? `${i18n.t("SETTINGS_FOR")} ${date}` : i18n.t("TODAY_S_SETTINGS")}</div>
          </b>
        </div>

        <div className="position-relative">
          <i
            className="fas fa-calendar-alt calendarModalIconCM"
            style={{
              color: "rgb(211, 156, 62)",
              fontSize: "18px",
              marginLeft: "10px"
            }}

            onClick={() => settingsCalendarToggle()}>
          </i>
          {settingsCalendar ? <SettingsCalendar date={date} getDate={getDateFromCalendar} closeModal={() => setsettingsCalendar(false)} /> : null}
        </div>

        {date == current_date ?
          <div className="position-relative">
            <i
              id="newModalIconCM"
              className="fas fa-plus"
              style={{
                color: "rgb(211, 156, 62)",
                fontSize: "18px",
                marginLeft: "10px"
              }}

              onClick={() => newModalToggle()}>
            </i>
            {newPlus ? <div><New
              second_description_ds={"Period"}
              onChildClicked={handleClickedNew}
              closeModal={newModalToggle}
              current_user={props.current_user}/>
            </div> : null}
          </div>
        :
          null
        }
      </div>

      <div style={{ display: "flex", flexDirection: "column", flex: 1, marginTop: "15px", width: "90%", marginLeft: "auto", marginRight: "auto", position:"relative" }}>
        {props.current_user.isEmployee || date != current_date ?
          null
        :
          <i
            className={exceptionComponent ? "fas fa-sort-up" : "fas fa-sort-down"}
            style={{
              color: "rgb(211, 156, 62)",
              fontSize: "25px",
              position: "absolute",
              top: exceptionComponent ? "4px" : "-6px",
              left:"-35px"
            }}
            onClick={() => setExceptionComponent(!exceptionComponent)}>
          </i>
        }
        <ExchangeRateComponent current_user = {props.current_user} date={date} />
        {exceptionComponent ?
          <ExchangeRateException current_user = {props.current_user} date={date} />
          :
          null
        }
        {shifts}
        <PeriodComponent new_period_type={newPeriodType} parenti={handler} date={date} shift_number={max_shift === -Infinity ? 1 : max_shift + 1} />

      </div>
      {done && date !== current_date ? <div style={{ display: "flex", justifyContent: "center" }}><Button clicked={onClickCurrntDay} extraStyle={classes.daily_settings_button}>{i18n.t("SEE_TODAY_S_SETTINGS")}</Button></div> : null}
    </div>);
}

export default compose(
  graphql(get_service_point_shifts_query, { name: 'shifts_query' }),
  graphql(not_updated_service_point_shifts, { name: 'not_updated_query' }))(
    DailySettings);
