import React, { Component } from 'react';
import i18n from 'i18next';
import { NavLink } from 'react-router-dom';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import classes from '../Login.module.css';
import { graphql } from 'react-apollo';
import login_mutation from '../../../mutations/Login/Login';
import sendOtp_mutation from '../../../mutations/Login/SendOtp';
import compose from 'lodash/fp/compose';


class LoginTool extends Component {

  constructor(props) {
    super(props);
    this.is_mounted = false;
  }

  state = {
    orderForm: {
      email: {
        elementType: "input",
        elementConfig: {
          type: 'email',
          placeholder: 'Email'
        },
        value: "",
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touch: false
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: 'password',
          placeholder: 'Password'
        },
        value: "",
        validation: {
          required: true,
          minLength: 6,
          maxLength: 15
        },
        valid: false,
        touch: false
      }
    },
    formIsValid: false,
    message: <p>{'\u00A0'}</p>, //ORRIBILE//
    data: null
  };


  checkValidity(value, rules) {
    let isValid = true;

    if (!rules) {
      return true;
    }

    this.setState({ touch: true });

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
      //trim elimina gli spazi
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }


  inputChangedHandler = (event, inputIdentifier) => {
    const updatedOrderForm = {
      ...this.state.orderForm
    };

    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };

    updatedFormElement.value = event.target.value;
    //VALIDAZIONE
    if (updatedFormElement.validation) {
      updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    }

    updatedFormElement.touch = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;

    for (let inputIdentifier in updatedOrderForm) {
      formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid && updatedOrderForm[inputIdentifier].touch;
    }

    this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });
  };

  signupHandler = () => {
    this.props.history.push({
      pathname: 'signup'
    });
  };


  onLoginClick = (email, password) => {
    this.props.login({
      variables: { email, password }
    }).then(res => {
      if (res.data.login == null) {
        this.setState({ message: <p style={{ color: "#992020" }}>{i18n.t("EMAIL_OR_PASSWORD_IS_INCORRECT")}</p> });
      } else {
        const token = res.data.login.token;
        localStorage.setItem("token", token);
        this.props.send_otp({}).then(res => {
          this.props.history.replace({
            pathname: '/login/code'
          });
        });
      }
    });
  };

  _handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.onLoginClick(this.state.orderForm.email.value, this.state.orderForm.password.value);
    }
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key] // email, password
      });
    }

    let form =
      <form>
        <div className={classes.content}>
          {formElementsArray.map((formElement) =>
            <Input
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touch={formElement.config.touch}
              changed={event => this.inputChangedHandler(event, formElement.id)}
              inputStyle={classes.inputs}
              onKeyDown={this._handleKeyDown}
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              />)}
          <p className={classes.forgotPasswordText}>{i18n.t("FORGET_YOUR_PASSWORD")} <NavLink to={this.props.match.url + "/password"} exact>{i18n.t("CLICK_HERE1")}</NavLink></p>
          <Button clicked={() => this.onLoginClick(this.state.orderForm.email.value, this.state.orderForm.password.value)} extraStyle={classes.loginButton}>{i18n.t("LOG_IN")}</Button>
        </div>
      </form>;

    return (
      <div>
        <div className={classes.Components}>
          <div className={classes.Head}></div>
          <div className={classes.welcomeText}>
            <p className={classes.welcomeText1}>{i18n.t("WELCOME_TO_CAESAR_S_PORTAL")}</p>
            <p className={classes.welcomeText2}>{i18n.t("PLEASE_LOGIN")}</p>
          </div>
          {this.state.message}
          {form}
          <div>
            <p className={classes.notUserYetText}>{i18n.t("NOT_A_USER_YET")}</p>
          </div>
          <Button clicked={() => this.signupHandler()} extraStyle={classes.signupButton}>{i18n.t("SIGN_UP")}</Button>
        </div>
      </div>
    );
  }
};

export default compose(
  graphql(login_mutation, { name: 'login' }),
  graphql(sendOtp_mutation, { name: 'send_otp' })
)(LoginTool);
