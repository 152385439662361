import i18n from 'i18next';
import React, { Component } from 'react';
import Button from '../../../../../components/UI/Button/Button';
import classes from '../../../CurrencyTrader/DossierModal/BuySell.module.scss';
import ToolbarLogout from '../../../../../components/Toolbar/ToolbarLogout';
import { Link } from "react-router-dom";
import buy_registration_mutation from '../../../../../mutations/BuySellRegistration/CreatePurchaseOrder';
import exchange_rate_query from '../../../../../queries/CashManager/ExchangeRates';
import commission_query from '../../../../../queries/CashManager/Commission';
import get_personalizations from '../../../../../queries/Personalization/Personalization'
import customers_query from '../../../../../queries/Customer';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';
import Graph from '../../../MarketRates/graph/graph';
import MarketRatesGraph from '../../../MarketRates/MarketRatesGraph/Graph';
import currenciesObject from "../../../../../data/currencies";
import CustomerRegistration from "../CustomerRegistration"
import OnlineOfferComponent from '../OnlineOfferComponent'
import compose from 'lodash/fp/compose';
import NumberFormat from 'react-number-format';
import { API } from 'aws-amplify'
import Amplify from 'aws-amplify';

import dropDownCurrencies from '../Data';

var dateFormat = require('dateformat');

Amplify.configure({
    API: {
        graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
        graphql_headers: async () => ({
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        })
    }
});

class BoughtRegistration extends Component {

    currencies = currenciesObject[0];
    currency_trader_currencies = ["EUR", "USD", "GBP", "CHF"]
    constructor(props) {
        super(props)
        this.save_button = React.createRef();
        this.state = {
            currencyYouGet: props.location.state.currencyYouGet,
            currencyYouGive: props.location.state.currencyYouGive,
            currencyYouGetDsc: null,
            currencyYouGetSymbol: null,
            currencyYouGiveSymbol: null,
            current_user: props.location.state.current_user,
            otherOption: false,
            switch: false,
            purchase_id: null,
            buy_amount: null,
            purchase_price: null,
            completed_two: false,
            purchase_rate: null,
            commission: 0,
            message: null,
            show_customer_registration: false,
            customer_state: null,
            customer_obligatory: false,
            cross_currency_active: props.location.state.cross_currency_active,
            transaction_status: "completed",
            button_active: true,
            purchase_rate_ALL: 1,
            order_id: null,
            cash_or_bank: "cash"
        }
            this.urlRef = React.createRef()
    }

    onSave = async (amountPurchasedCents, amountPurchasedCurrency, amountPaidCents, amountPaidCurrency, rate, commission, status, cashOrBank, companyRepresentative, firstName = "", lastName = "", address = "", cellphone = "", email = "", city = "", country = "", personalIdNumber = "", postalCode = "", typology = "", rea = "") => {
        var customer_created = false
        this.setState({
            button_active: false
        })

        let customer_attributes = this.state.show_customer_registration ? { firstName, lastName, address, cellphone, email, city, country, personalIdNumber, postalCode, typology, rea } : null

        this.props.buy_mutation({
            variables: { attributes: { amountPurchasedCents: amountPurchasedCents ? amountPurchasedCents.toFixed(2).toString() : null, amountPurchasedCurrency, amountPaidCents: amountPaidCents ? amountPaidCents.toFixed(2).toString() : null, amountPaidCurrency, status, commission, companyRepresentative, cashOrBank }, rate: rate.toString(), customer: customer_attributes },
            refetchQueries: [{ query: get_personalizations }]
        }).then(order_registration_res => {
            this.props.history.push({
                pathname: "/invoice",
                state: { orders: order_registration_res.data.createPurchaseOrder }
            });
        }).catch(error => {
          console.log(error)
            this.setState({
                button_active: true
            })
            if (error.message === "GraphQL error: You dont have enough currency balance to complete this transaction") {
                this.setState({
                    message: i18n.t("YOU_DON_T_HAVE_ENOUGH_CURRENCY"),
                })
            }
            else if (error.message === "GraphQL error: You must define an exchange rate") {
                this.setState({
                    message: i18n.t("MUST_DEFINE_AN_EXCHANGE_RATE"),
                })
            }
            else if (error.message === "GraphQL error: No balance provided") {
                this.setState({
                    message: i18n.t("MAKE_SURE_YOU_HAVE_BALANCE"),
                })
            }
            else if (error.message === "GraphQL error: Customer data not completed") {
                this.setState({
                    message: i18n.t("PLEASE_PROVIDE_DATA_REQUIRED_F"),
                })
            }
            else {
                this.setState({
                    message: i18n.t("SOMETHING_WENT_WRONG"),
                })
            }
        })
    }



    onValueChange = async (values, input_type) => {
        await this.setState({
            [input_type]: parseFloat(values.value),
        });
        this.calculatePurchasePrice(parseFloat(values.value), this.state.purchase_rate, this.state.commission, input_type)
    }

    async onSelectDropdown(selectedValue, id) {
        var value = selectedValue.slice(0, 3);
        await this.setState({ currencyYouGet: value });
        await this.setCurrencyData(value);
        const purchase_rate = await this.fetchExchangeRates(value, "purchaseRate").then(data => {
            return data
        })
        this.setState({
            purchase_rate: purchase_rate == 0 ? null : purchase_rate
        })
        if(purchase_rate == 0){
          this.setState({
            button_active: false
          })
        }
        else{
          this.setState({
            button_active: true
          })
        }
    }

    async setCurrencyData(state) {
        await this.setState({
            currencyYouGetDsc: this.currencies[this.state.currencyYouGet].description,
            currencyYouGetSymbol: this.currencies[this.state.currencyYouGet].symbol,
            currencyYouGiveSymbol: this.currencies[this.state.currencyYouGive].symbol
        });
        if (state.currencyYouGet === "OTHER") {
            this.setState({ otherOption: true });
        }
    }

    showCustomerRegistration = () => {
        const customer_registration = !this.state.show_customer_registration
        this.setState({
            show_customer_registration: customer_registration,
            transaction_status: customer_registration ? "pending" : "completed"
        });
    }

    fetchExchangeRates = async (currency, type) => {
        const result = await API.graphql({
            query: exchange_rate_query,
            variables: { currency: currency }
        })

        if (result.data.exchangeRates[0]) {
            return result.data.exchangeRates[0][type]
        }
        else {
            return 0
        }
    }

    fetchCommission = async () => {
        const result = await API.graphql({
            query: commission_query,
        })

        if (result.data.commission) {
            return result.data.commission
        }
        else {
            return 0
        }
    }

    componentDidMount = async () => {
        const purchased_currency = this.props.location.state.currencyYouGet
        const sold_currency = this.props.location.state.currencyYouGive

        await this.fetchCommission().then(commission => {
            this.setState({
                commission
            })
        })

        const purchase_rate_ALL = await this.fetchExchangeRates(purchased_currency, "purchaseRate").then(data => {
            return data
        })

        if (sold_currency !== "ALL") {
            const sold_rate_ALL = await this.fetchExchangeRates(sold_currency, "saleRate").then(data => {
                return data
            })
            const purchase_rate = (purchase_rate_ALL / sold_rate_ALL)
            this.setState({
                purchase_rate: purchase_rate !== "NaN" && purchase_rate !== "Infinity" ? purchase_rate : null,
                purchase_rate_ALL: sold_rate_ALL
            })
        }
        else {
            this.setState({
                purchase_rate: purchase_rate_ALL == 0 ? null : purchase_rate_ALL
            })
        }

        if(purchase_rate_ALL == 0){
          this.setState({
            button_active: false
          })
        }
        else{
          this.setState({
            button_active: true
          })
        }

        this.setCurrencyData(this.state);
    }

    calculatePurchasePrice = (amount, purchase_rate, commission, provided_input) => {
        let purchase_price = this.state.purchase_price
        let buy_amount = this.state.buy_amount

        if (provided_input === "buy_amount") {
            purchase_price = amount * purchase_rate
            purchase_price = purchase_price - (purchase_price * commission / 100)
            buy_amount = amount
        }
        else if (provided_input === "purchase_price") {
            if (purchase_rate !== 0) {
                buy_amount = (amount / (1 - commission / 100)) / purchase_rate
            }
            else {
                buy_amount = null
            }
            purchase_price = amount
        }

        let condition = false
        let obligatory_condition = false

        if (purchase_price * this.state.purchase_rate_ALL >= 100000) {
            condition = true
            obligatory_condition = true
        }

        this.setState({
            completed_two: true,
            purchase_price: purchase_price,
            buy_amount: buy_amount,
            show_customer_registration: this.state.show_customer_registration || condition,
            customer_obligatory: obligatory_condition,
            transaction_status: condition || this.state.show_customer_registration ? "pending" : "completed"
        })
    }

    take_child_data = (data) => {
        this.setState({
            customer_state: data
        })
    }

    handleSwitch = () => {
        this.setState({
            switch: !this.state.switch,
            buy_amount: null,
            purchase_price: null
        })
    }

    handleRateInput = (e) => {
        this.setState({
            purchase_rate: e.target.value
        });
        const amount = this.state.switch ? this.state.purchase_price : this.state.buy_amount
        const provided_input = this.state.switch ? "purchase_price" : "buy_amount"
        const commission = this.state.commission
        this.calculatePurchasePrice(amount, e.target.value, commission, provided_input)
    }

    handleCommisionChange = (e) => {
        let commission
        if (e.value === "") {
            this.setState({
                commission: null
            })
            commission = 0
        }
        else {
            this.setState({
                commission: (parseFloat(e.value))
            })
            commission = (parseFloat(e.value))
        }

        const amount = this.state.switch ? this.state.purchase_price : this.state.buy_amount
        const provided_input = this.state.switch ? "purchase_price" : "buy_amount"
        const rate = this.state.purchase_rate
        this.calculatePurchasePrice(amount, rate, commission, provided_input)
    }

    _handleKeyDown = (e) => {
      if (e.key === 'Enter' && this.state.button_active) {
        this.save_button.current.click();
      }
    }

    changeType = (e) => {
      this.setState({
        cash_or_bank: e.target.getAttribute('name')
      })
    }

    render() {
      const url = `${process.env.REACT_APP_HOME_URL}/file/purchase_invoice/${this.state.order_id}`;
        const purchase_registration_data =
            [
                this.state.buy_amount,
                this.state.currencyYouGet,
                this.state.purchase_price,
                this.state.currencyYouGive,
                this.state.purchase_rate,
                this.state.commission || 0,
                this.state.transaction_status,
                this.state.cash_or_bank,
                this.state.customer_state ? this.state.customer_state.personalIdNumber[0] : null
            ]

        let customer_registration_data = []

        if (this.state.customer_state !== null) {

            customer_registration_data =
                [
                    this.state.customer_state.firstName,
                    this.state.customer_state.lastName,
                    this.state.customer_state.address,
                    this.state.customer_state.cellphone,
                    this.state.customer_state.email,
                    this.state.customer_state.city,
                    this.state.customer_state.country,
                    Array.from(new Set([...this.state.customer_state.company_rapresentatives, ...this.state.customer_state.personalIdNumber])),
                    this.state.customer_state.postalCode,
                    this.state.customer_state.typology,
                    this.state.customer_state.rea
                ]
        }

        let label
        let input_field
        let suffix
        let prefix

        if (this.state.switch) {
            label = this.state.buy_amount
            input_field = (<NumberFormat value={this.state.purchase_price} suffix={this.state.currencyYouGiveSymbol} onKeyDown={this._handleKeyDown} thousandSeparator={true} className={classes.inputs} placeholder={"0.00 " + this.state.currencyYouGiveSymbol} onValueChange={(values, input) => this.onValueChange(values, "purchase_price")} />)
            suffix = null
            prefix = this.state.currencyYouGetSymbol
        }
        else {
            label = this.state.purchase_price
            input_field = (<NumberFormat autoFocus value={this.state.buy_amount} prefix={this.state.currencyYouGetSymbol} onKeyDown={this._handleKeyDown} thousandSeparator={true} className={classes.inputs} placeholder={this.state.currencyYouGetSymbol + " 0.00"} onValueChange={(values, input) => this.onValueChange(values, "buy_amount")} />)
            suffix = this.state.currencyYouGiveSymbol
            prefix = null
        }

        return (
            <div>
                <ToolbarLogout />
                <div className={classes.newRegistration} style={{ justifyContent: "center" }}>
                    {['EUR', 'USD', 'GBP', 'CHF'].includes(this.state.currencyYouGet) ? (
                        this.state.cross_currency_active ?
                            <div className={classes.graphComponent}>
                                <div className={classes.Head}>
                                    <p className={classes.TransactionText} >{i18n.t("MARKET_RATES")}</p>
                                </div>

                                {(true) ?
                                    (<div className={classes.graphContainer}>
                                        {/* <Graph panel={i18n.t("NEW_REGISTRATION1")} currencyType={this.state.currencyYouGet} showMaxIcon={false} showInWidget={true} /> */}
                                        <MarketRatesGraph panel={i18n.t("NEW_REGISTRATION1")} currency={this.state.currencyYouGet} showMaxIcon={false} showInWidget={true} width={480} />
                                    </div>) : null}
                            </div> : null
                    ) : null}

                    <div className={classes.Component} style={{ margin: "35px 25px" }}>
                        <div className={classes.Head}>
                            <p className={classes.TransactionText} >{i18n.t("NEW_REGISTRATION")}</p>
                        </div>

                        <div className={classes.container}>
                            <div className={classes.firstPart}>
                                <div className={classes.checkoutHeaderBuy}>
                                  <span className={classes.someText} name= "cash" style={{ color: this.state.cash_or_bank == "cash" ? "#D39C3E" : null}} onClick={(e) => this.changeType(e)}>CASH</span>
                                  <span>|</span>
                                  <span className={classes.someText} name= "bank" style={{ color: this.state.cash_or_bank == "bank" ? "#D39C3E" : null}} onClick={(e) => this.changeType(e)}>BANK</span>
                                </div>
                                <div className={classes.checkoutCurrencies}>
                                    <div style={{ height: "80px" }} >
                                        <p className={classes.someText}>{i18n.t("FINISH_REGISTERING_YOUR_PURCHA")}</p>
                                        {this.state.otherOption ?
                                            <Dropdown items={dropDownCurrencies} onSelectDropdown={this.onSelectDropdown.bind(this)} />
                                            :
                                            <p className={classes.boldText}> {this.state.currencyYouGetDsc} </p>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={classes.checkoutCurrencies}>
                                <div className={classes.checkoutCurrenciesContent} style={{ position: "relative" }}>
                                    <p className={classes.someText}>{this.state.switch ? i18n.t("WHAT_IS_THE_TOTAL_PRICE") : i18n.t("HOW_MUCH_ARE_YOU_BUYING")}</p>
                                    {input_field}
                                    <i className="fas fa-random" onClick={() => this.handleSwitch()} style={{ position: "absolute", top: "40px", left: "250px", fontSize: "20px", color: "#D39C3E", cursor: "pointer" }}></i>
                                </div>
                            </div>

                            <div className={classes.checkoutCurrencies}>
                                <div className={classes.checkoutCurrenciesContent} >
                                    <p className={classes.someText}>{i18n.t("THE_PURCHASE_RATE_IS")}</p>
                                    <b className={classes.right_part}>
                                      {this.state.current_user.isStoreAdmin && this.state.currencyYouGive == "ALL"?
                                        <input className={classes.inputs} placeholder={"0.00"} onKeyDown={this._handleKeyDown} value={this.state.purchase_rate || 0} onChange={this.handleRateInput} />
                                        :
                                        parseFloat(this.state.purchase_rate).toFixed(4)
                                      }
                                    </b>
                                </div>
                            </div>

                            <div className={classes.checkoutCurrencies}>
                                <div className={classes.checkoutCurrenciesContent} >
                                    <p className={classes.someText}>{i18n.t("WHAT_IS_THE_COMMISSION")}</p>
                                    <div className={classes.right_part}>
                                        <NumberFormat className={classes.inputs} suffix={"%"} onKeyDown={this._handleKeyDown} value={this.state.commission} placeholder={"0%"} onValueChange={(e) => this.handleCommisionChange(e)} />
                                    </div>
                                </div>
                            </div>

                            <div className={classes.checkoutCurrencies} style={{ height: "80px" }}>
                                <div style={{ display: "flex" }}>
                                    {/* <i className="fas fa-user-circle" style={{ color: '#D39C3E', fontSize: '23px', marginRight: '10px', cursor: "pointer" }} onClick={this.state.customer_obligatory ? null : () => this.showCustomerRegistration()}></i> */}
                                    <span className="fa-stack" style={{ color: '#D39C3E', fontSize: '13px', marginRight: '10px', cursor: "pointer" }} onClick={this.state.customer_obligatory ? null : () => this.showCustomerRegistration()}>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-user fa-stack-1x fa-inverse" style={{ fontSize: '16px' }}></i>
                                    </span>
                                    <p className={classes.someText}>{this.state.switch ? i18n.t("AMOUNT_PURCHASED_IS") : i18n.t("THE_TOTAL_PRICE_IS")}
                                        <span style={{ marginLeft: "5px" }}>
                                            {label ?
                                                <NumberFormat displayType={"text"} thousandSeparator={true} prefix={prefix} suffix={suffix} value={label === "0.0000" || label.toFixed(2)} />
                                                : " --"}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            {this.state.show_customer_registration ? <CustomerRegistration sendData={this.take_child_data} type={"bought"} /> : null}
                            {this.state.message ? <p style={{ marginTop: "15px", color: "red" }}>{this.state.message}</p> : null}
                            <div className={classes.buttons}>
                                <a href={url} ref={this.urlRef}/>
                                <Button extraStyle={classes[!this.state.button_active ? "button_disabled" : null]} disabled={!this.state.button_active} clicked={() => this.onSave(...purchase_registration_data, ...customer_registration_data)}> {i18n.t("SAVE")} </Button>
                                <button style={{display: "none"}} ref={this.save_button} onClick={() => this.onSave(...purchase_registration_data, ...customer_registration_data)}></button>
                                <Link to="/dashboard">  <Button extraStyle={classes.cancelButton} > {i18n.t("CANCEL")} </Button> </Link>
                            </div>
                        </div>
                    </div>

                    {this.state.cross_currency_active && this.currency_trader_currencies.includes(this.state.currencyYouGet) && this.state.current_user.currencyTrader ?
                        <OnlineOfferComponent
                            currency_symbol={this.state.currencyYouGetSymbol}
                            currency={this.state.currencyYouGet}
                            amount={this.state.buy_amount}
                            cash_manager_rate={this.state.purchase_rate > 0 ? this.state.purchase_rate : null}
                            offer_order_type={"Sale"}
                        />
                        : null}
                </div>
            </div>
        );
    }
}

export default compose(
    graphql(buy_registration_mutation, { name: 'buy_mutation' })
)(withRouter(BoughtRegistration));
