import gql from 'graphql-tag';

export default gql`

mutation createUser($email:String!, $password:String!, $password_confirmation:String!, $typology:String!){
  createUser(
  email:$email
  password:$password
  passwordConfirmation:$password_confirmation
  typology:$typology
  ) {
    id
    email
  }
}

`;
