import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../CashManager.module.scss';
import NumberFormat from 'react-number-format';

var dateFormat = require('dateformat');

class DailySettingsRate extends Component {
  constructor(props) {
    super(props);


    this.state = {
      modifiable: this.props.modifiable || this.props.confirmation_modify ? true : false,
      pencil_opacity: this.props.requested_without_closing && this.props.amount == null ? true : false,
      currency: this.props.currency,
      first_element_value: (this.props.type === "exchange_rate" ? this.props.purchase_rate : this.props.type === "period" ? this.props.amount : this.props.type === "top_up_Exchange" || this.props.type === "top_up_Withdrawal" ? this.props.amount : null),
      second_element_value: (this.props.type === "exchange_rate" ? this.props.sales_rate : this.props.type === "period" ? this.props.ave_rate : this.props.type === "top_up_Exchange" || this.props.type === "top_up_Withdrawal" ? this.props.rate : null),
      third_element_value: (this.props.type === "top_up_Exchange" ? this.props.paid_in : null),
      description: this.props.last_description || this.props.description || ""
    }
  }

onModifyValues = () => {
  this.setState({
    modifiable: true
  });
};

handleKeyDown = e => {
  if (e.key === 'Enter') {
    this.handleClickedPencil();
  }
};


componentDidUpdate(prevProps) {
  if (prevProps.modifiable !== this.props.modifiable) {
    this.setState({
      modifiable: this.props.modifiable,
      pencil_opacity: this.props.modifiable
    });
  } else
    if (prevProps.confirmation_modify !== this.props.confirmation_modify) {
      this.setState({
        modifiable: this.props.confirmation_modify,
        pencil_opacity: this.props.confirmation_modify
      });
    }
}

amountChoosenInput = value => {
  this.setState({ first_element_value: value.value });
};


render() {
  const color = this.props.type === "top-up" ? "#285A64" : "#649177";
  const input =
    <input type="text" onKeyDown={this.handleKeyDown} name="name" onChange={e => { this.setState({ currency: e.target.value }); }} value={this.state.currency || ""} style={{ border: "none", width: "40px", borderBottom: "1px solid #CCCCCC", outline: "none" }} />;
  const first_input =
    ["period", "top_up_Exchange", "top_up_Withdrawal", "top_up_Payment"].includes(this.props.type) ?
      <NumberFormat
        style={{ border: "none", width: "80%", borderBottom: "1px solid #CCCCCC", outline: "none" }}
        thousandSeparator={true}
        prefix={!this.state.first_element_value && this.props.default_negative ? "-" : ""}
        value={this.state.first_element_value}
        onValueChange={value => this.amountChoosenInput(value)} /> :


      <input type="text" name="name" onKeyDown={this.handleKeyDown} onChange={e => { this.setState({ first_element_value: e.target.value }); }} value={this.state.first_element_value || ""} style={{ border: "none", width: "80%", borderBottom: "1px solid #CCCCCC", outline: "none" }} />;

  const second_input =
    <input type="number" name="name" onKeyDown={this.handleKeyDown} onChange={e => { this.setState({ second_element_value: Math.abs(e.target.value) }); }} value={this.state.second_element_value || ""} style={{ border: "none", width: "80%", borderBottom: "1px solid #CCCCCC", outline: "none" }} />;
  const third_input =
    <input type="text" name="name" onKeyDown={this.handleKeyDown} onChange={e => { this.setState({ third_element_value: e.target.value }); }} value={this.state.third_element_value || ""} style={{ border: "none", width: "80%", borderBottom: "1px solid #CCCCCC", outline: "none" }} />;

  const flex_value = this.props.type === "top_up_Exchange" || this.props.type === "top_up_Withdrawal" ? 5 : 2;
  const border_color = this.props.requested_without_closing && this.props.amount === null ? "red" : "#CCCC";

  var first_description = null;
  var second_description = null;
  var third_description = null;
  var first_description_value = null;
  var second_description_value = null;
  var third_description_value = null;

  if (this.props.type === "exchange_rate") {
    first_description = i18n.t("PURCHASE_RATE");
    second_description = i18n.t("SALES_RATE");
    first_description_value = this.props.purchase_rate;
    second_description_value = this.props.sales_rate;

  } else
    if (this.props.type === "period") {
      first_description = i18n.t("AMOUNT");
      second_description = i18n.t("AVE_RATE");
      first_description_value = this.props.amount;
      second_description_value = this.props.ave_rate;
    } else
      if (this.props.type === "top_up_Exchange") {
        first_description = i18n.t("AMOUNT");
        second_description = i18n.t("RATE");
        third_description = i18n.t("PAID_IN");
        first_description_value = this.props.amount;
        second_description_value = this.props.rate;
        third_description_value = this.props.paid_in;

      } else
        if (this.props.type === "top_up_Withdrawal" || "top_up_Payment") {
          first_description_value = this.props.amount;
          second_description_value = this.props.rate;
          first_description = i18n.t("AMOUNT");
          second_description = i18n.t("RATE");
        }

  return (
    <div style={{ display: "flex", flexDirection: "row", flex: 1, marginTop: "5px", alignItems: "center", justifyContent: "center" }}>
      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          <div style={{ flex: 1 }}>
            <span className={classes.daily_settings_card_small_text} style={{ color: color, marginRight: "5px"}}>{!this.props.currency ? input : this.props.currency}</span>
          </div>

          {this.props.type === "exchange_rate" && this.props.with_ALL ?
            <div style={{ flex: 5 }}>
              <span>-</span>
              <span className={classes.daily_settings_card_small_text} style={{ marginLeft: "5px" }}>ALL</span>
            </div> :
            null}
        </div>

        {this.props.type !== "exchange-rate" && this.props.action_handeled ?
          <div style={{ marginRight: "5.13px", flex: 1, display: "flex", justifyContent: "flex-end", flexDirection: "column", fontWeight: "bold" }}>
            <div style={{ fontSize: "9px", display: "flex", justifyContent: "flex-end" }}>
              {this.props.confirmation ? <div onClick={() => this.props.onConfirmMismatch()} style={{ fontSize: "16px", color: "rgb(211, 156, 62)", cursor: "pointer" }}>{i18n.t("OK")}</div> : (this.props.action || "") + " " + (this.props.date || "")}
            </div>
            <div style={{ fontSize: "9px", display: "flex", justifyContent: "flex-end" }}>
              {this.props.time}
            </div>
          </div> :
          null}
      </div>

      <div style={{ display: "flex", flex: flex_value }}>
        <div style={{ width: "-webkit-fill-available", border: `1px solid ${border_color}`, width:"100%" }}>
          <div style={{ display: "flex", flex: 2, flexDirection: "row", height: "35px" }}>

            <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", marginLeft: this.props.marginLeft }}>

              <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                <span className={classes.daily_settings_extra_small_text}>{first_description}</span>
              </div>
              <div style={{ display: "flex", flex: this.props.flex }}>
                <span className={classes.daily_settings_card_small_text}>
                  {this.props.input || this.state.modifiable ?
                    first_input :
                    <NumberFormat
                      prefix={this.props.currency_symbol === "L" ? null : this.props.currency_symbol}
                      suffix={this.props.currency_symbol === "L" ? this.props.currency_symbol : null}
                      thousandSeparator={true} displayType={"text"} value={first_description_value} />
                  }
                </span>
              </div>
            </div>

            <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}>
              <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                <span className={classes.daily_settings_extra_small_text}>{second_description}</span>
              </div>
              <div style={{ flex: 1 }}>
                <span className={classes.daily_settings_card_small_text}>{this.props.input || this.state.modifiable ? second_input : second_description_value}</span>
              </div>
            </div>

            {this.props.type === "top_up_Exchange" ?
              <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}>
                <div style={{ flex: 1 }}>
                  <span className={classes.daily_settings_extra_small_text}>{third_description}</span>
                </div>
                <div style={{ flex: 1 }}>
                  <span className={classes.daily_settings_card_small_text}>{this.props.input || this.state.modifiable ? third_input : third_description_value}</span>
                </div>
              </div> : null}
          </div>

          {this.props.type === "top_up_Withdrawal" ?
            <div style={{ fontSize: "9px", marginBottom: "3px", marginLeft: "3px", display: "flex", flexDirection: "row", alignItems: "center" }}>
              <div style={{ flex: 1 }}>Description</div>
              {this.props.input || this.state.modifiable ?
                <input type="text" value={this.state.description} onKeyDown={this.handleKeyDown} value= {this.state.description} onChange={(e) => { this.setState({ description: e.target.value }) }} style={{ flex: 5, border: "none", width: "40px", borderBottom: "1px solid #CCCCCC", outline: "none", marginRight: "10px" }} />
                :
                <div style={{ flex: 5, fontWeight: "bold" }}>{this.state.description}</div>
              }
            </div>
            :
            null}
        </div>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {this.props.date_of_creation === dateFormat(new Date(), "dd/mm/yyyy") && !this.props.employee && this.props.type !== "top_up_Withdrawal" && this.props.type !== "top_up_Exchange" && !this.props.popup && this.props.period_type !== "open" || this.props.modifiable === true ?
            <i
              className={"fas fa-pen"}
              style={{
                cursor: "pointer",
                color: "rgb(211, 156, 62)",
                fontSize: "15.33px",
                marginLeft: "10px",
                opacity: this.state.pencil_opacity ? 0.5 : 1
              }}
              onClick={() => this.handleClickedPencil()}>
            </i> :
            <div style={{ width: "15.33px", marginLeft: "10px", background: "red" }}></div>}
        </div>
      </div>
    </div>);
  }

  handleClickedPencil = () => {
    if (this.state.modifiable === true) {
      const id = (this.props.period_id ? this.props.period_id : this.props.top_up_id ? this.props.top_up_id : null)
      const currency = this.state.currency
      const first_element = this.state.first_element_value
      const second_element = this.state.second_element_value
      const third_element = this.state.third_element_value
      const description = this.state.description
      const child_values = {
        id,
        currency,
        first_element,
        second_element,
        third_element,
        description
      }
      this.props.onChildClicked(child_values);
      this.setState({
        modifiable: false
      });

    }
    this.setState({
      modifiable: !this.state.modifiable,
      pencil_opacity: !this.state.pencil_opacity
    });

  };

  handleClickedX = () => {
    this.props.onChildClicked(this.props.period_id);
  };
}

export default DailySettingsRate;
