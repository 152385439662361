import gql from 'graphql-tag';


export default gql
`mutation createDailyLimit(
  $preferred_currency: String!,
  $preferred_trading_amount: Int!,
  $preferred_trading_period: Int!,
  $trading_daily_limit: String!,
  $guarantee: Int!,
  $user_id:ID!) {
  createDailyLimit(
    preferredCurrency: $preferred_currency,
    preferredTradingAmount: $preferred_trading_amount,
    preferredTradingPeriod: $preferred_trading_period,
    tradingDailyLimit: $trading_daily_limit,
    guarantee: $guarantee,
    userId:$user_id
  )
  {
    preferredTradingAmount
    preferredTradingPeriod
  }
}
`;
