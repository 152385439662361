import i18n from 'i18next';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classes from '../Login.module.css';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import login_otp_mutation from '../../../mutations/Login/LoginOtp';
import sendOtp_mutation from '../../../mutations/Login/SendOtp';
import { graphql } from 'react-apollo';
import compose from 'lodash/fp/compose';
import query from '../../../queries/LoggedUser';
import logged_user from '../../../queries/LoggedUser';
import { API } from 'aws-amplify'
import Amplify from 'aws-amplify';

Amplify.configure({
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
    graphql_headers: async () => ({
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    })
  }
});


class CodeTool extends Component {

  state = {
    orderForm: {
      code: {
        elementType: "input",
        elementConfig: {
          type: 'text',
          placeholder: 'Code'
        },


        value: "",
        validation: {
          required: true,
          minLength: 6,
          isNumeric: true
        },

        valid: false,
        touch: false
      }
    },


    listcode: ['1', '0', '6549-8745-3218'],
    formIsValid: false,
    message: <p style={{ color: "white" }}> </p>, //ORRIBILE
    resentOtp: false,
    correct: false,
    showReadyForActivationInfo: false
  };



  checkValidity(value, rules) {
    let isValid = true;

    if (!rules) {
      return true;
    }

    this.setState({ touch: true });

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }
    //trim elimina gli spazi

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }


    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
    }
    return isValid;
  }

  inputChangedHandler = event => {

    const updatedOrderForm = {
      ...this.state.orderForm
    };


    const updatedFormElement = this.state.orderForm.code;

    updatedFormElement.value = event.target.value;
    //VALIDAZIONE
    if (this.state.orderForm.code.validation) {
      updatedFormElement.valid = this.checkValidity(this.state.orderForm.code.value, this.state.orderForm.code.validation);
    }

    updatedFormElement.touch = true;
    updatedOrderForm[this.state.orderForm.code] = updatedFormElement;

    let formIsValid = updatedFormElement.valid;

    this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });

  };

  nextHandler = otp => {
    this.props.login_otp({
      variables: { otp },
      refetchQueries: [{ query }]
    }).then(res => {
      if (!res.data.loginOtp.success) {
        this.setState({ message: <p style={{ color: "#992020" }}>{i18n.t("INVALID_CODE")}</p> });
      } else {
        this.setState({ correct: true });
      }

    });
  };


  resendOtp = otp => {
    this.props.send_otp({}).then(res => {

      this.props.history.replace({
        pathname: '/login/code'
      });

      this.setState({ resendOtp: true });
    });
  };

  redirect_to_dashboard = async () => {
    let user = await API.graphql({
      query: logged_user
    })
    if (user.data.currentUser.state === "active") {
      this.props.history.replace({
        pathname: "/dashboard"
      });
    }
    else if (user.data.currentUser.state === "ready_for_activation") {
      this.setState({ showReadyForActivationInfo: true })
    }
    else if (user.data.currentUser.state !== "ready_for_activation") {
      switch (user.data.currentUser.typology) {
        case "service_point": {
          this.props.history.replace({
            pathname: "/servicepointaccount",
            state: {
              userId: user.data.currentUser.id,
              userEmail: user.data.currentUser.email,
              registrationPhase: user.data.currentUser.state
            }
          });
          break;
        }
        case "business_account": {
          this.props.history.replace({
            pathname: "/businessaccount",
            state: {
              userId: user.data.currentUser.id,
              userEmail: user.data.currentUser.email,
              registrationPhase: user.data.currentUser.state
            }
          });
          break;
        }
        case "bank_account": {
          this.props.history.replace({
            pathname: "/servicepointaccount",
            state: {
              userId: user.data.currentUser.id,
              userEmail: user.data.currentUser.email,
              registrationPhase: user.data.currentUser.state
            }
          });
          break;
        }
        case "private_account": {
          this.props.history.replace({
            pathname: "/privateaccount",
            state: {
              userId: user.data.currentUser.id,
              userEmail: user.data.currentUser.email,
              registrationPhase: user.data.currentUser.state
            }
          });
          break;
        }
        case "market_viewer": {
          this.props.history.replace({
            pathname: "/marketviewer",
            state: {
              userId: user.data.currentUser.id,
              userEmail: user.data.currentUser.email,
              registrationPhase: user.data.currentUser.state
            }
          });
          break;
        }
      }
    }
    console.log(user.data.currentUser)
  }
  closeHandler = () => {
    this.props.history.replace({
      pathname: "/login"
    });
  }

  render() {

    const code = this.state.orderForm.code;

    let form =
      <form>
        <div className={classes.Components3}>
          <div className={classes.Head}></div>
          <div className={classes.verificationContent}>
            <p className={classes.verificationHeader}>{i18n.t("PLEASE_ENTER_YOUR_CODE")}</p>
            {this.state.message}
            <Input
              inputStyle={classes.inputStyle}
              autocomplete="off"
              elementType={code.elementType}
              elementConfig={code.elementConfig}
              value={code.value}
              invalid={!code.valid}
              shouldValidate={code.validation}
              touch={this.state.touch}
              changed={event => this.inputChangedHandler(event)} />
            <p className={classes.forgotPasswordText}>{i18n.t("DIDN_T_RECEIVE_A_CODE")}<span style={{ color: "#D39C3E", cursor: "pointer" }} onClick={this.resendOtp}> {i18n.t("CLICK_HERE1")}</span></p>
            {this.state.resendOtp ? <p className={classes.forgotPasswordText}><NavLink to={"verification/newcode"}>{i18n.t("THE_CODE_WAS_RESENT_TO_YOUR_EM")}</NavLink></p> : null}
            {!this.state.correct ? <Button
              clicked={() => this.nextHandler(code.value)}
              extraStyle={classes.loginButton}>
              {i18n.t("VERIFY")}
            </Button> :

              <Button
                clicked={() => this.redirect_to_dashboard()}
                extraStyle={classes.loginButton}>

                {i18n.t("NEXT")}
              </Button>}
          </div>
        </div>
      </form>;

    let infoScreen =
      <form>
        <div className={classes.Components2}>
          <div className={classes.Head}></div>
          <div className={classes.contentTextLogin}>
            <p className={classes.infoTextLogin}>{i18n.t("YOUR_ACCOUNT_IS_WAITING")} <br /></p>
            <Button
              clicked={() => this.closeHandler()}
              extraStyle={classes.closeButton}>

              {i18n.t("CLOSE")}
            </Button>
          </div>
        </div>
      </form>;


    return (
      <div>
        {this.state.showReadyForActivationInfo ? infoScreen : form}
      </div>);

  }
}


export default compose(
  graphql(login_otp_mutation, { name: 'login_otp' }),
  graphql(sendOtp_mutation, { name: 'send_otp' }))(
    CodeTool);
